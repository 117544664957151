import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'
import {
	showPage,
	hidePage,
	showPopup,
	goToStart,
	hideAllPopups,
	hideAllPages,
	showMenu,
	hideMenu,
	hideSpaceLightbox
} from '../../actions/uiActions'

import { sendEvent } from '../../actions/chatActions'
import { logout } from '../../actions/userActions'
import Settings from '../../Settings'

const IntroText = () => (
	<React.Fragment>
		<p>
			Synthetic Life invites you to enjoy an innovative encounter
			customized to your specific wants and desires.
		</p>
		<p>
			Based in Berlin, we can offer you an interactive experience that you
			will touch, taste and feel.
		</p>
		<p>Introducing Kokeshi, our sophisticated pleasure doll.</p>
	</React.Fragment>
)

class MainScreen extends React.Component {
	state = { active_slide: 1, justClicked: false }
	constructor(props) {
		super(props)
		this.clicked = this.clicked.bind(this)
		this.hide = this.hide.bind(this)
		this.toggleAbout = this.toggleAbout.bind(this)
		this.showRegister = this.showRegister.bind(this)
		this.goToStart = this.goToStart.bind(this)
		this.hamburgerHandler = this.hamburgerHandler.bind(this)
		this.loginHandler = this.loginHandler.bind(this)
		this.logoutHandler = this.logoutHandler.bind(this)
		this.nextSlide = this.nextSlide.bind(this)
		this.registerHandler = this.registerHandler.bind(this)
		this.pricesHandler = this.pricesHandler.bind(this)
		this.showImprint = this.showImprint.bind(this)
		this.showSpace = this.showSpace.bind(this)
		this.showFaq = this.showFaq.bind(this)
		this.experienceHandler = this.experienceHandler.bind(this)
	}
	componentDidMount() {
		this.interval = window.setInterval(() => {
			this.nextSlide()
		}, 10000)
	}

	// helper function to avoid double and triple answers in chat
	clicked = () => {
		this.setState({ justClicked: true })
		setTimeout(this.setState({ justClicked: false }), 1000)
	}

	hamburgerHandler = () => {
		const {
			hideMenu,
			hideAllPopups,
			hideAllPages,
			hidePage,
			popupActive,
			showMenu,
			menuVisible,
			imprintVisible,
			pageVisible,
			spaceLightboxVisible,
			hideSpaceLightbox
		} = this.props
		if (menuVisible) hideMenu()
		if (popupActive && !imprintVisible) hideAllPopups()
		if (imprintVisible) hidePage('imprint')
		if (pageVisible && !spaceLightboxVisible) hideAllPages()
		if (spaceLightboxVisible) hideSpaceLightbox()

		if (!menuVisible && !popupActive && !imprintVisible && !pageVisible)
			showMenu()
	}
	loginHandler() {
		const { showPopup, hideAllPages } = this.props
		ReactGA.pageview('login')
		// this.props.sendEvent('showLoginPopup')
		showPopup('loginPopup')
		hideAllPages()
	}
	logoutHandler() {
		const { logout } = this.props
		ReactGA.pageview('logout')
		logout()
	}

	pricesHandler() {
		if (!this.state.justClicked) {
			this.clicked()
			ReactGA.pageview('prices')
			this.props.sendEvent('prices', false)
		}
	}

	registerConfirmHandler() {
		// if (!this.state.justClicked) {
		// 	this.clicked()
		// 	ReactGA.pageview('register')
		// 	this.props.sendEvent('register', false)
		// }
		// console.log('registerConfirmHandler')
	}

	registerHandler() {
		if (!this.state.justClicked) {
			this.clicked()
			ReactGA.pageview('register')
			this.props.sendEvent('register_no_ok', false)
			ReactPixel.track('Lead')
		}
	}

	experienceHandler() {
		const { showPopup } = this.props
		// console.log('meet')
		ReactGA.pageview('meet')
		showPopup('experiencePopup')
	}

	sxTechHandler = () => {
		const { showPopup, loggedIn } = this.props
		// console.log('sx Tech')
		ReactGA.pageview('book_sx_tech')
		loggedIn ? showPopup('sxPopup') : showPopup('sxLoggedOutPopup')
	}

	openInstagram() {
		ReactGA.pageview('instagram')
		window.open(Settings.instagramUrl)
	}

	hide() {
		this.props.hideMainScreen()
		this.props.showPage()
	}

	toggleAbout() {
		if (!this.state.justClicked) {
			this.clicked()
			ReactGA.pageview('about')
			const { sendEvent } = this.props
			// aboutVisible ? hidePage('about') : showPage('about')
			sendEvent('about')
		}
	}

	showRegister() {
		const { showPopup, showPage } = this.props
		showPopup('register')
		showPage('registerQuestions')
	}

	showSpace() {
		const { showPage, hideAllPages } = this.props
		hideAllPages()
		showPage('space')
	}

	showFaq() {
		const { showPage, hideAllPages } = this.props
		hideAllPages()
		showPage('faq')
	}

	showImprint() {
		ReactGA.pageview('imprint')

		const { showPage, hideAllPages } = this.props
		hideAllPages()
		showPage('imprint')
	}

	goToStart() {
		// const { goToStart } = this.props
		// goToStart()
	}

	nextSlide() {
		this.setState(prevState => {
			let active_slide = prevState.active_slide
			if (active_slide === Settings.nrSlides) active_slide = 0
			active_slide++
			return { active_slide }
		})
	}

	render() {
		const {
			props: {
				mainScreenVisible,
				aboutVisible,
				pageVisible,
				menuVisible,
				showMenu,
				hideMenu,
				loggedIn,
				firstName,
				lastName,
				popupActive,
				imprintVisible
			},
			experienceHandler,
			logoutHandler,
			hamburgerHandler,
			showImprint,
			sxTechHandler
		} = this
		// const timer = window.setTimeout(
		// 	() => elementClasses.push('visible'),
		// 	2000
		// )

		const { active_slide } = this.state
		let bg = []
		for (let i = 0; i < Settings.nrSlides; i++) {
			bg.push(
				<div
					className={`slide ${
						active_slide === i + 1 ? 'active' : ''
					}`}
					// style={{
					// backgroundImage: `url(${`./img/slideshow_${i}.jpg`})`
					// }}
					key={`slide_${i}`}
				/>
			)
		}

		return (
			<div
				className={`main_screen ${mainScreenVisible ? 'visible' : ''}`}
			>
				{!Settings.safeMode && (
					<div className={`bg ${mainScreenVisible ? 'visible' : ''}`}>
						{bg}
					</div>
				)}
				{Settings.safeMode && (
					<div
						className={`bg ${mainScreenVisible ? 'visible' : ''}`}
						style={{
							backgroundImage: `url("http://placekitten.com/2000/1200")`,
							backgroundPosition: 'center',
							backgroundSize: 'cover'
						}}
					></div>
				)}
				<div className={`about ${aboutVisible ? 'visible' : ''}`}>
					<h2>About</h2>
					<IntroText />
				</div>
				<div className="main_menu">
					<span onClick={this.goToStart} className="logo">
						kokeshi
					</span>
					<div className="nav_left">
						<button className="link" onClick={this.toggleAbout}>
							About
						</button>
						<button className="link" onClick={this.openInstagram}>
							Instagram
						</button>
						<button className="link" onClick={this.pricesHandler}>
							Prices
						</button>
					</div>
					<div className="nav_left_2">
						<button className="link" onClick={this.showSpace}>
							Space
						</button>
						<button className="link" onClick={this.showFaq}>
							FAQ
						</button>
					</div>
					{!loggedIn && (
						<React.Fragment>
							<button
								className="nav meet"
								onClick={this.loginHandler}
							>
								Log In
							</button>
							{/*
							<button
								className="nav register"
								onClick={this.registerHandler}
							>
								Register
							</button>
						*/}
						</React.Fragment>
					)}

					{loggedIn && (
						<React.Fragment>
							<button type="button" className="account">
								{
									//firstName && lastName ? (
									<div className="initials">
										<span>
											{firstName ? firstName[0] : 'A'}
											{lastName ? lastName[0] : 'H'}
										</span>
									</div>
									//) : (
									// <div className="icon icon_user active" />
									//)
								}
							</button>
							<button
								className="nav logout"
								onClick={logoutHandler}
							>
								Log out
							</button>
							<button
								className="nav meet"
								onClick={experienceHandler}
							>
								Book!
							</button>
						</React.Fragment>
					)}

					<button
						className="nav meet primary"
						onClick={sxTechHandler}
					>
						Book SX TECH!
					</button>
					{/*
					<button className="nav space" onClick={this.showSpace}>
						Space
					</button>
				*/}
				</div>

				<button
					className={`imprint_link ${pageVisible ? 'hidden' : ''}`}
					onClick={this.showImprint}
				>
					Imprint
				</button>

				{/* MOBILE MENU */}

				<div className={`mobile_menu ${menuVisible ? 'visible' : ''}`}>
					<div className="link_holder">
						<button
							className="link"
							onClick={() => {
								sxTechHandler()
								hideMenu()
							}}
						>
							Book SX Tech!
						</button>
						{loggedIn && (
							<React.Fragment>
								<button
									className="link"
									onClick={() => {
										experienceHandler()
										hideMenu()
									}}
								>
									Book!
								</button>
								<button
									className="link"
									onClick={() => {
										logoutHandler()
										hideMenu()
									}}
								>
									Log out
								</button>
							</React.Fragment>
						)}
						{!loggedIn && (
							<React.Fragment>
								<button
									className="link register"
									onClick={() => {
										this.registerHandler()
										hideMenu()
									}}
								>
									Register
								</button>
								<button
									className="link meet"
									onClick={() => {
										this.loginHandler()
										hideMenu()
									}}
								>
									Log In
								</button>
							</React.Fragment>
						)}
						<button
							className="link"
							onClick={() => {
								this.toggleAbout()
								hideMenu()
							}}
						>
							About
						</button>
						<button
							className="link"
							onClick={() => {
								this.openInstagram()
								hideMenu()
							}}
						>
							Instagram
						</button>
						<button
							className="link"
							onClick={() => {
								this.pricesHandler()
								hideMenu()
							}}
						>
							Prices
						</button>
						<button
							className="link"
							onClick={() => {
								this.showFaq()
								hideMenu()
							}}
						>
							FAQ
						</button>
						<button
							className="link"
							onClick={() => {
								this.showSpace()
								hideMenu()
							}}
						>
							Space
						</button>
						<button
							className="link"
							onClick={() => {
								showImprint()
								hideMenu()
							}}
						>
							Imprint
						</button>
					</div>
				</div>
				<button
					className={`hamburger hamburger--spin ${
						menuVisible ||
						popupActive ||
						imprintVisible ||
						pageVisible
							? 'is-active'
							: ''
					}`}
					type="button"
					onClick={hamburgerHandler}
				>
					<span className="hamburger-box">
						<span className="hamburger-inner" />
					</span>
				</button>
			</div>
		)
	}
}

MainScreen.propTypes = {
	// loading: PropTypes.bool.isRequired,
	spaceLightboxVisible: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
	mainScreenVisible: state.ui.mainScreenVisible,
	aboutVisible: state.ui.aboutVisible,
	pageVisible: state.ui.pageVisible,
	popupActive: state.ui.popupActive,
	menuVisible: state.ui.menuVisible,
	loggedIn: state.user.loggedIn,
	firstName: state.user.firstName,
	lastName: state.user.lastName,
	imprintVisible: state.ui.imprintVisible,
	spaceLightboxVisible: state.ui.spaceLightboxVisible
})

export default connect(mapStateToProps, {
	showPage,
	hidePage,
	showPopup,
	goToStart,
	sendEvent,
	showMenu,
	hideMenu,
	showPopup,
	hidePage,
	hideAllPopups,
	hideAllPages,
	logout,
	hideSpaceLightbox
})(MainScreen)
