import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as BackIcon } from '../../img/icons/back.svg'

const backHidden = ['voiceNoVoice', 'welcomeSX']

class FormPage extends React.Component {
	constructor(props) {
		super(props)
		this.contentHolder = React.createRef()
	}

	componentDidMount = () => {
		const {
			contentHolder,
			props: { changeHeight, title, activePage }
		} = this
		if (title === activePage) {
			changeHeight(contentHolder.current.getBoundingClientRect().height)
		}
		// }
	}

	componentDidUpdate = oldProps => {
		const {
			contentHolder,
			props: { changeHeight, title, activePage }
		} = this
		if (activePage !== oldProps.activePage && title === activePage) {
			// console.log('this.contentHolder')
			// console.log(contentHolder.current.getBoundingClientRect().height)
			changeHeight(contentHolder.current.getBoundingClientRect().height)
		}
		// }
	}

	recalculateHeight = () => {
		const {
			contentHolder,
			props: { changeHeight }
		} = this

		// console.log(
		// 	`recalculateHeight: ${
		// 		contentHolder.current.getBoundingClientRect().height
		// 	}`
		// )
		changeHeight(contentHolder.current.getBoundingClientRect().height)
	}

	render() {
		const {
			props: {
				children,
				title,
				type,
				activePage,
				activeIndex,
				index,
				nextPage,
				onChange,
				onClick,
				changePage,
				data,
				nextIndex,
				goBack,
				hideNextButton
			},
			recalculateHeight
		} = this

		const childrenProps = {}
		childrenProps.pageTitle = title
		childrenProps.visible = title === activePage
		if (nextPage) childrenProps.nextPage = nextPage
		if (activePage) childrenProps.activePage = activePage
		if (activeIndex) childrenProps.activeIndex = activeIndex
		if (onChange) childrenProps.onChange = onChange
		if (onClick) childrenProps.onClick = onClick
		if (changePage) childrenProps.changePage = changePage
		if (data) childrenProps.data = data
		if (nextIndex) childrenProps.nextIndex = nextIndex
		childrenProps.pageActive = activePage === title ? true : false
		childrenProps.recalculateHeight = recalculateHeight

		return (
			<div
				className={`page ${title}_page ${
					activePage === title ? 'active' : ''
				}${activeIndex < index ? 'right' : ' '}${
					activeIndex > index ? 'left' : ''
				}`}
			>
				<div className="content_wrapper">
					<div className="content_holder" ref={this.contentHolder}>
						{React.Children.map(children, child =>
							React.cloneElement(child, childrenProps)
						)}
					</div>
				</div>
				{!backHidden.includes(title) && (
					<button
						className={`back ${
							backHidden.includes(title) ? 'hidden' : ''
						}`}
						onClick={goBack}
					>
						<BackIcon alt="zurück" className="icon" />
					</button>
				)}
			</div>
		)
	}
}

FormPage.propTypes = {
	title: PropTypes.string.isRequired,
	// type: PropTypes.string.isRequired,
	index: PropTypes.number.isRequired,
	changeHeight: PropTypes.func.isRequired,
	goBack: PropTypes.func.isRequired
}

export default FormPage
