// import shortid from 'shortid'
import {
	CALENDAR_REFRESHED,
	CALENDAR_LOADING,
	SX_REFRESHED,
	SX_LOADING
} from '../actions/types'

const initialState = {
	calendarEntries: [],
	loading: true,
	sx: {
		slots: [],
		loading: true
	}
}

export default function (state = initialState, action) {
	let loading = true
	switch (action.type) {
		case CALENDAR_LOADING:
			loading = true
			action.payload ? (loading = action.payload) : (loading = true)
			return { ...state, loading }
		case CALENDAR_REFRESHED:
			return {
				...state,
				calendarEntries: action.payload.data,
				loading: false
			}
		case SX_LOADING:
			return { ...state, sx: { ...state.sx, loading: true } }
		case SX_REFRESHED:
			return {
				...state,
				sx: { slots: action.payload.data, loading: false }
			}
		default:
			return state
	}
}
