import React from 'react'
import { connect } from 'react-redux'
import {
	hideStartScreen,
	showChatScreen,
	showMainScreen
} from '../../actions/uiActions'
import Settings from '../../Settings'

class StartScreen extends React.Component {
	constructor(props) {
		super(props)
		this.hide = this.hide.bind(this)
	}
	componentDidMount() {}

	hide() {
		this.props.hideStartScreen()
		this.props.showChatScreen()
	}

	render() {
		const { startScreenVisible } = this.props
		// const timer = window.setTimeout(
		// 	() => elementClasses.push('visible'),
		// 	2000
		// )

		return (
			<div
				className={`start_screen ${
					startScreenVisible ? 'visible' : ''
				}`}
				onClick={this.hide}
			>
				<div
					className={`bg_image ${
						startScreenVisible ? 'visible' : ''
					}`}
				/>
				<button
					className={`logo ${startScreenVisible ? 'visible' : ''}`}
					onClick={this.hide}
				>
					<p className="kokeshi">kokeshi</p>
					<p className="jp" />
				</button>
			</div>
		)
	}
}

StartScreen.propTypes = {
	// loading: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
	startScreenVisible: state.ui.startScreenVisible
})

export default connect(mapStateToProps, {
	hideStartScreen,
	showChatScreen,
	showMainScreen
})(StartScreen)
