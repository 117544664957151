import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Swipe from 'react-easy-swipe'

import { showSpaceLightbox, hideSpaceLightbox } from '../../actions/uiActions'

import { ReactComponent as IconForward } from '../../img/icons/icon_forward.svg'
import { ReactComponent as IconForwardActive } from '../../img/icons/icon_forward_active.svg'
import { ReactComponent as IconBack } from '../../img/icons/icon_back.svg'
import { ReactComponent as IconBackActive } from '../../img/icons/icon_back_active.svg'

const basePaddingBottom = 60

class SpaceDisplay extends React.Component {
	constructor(props) {
		super(props)
		this.state = { currentSlide: 0 }
	}

	openLightbox = id => {
		this.setState({ currentSlide: id })
		this.props.showSpaceLightbox()
	}

	closeLightbox = () => {
		this.props.hideSpaceLightbox()
		this.setState({ currentSlide: 0 })
	}

	nextSlide = () => {
		const {
			props: { gallery },
			state: { currentSlide }
		} = this

		const nrSlides = gallery ? gallery.length : null

		if (currentSlide + 1 < nrSlides) {
			this.setState({ currentSlide: currentSlide + 1 })
		}
	}

	lastSlide = () => {
		const {
			props: { apiElement },
			state: { currentSlide }
		} = this

		if (currentSlide > 0) {
			this.setState({ currentSlide: currentSlide - 1 })
		}
	}

	render() {
		const {
			props: { gallery, spaceVisible, spaceLightboxVisible },
			state: { currentSlide },
			openLightbox,
			closeLightbox
		} = this
		// console.log(apiElement)

		const nrSlides = gallery ? gallery.length : null
		// console.log(`nrSlides: ${nrSlides}, currentSlide: ${currentSlide}`)

		if (!nrSlides) return <div className="slider">No Slides Found</div>
		return (
			<>
				<div className="space_gallery">
					<div className="left">
						<div className="inner">
							<div
								className="image"
								style={{
									backgroundImage: `url(${gallery[0].image.sizes.large})`
								}}
								onClick={() => openLightbox(0)}
							/>
						</div>
					</div>
					<div className="middle">
						<div className="inner">
							<div className="top">
								<div
									className="image"
									style={{
										backgroundImage: `url(${gallery[1].image.sizes.large})`
									}}
									onClick={() => openLightbox(1)}
								/>
							</div>
							<div className="bottom">
								<div
									className="image"
									style={{
										backgroundImage: `url(${gallery[2].image.sizes.large})`
									}}
									onClick={() => openLightbox(2)}
								/>
							</div>
						</div>
					</div>
					<div className="right">
						<div className="inner">
							<div className="top">
								<div
									className="image"
									style={{
										backgroundImage: `url(${gallery[3].image.sizes.large})`
									}}
									onClick={() => openLightbox(3)}
								/>
							</div>
							<div className="bottom">
								<div
									className="image"
									style={{
										backgroundImage: `url(${gallery[4].image.sizes.large})`
									}}
									onClick={() => openLightbox(4)}
								/>
							</div>
						</div>
					</div>
				</div>
				<div
					className={`space_gallery_overlay ${
						spaceLightboxVisible && spaceVisible ? 'visible' : ''
					}`}
				>
					<Swipe
						onSwipeRight={this.lastSlide}
						onSwipeLeft={this.nextSlide}
					>
						<div className="content">
							<div className="slider_holder">
								<div className="inner_slider_holder">
									<div className="slider">
										{gallery.map((entry, n) => (
											<div
												className={`slide ${
													n === currentSlide
														? 'visible'
														: ''
												}`}
												key={`space_gallery_slide_${n}`}
											>
												<div
													className="image"
													style={{
														backgroundImage: `url('${entry?.image?.sizes?.large}')`
													}}
												/>
											</div>
										))}
									</div>
								</div>
								<div className="nav_button_holder left">
									{currentSlide !== 0 && (
										<button
											className="nav_button left"
											onClick={this.lastSlide}
											type="button"
										>
											<div className="icon icon_back">
												<IconBack />
											</div>
											<div className="icon icon_back active">
												<IconBackActive />
											</div>
										</button>
									)}
								</div>
								<div className="nav_button_holder right">
									{currentSlide + 1 !== nrSlides && (
										<button
											className="nav_button right"
											onClick={this.nextSlide}
											type="button"
										>
											<div className="icon icon_forward">
												<IconForward />
											</div>
											<div className="icon icon_forward active">
												<IconForwardActive />
											</div>
										</button>
									)}
								</div>
							</div>
							<div className="descriptions">
								{gallery.map((entry, n) => (
									<div
										className={`description ${
											n === currentSlide ? 'visible' : ''
										}`}
										key={`space_gallery_desc_${n}`}
										dangerouslySetInnerHTML={{
											__html: entry.description || null
										}}
									/>
								))}
							</div>
						</div>
						<span className="nr_images">
							{currentSlide + 1} / {gallery.length}
						</span>

						<button
							type="button"
							className="button_close"
							onClick={closeLightbox}
						>
							<div className="icon icon_close" />
						</button>
					</Swipe>
				</div>
			</>
		)
		// return (
		// 	<div className="slider" id={apiElement.anchor_name}>
		// 		{apiElement.slider &&
		// 			Object.values(apiElement.slider).length > 0 && (
		// 				<div
		// 					className="slides_holder"
		// 					style={{
		// 						width: `${
		// 							Object.values(apiElement.slider).length *
		// 							100
		// 						}%`,
		// 						transform: `translate3d(-${
		// 							currentSlide * (100 / nrSlides)
		// 						}%, 0, 0)`
		// 					}}
		// 				>
		// 					{Object.values(apiElement.slider).map(entry => (
		// 						<div
		// 							className="slide"
		// 							style={{
		// 								backgroundImage: `url(${entry.image.sizes.large})`,
		// 								backgroundPosition: 'center',
		// 								width: `${100 / nrSlides}%`,
		// 								paddingBottom: `${
		// 									basePaddingBottom / nrSlides
		// 								}%`
		// 							}}
		// 						>
		// 							{}
		// 						</div>
		// 					))}
		// 					<div className="clearfix" />
		// 				</div>
		// 			)}

		// </div>
		// )
	}
}
SpaceDisplay.propTypes = {
	spaceVisible: PropTypes.bool.isRequired,
	spaceLightboxVisible: PropTypes.bool.isRequired,
	showSpaceLightbox: PropTypes.func.isRequired,
	hideSpaceLightbox: PropTypes.func.isRequired
}
const mapStateToProps = state => ({
	spaceLightboxVisible: state.ui.spaceLightboxVisible
})

export default connect(mapStateToProps, {
	showSpaceLightbox,
	hideSpaceLightbox
})(SpaceDisplay)
