import React from 'react'
import PropTypes from 'prop-types'

class ConfirmationSX extends React.Component {
	render() {
		const { selectedSlot, maxNrPersons, sendOrder } = this.props
		return (
			<>
				<h1>Summary</h1>
				<p>
					<b>
						You need a valid ticket to SX Tech in order to see me at
						this date.
					</b>
				</p>
				<p>
					<b>
						{selectedSlot?.substr(8, 2)}.
						{selectedSlot?.substr(5, 2)}.
						{selectedSlot?.substr(0, 4)}
					</b>
				</p>
				<h2>{selectedSlot?.substr(11, 5)}</h2>
				<p>
					<b>
						{maxNrPersons === 1
							? 'Individual slot'
							: `Group Slot (max ${maxNrPersons} persons)`}
					</b>
				</p>
				<button onClick={sendOrder} className="primary full_width">
					Reserve now
				</button>
			</>
		)
	}
}

ConfirmationSX.propTypes = {
	selectedSlot: PropTypes.oneOfType([
		PropTypes.oneOf([null]),
		PropTypes.string
	]),
	sendOrder: PropTypes.func.isRequired
}

export default ConfirmationSX
