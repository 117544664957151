import Settings from '../Settings'

const formatPrice = (price, currency = Settings.currency) => {
	if (price === null) return 'ERROR'
	if (typeof price !== 'number') {
		console.error(`ERROR: Price: ${price} is not a number`)
		return 'ERROR'
	}
	return `${price.toFixed(2).replace('.', ',')} ${currency}`
}

export default formatPrice
