import React from 'react'
import { connect } from 'react-redux'

import { find as _find, sortBy as _sortBy } from 'lodash-es'

import { login, sendOrderSx } from '../../actions/userActions'
import {
    hideAllPopups,
    showHamburger,
    hideHamburger
} from '../../actions/uiActions'
import { fetchCalendarSX } from '../../actions/calendarActions'

import ConfirmationSX from '../../forms/ConfirmationSX'
import CalendarSX from '../../forms/CalendarSX'
import WelcomeSX from '../../forms/WelcomeSX'
import FormPage from './FormPage'
import _t from '../../utils/translate'

const minHeight = 300
// maxHeight = 450

const initialFormData = {
    nrSlides: 3,
    activeSlide: 1,
    activeSlideName: 'welcomeSX',
    message: null,
    error: false,
    productId: null,
    selectedCalendar: 'main',
    history: [{ index: 1, name: 'welcomeSX' }],
    height: 450,
    cart: { total: 0, items: [] },
    selectedSlot: null,
    maxNrPersons: 0,
    slotId: null
}
const resetFormData = {
    nrSlides: 3,
    activeSlide: 1,
    activeSlideName: 'welcomeSX',
    message: null,
    error: false,
    selectedCalendar: 'main',
    history: [{ index: 1, name: 'welcomeSX' }],
    height: 450,
    cart: { total: 0, items: [] },
    selectedSlot: null,
    maxNrPersons: 0,
    slotId: null
}

class SxPopup extends React.Component {
    static propTypes = {}

    constructor(props) {
        super(props)

        this.state = initialFormData
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { showHamburger, hideHamburger } = this.props
        if (prevProps.sxPopupVisible && !this.props.sxPopupVisible)
            setTimeout(() => {
                this.setState(resetFormData)
            }, 500)

        if (!prevProps.sxPopupVisible && this.props.sxPopupVisible) {
            this.loadEvents()
        }

        if (prevState.message === null && this.state.message !== null) {
            hideHamburger()
        }
        if (prevState.message !== null && this.state.message === null) {
            showHamburger()
        }
        if (prevState.error === null && this.state.error !== null) {
            hideHamburger()
        }
        if (prevState.error !== null && this.state.error === null) {
            showHamburger()
        }
        if (!this.props.products.loading && prevProps.products.loading) {
            this.findProduct()
        }
    }

    loadEvents = () => {
        this.props
            .fetchCalendarSX()
            .then(res => {
                // calcPrice()
                // console.log(res)
                return res
            })
            .catch(err => {
                alert(err.message)
            })
    }

    findProduct = () => {
        const { products } = this.props
        if (products.loading) return null
        const selectedProduct = _find(products.products, {
            slug: 'sx-tech-slot'
        })
        this.setState({ productId: selectedProduct?.id })
    }

    changeData = (dataKey, dataValue) => {
        if (dataKey === 'voiceNoVoice' && dataValue === 'no_voice')
            this.setState({
                selectedCalendar: 'main'
            })

        if (dataKey === 'voice')
            this.setState({
                selectedCalendar: dataValue
            })

        this.setState({
            [dataKey]: dataValue
        })
    }

    changeHeight = height => {
        let heightToSet = height
        if (height < minHeight) heightToSet = minHeight

        this.setState({
            height: heightToSet + 60
        })
    }
    changePage = (page, index) => {
        this.setState(oldState => ({
            history: [
                ...oldState.history,
                {
                    name: page,
                    index: index
                        ? index
                        : oldState.history[oldState.history.length - 1].index +
                          1
                }
            ]
        }))
    }

    goBack = () => {
        // this.scrollToTop()
        this.setState(oldState => ({
            history:
                oldState.history.length > 1
                    ? [...oldState.history.slice(0, -1)]
                    : [...oldState.history]
        }))
    }

    finishBooking = () => {
        const { hideAllPopups } = this.props
        hideAllPopups()
        setTimeout(() => this.setState({ message: null, activeSlide: 1 }), 2000)
    }

    goToSlide = slide => this.setState({ activeSlide: slide })

    hideError = () => this.setState({ message: null, error: false })

    sendOrder = data => {
        const {
            state: { productId, selectedSlot, slotId },
            props: { sendOrderSx },
            displayMessage
        } = this

        displayMessage('loading_generic')

        const formData = {
            ...data,
            cart: {
                items: [{ id: productId, quantity: 1 }]
            },
            selectedSlot,
            slotId
        }
        // console.log(formData)
        sendOrderSx(formData)
            .then(res => {
                displayMessage('sx_booked')
            })
            .catch(err => {
                displayMessage(
                    err.response &&
                        err.response.data &&
                        err.response.data.message
                        ? _t(err.response.data.message)
                        : _t('There was a problem. Please try again later.'),
                    'error'
                )
                console.error('error creating the order:')
                console.error(err.response)
            })
        console.log(formData)
    }

    displayMessage = (message, type) => {
        // console.log(message)
        this.setState({ message, error: type === 'error' })
    }

    render() {
        const {
            props: { sxPopupVisible, sxSlots, products },
            state: {
                nrSlides,
                activeSlide,
                message,
                error,
                productId,
                history,
                height,
                cart,
                selectedSlot,
                lengthHours,
                maxNrPersons
            },
            changeData,
            finishBooking,
            goToSlide,
            displayMessage,
            hideError,
            paymentSuccess,
            sendOrder,
            changePage,
            changeHeight,
            goBack
        } = this

        // console.log('cart')
        // console.log(cart)

        const activePage =
            history.length > 0 ? history[history.length - 1].name : ''
        const activeIndex =
            history.length > 0 ? history[history.length - 1].index : 0

        return (
            <div
                className={`popup slider sx_popup ${
                    sxPopupVisible ? 'active' : ''
                }`}
                style={{ height: height }}
            >
                <div className="pages">
                    <FormPage
                        changePage={changePage}
                        changeHeight={changeHeight}
                        goBack={goBack}
                        title="welcomeSX"
                        index={1}
                        activePage={activePage}
                        activeIndex={activeIndex}
                    >
                        <WelcomeSX />
                    </FormPage>

                    <FormPage
                        changePage={changePage}
                        changeHeight={changeHeight}
                        goBack={goBack}
                        title="calendarSX"
                        index={2}
                        activePage={activePage}
                        activeIndex={activeIndex}
                    >
                        <CalendarSX
                            displayMessage={displayMessage}
                            changeData={changeData}
                            selectedSlot={selectedSlot}
                        />
                    </FormPage>

                    <FormPage
                        changePage={changePage}
                        changeHeight={changeHeight}
                        goBack={goBack}
                        title="confirmationSX"
                        index={3}
                        activePage={activePage}
                        activeIndex={activeIndex}
                    >
                        <ConfirmationSX
                            selectedSlot={selectedSlot}
                            maxNrPersons={maxNrPersons}
                            sendOrder={sendOrder}
                        />
                    </FormPage>
                </div>
                <div
                    className={`loading ${
                        message === 'loading_generic' ? 'active' : ''
                    }`}
                >
                    <div className="content">
                        <span>{_t('Loading...')}</span>
                    </div>
                </div>
                <div
                    className={`loading ${
                        sxSlots.loading || products.loading ? 'active' : ''
                    }`}
                >
                    <span>
                        <div className="content">
                            {_t('Checking for free slots...')}
                        </div>
                    </span>
                </div>

                <div
                    className={`message ${
                        message === 'sx_booked' ? 'active' : ''
                    }`}
                >
                    <span>
                        <div className="content">
                            <h3>Thank you!</h3>
                            <p>
                                Your SX Tech slot has successfully been booked!
                                <br />
                                You will recieve a confirmation in your emails
                                soon.
                            </p>
                            <button
                                onClick={finishBooking}
                                className="error_ok"
                            >
                                {_t('OK', 'error')}
                            </button>
                        </div>
                    </span>
                </div>

                <div className={`error ${error ? 'active' : ''}`}>
                    <span>
                        <div className="content">
                            <span>{_t(message)}</span>
                            <button onClick={hideError} className="error_ok">
                                {_t('OK', 'error')}
                            </button>
                        </div>
                    </span>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    sxPopupVisible: state.ui.sxPopupVisible,
    sxSlots: state.calendar.sx,
    products: state.products
})

export default connect(mapStateToProps, {
    login,
    hideAllPopups,
    sendOrderSx,
    showHamburger,
    hideHamburger,
    fetchCalendarSX
})(SxPopup)
