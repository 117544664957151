import React from 'react'
import PropTypes from 'prop-types'
//
import { connect } from 'react-redux'

import { typingStarted, typingFinished } from './actions/uiActions'

const DEBUG = false

class Type extends React.Component {
	state = { visibleText: '', charactersVisible: 0, inProgress: false }

	constructor(props) {
		super(props)
		this.typeCharacter = this.typeCharacter.bind(this)
	}

	componentDidMount() {
		let { ms, enabled, text, isTyping, typingStarted } = this.props
		const { inProgress } = this.state
		if (text.length > 400) {
			ms = 20
		}
		if (enabled && !isTyping && !this.state.inProgress) {
			DEBUG && console.log('typing started because of mount')
			typingStarted()
			this.setState({ inProgress: true })
			this.interval = window.setInterval(() => {
				this.typeCharacter()
				// console.log('x')
			}, ms)
		}
	}

	componentDidUpdate(prevProps) {
		let { ms, typingStarted, isTyping, text, enabled } = this.props
		if (text.length > 400) {
			ms = 20
		}
		if (enabled && !isTyping && !this.state.inProgress) {
			DEBUG && console.log('typing started because of update')
			typingStarted()
			this.setState({ inProgress: true })
			window.setTimeout(() => {
				this.interval = window.setInterval(() => {
					this.typeCharacter()
					// console.log('x')
				}, ms)
			}, 1000)
		}
	}

	componentWillUnmount() {
		this.props.typingFinished()
		clearInterval(this.interval)
	}
	typeCharacter() {
		const { onType } = this
		const { active, text, typingFinished } = this.props

		if (active) {
			this.setState(
				prevState => ({
					charactersVisible: prevState.charactersVisible + 1
				}),
				() => {
					// console.log(this.state.charactersVisible)
					// console.log(
					// 	this.state.charactersVisible +
					// 		': ' +
					// 		text.substr(0, this.state.charactersVisible)
					// )
					this.props.onType()
				}
			)
		}
		if (this.state.charactersVisible >= text.length) {
			clearInterval(this.interval)
			typingFinished()
			DEBUG && console.log('typingFinished')
		}
	}

	render() {
		const { text, enabled } = this.props
		const { visibleText, charactersVisible } = this.state
		if (!enabled) return text
		return text.substr(0, charactersVisible)
	}
}

Type.defaultProps = {
	onType: () => {},
	ms: 50,
	active: true,
	enabled: true
}

Type.propTypes = {
	onType: PropTypes.func,
	ms: PropTypes.number
}

const mapStateToProps = state => ({
	isTyping: state.ui.typing
})

export default connect(mapStateToProps, { typingStarted, typingFinished })(Type)
