import React from 'react'
import { connect } from 'react-redux'
import { find as _find } from 'lodash-es'

import {
    showPage,
    hidePage,
    showPopup,
    goToStart
} from '../../actions/uiActions'

import { sendEvent } from '../../actions/chatActions'

class Imprint extends React.Component {
    constructor(props) {
        super(props)
        this.closePage = this.closePage.bind(this)
    }

    closePage() {
        const { hidePage } = this.props
        hidePage('imprint')
    }

    render() {
        const { closePage } = this
        const { pages, imprintVisible } = this.props
        const page = _find(pages, { slug: 'imprint' })

        // console.log(page)

        return (
            <div className={`imprint ${imprintVisible ? 'visible' : ''}`}>
                <div className="text_wrapper">
                    <h1>{page && page.title.rendered}</h1>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: (page && page.content.rendered) || false
                        }}
                    />
                </div>
                <button
                    type="button"
                    className="button_close"
                    onClick={closePage}
                >
                    <div className="icon icon_close" />
                </button>
            </div>
        )
    }
}

Imprint.propTypes = {
    // loading: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
    imprintVisible: state.ui.imprintVisible,
    pages: state.pages
})

export default connect(mapStateToProps, { hidePage })(Imprint)
