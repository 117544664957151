import ReactGA from 'react-ga'

import {
    SHOW_START_SCREEN,
    HIDE_START_SCREEN,
    SHOW_CHAT,
    SHOW_CHAT_SCREEN,
    HIDE_CHAT_BACKGROUND,
    SHOW_MAIN_SCREEN,
    HIDE_MAIN_SCREEN,
    SHOW_PAGE,
    HIDE_PAGE,
    SHOW_POPUP,
    HIDE_POPUP,
    GO_TO_START,
    STARTED_TYPING,
    FINISHED_TYPING,
    SHOW_MENU,
    HIDE_MENU,
    SHOW_LOGIN_POPUP,
    HIDE_ALL_POPUPS,
    SHOW_MESSAGE,
    SHOW_REGISTER_POPUP,
    HIDE_REGISTER_POPUP,
    SHOW_COMPLETE_RESET_PASSWORD_POPUP,
    HIDE_COMPLETE_RESET_PASSWORD_POPUP,
    SHOW_HAMBURGER,
    HIDE_HAMBURGER,
    HIDE_ALL_PAGES,
    SHOW_SPACE_LIGHTBOX,
    HIDE_SPACE_LIGHTBOX
} from './types'

export const showStartScreen = () => dispatch => {
    ReactGA.pageview('start_screen')
    dispatch({
        type: SHOW_START_SCREEN
    })
}

export const hideStartScreen = () => dispatch => {
    dispatch({
        type: HIDE_START_SCREEN
    })
}

export const showChat = () => dispatch => {
    dispatch({
        type: SHOW_CHAT
    })
}

export const showChatScreen = () => dispatch => {
    ReactGA.pageview('chat_screen')
    dispatch({
        type: SHOW_CHAT_SCREEN
    })
}
export const hideChatBackground = () => dispatch => {
    dispatch({
        type: HIDE_CHAT_BACKGROUND
    })
}

export const showMainScreen = () => dispatch => {
    ReactGA.pageview('main_screen')
    dispatch({
        type: SHOW_MAIN_SCREEN
    })
}
export const hideMainScreen = () => dispatch => {
    dispatch({
        type: HIDE_MAIN_SCREEN
    })
}
export const showPage = page => dispatch => {
    console.log(`show: ${page}`)
    dispatch({
        type: SHOW_PAGE,
        payload: page
    })
}
export const hidePage = page => dispatch => {
    console.log(`hide: ${page}`)
    dispatch({
        type: HIDE_PAGE,
        payload: page
    })
}
export const hideAllPages = () => dispatch => {
    dispatch({
        type: HIDE_ALL_PAGES
    })
}
export const showPopup = popupName => dispatch => {
    if (popupName === 'register')
        dispatch({
            type: SHOW_PAGE,
            payload: 'registerQuestions'
        })
    dispatch({
        type: SHOW_POPUP,
        payload: popupName
    })
}
export const hidePopup = popup => dispatch => {
    dispatch({
        type: HIDE_POPUP,
        payload: popup
    })
}
export const hideAllPopups = () => dispatch => {
    dispatch({
        type: HIDE_ALL_POPUPS
    })
}
export const goToStart = () => dispatch => {
    dispatch({ type: GO_TO_START })
}

export const typingStarted = () => dispatch => {
    dispatch({ type: STARTED_TYPING })
}

export const typingFinished = () => dispatch => {
    dispatch({ type: FINISHED_TYPING })
}

export const showMenu = () => dispatch => {
    dispatch({ type: SHOW_MENU })
}

export const hideMenu = () => dispatch => {
    dispatch({ type: HIDE_MENU })
}

export const showLoginPopup = () => dispatch => {
    dispatch({ type: SHOW_LOGIN_POPUP })

    dispatch({
        type: SHOW_POPUP
    })
}

export const showMessage = message => dispatch => {
    dispatch({ type: SHOW_MESSAGE, payload: message })
}
export const showRegisterPopup = message => dispatch => {
    dispatch({ type: SHOW_REGISTER_POPUP })
}
export const hideRegisterPopup = message => dispatch => {
    dispatch({ type: HIDE_REGISTER_POPUP })
}
export const showCompleteResetPasswordPopup = message => dispatch => {
    dispatch({ type: SHOW_COMPLETE_RESET_PASSWORD_POPUP })
}
export const hideCompleteResetPasswordPopup = message => dispatch => {
    dispatch({ type: HIDE_COMPLETE_RESET_PASSWORD_POPUP })
}

export const showHamburger = () => dispatch => {
    dispatch({
        type: SHOW_HAMBURGER
    })
}

export const hideHamburger = () => dispatch => {
    dispatch({
        type: HIDE_HAMBURGER
    })
}

export const showSpaceLightbox = () => dispatch => {
    dispatch({
        type: SHOW_SPACE_LIGHTBOX
    })
}

export const hideSpaceLightbox = () => dispatch => {
    dispatch({
        type: HIDE_SPACE_LIGHTBOX
    })
}
