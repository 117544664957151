import React from 'react'
import { connect } from 'react-redux'

import { completeResetPassword, login } from '../../actions/userActions'
import { hideAllPopups } from '../../actions/uiActions'

import CompleteResetPasswordForm from '../../forms/CompleteResetPasswordForm'

class CompleteRegisterPopup extends React.Component {
    static propTypes = {}

    constructor(props) {
        super(props)
        this.submit = this.submit.bind(this)
    }

    submit = data => {
        console.log('resetPasswordPopup submitted.')
        const { completeResetPassword, resetPasswordToken } = this.props
        return completeResetPassword({
            password: data.new_password_reset,
            token: resetPasswordToken
        })
    }

    login = data => {
        console.log('login!')
        return this.props.login(data).then(res => {
            console.log(res)
            return res
        })
    }

    render() {
        const {
            props: { completeCompleteResetPasswordPopupVisible },
            submit,
            login
        } = this
        return (
            <div
                className={`popup complete_register_popup ${
                    completeCompleteResetPasswordPopupVisible ? 'active' : ''
                }`}
            >
                <div className="content_wrapper">
                    <div className="content">
                        <CompleteResetPasswordForm
                            submit={submit}
                            login={login}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    completeCompleteResetPasswordPopupVisible:
        state.ui.completeCompleteResetPasswordPopupVisible,
    resetPasswordToken: state.user.resetPasswordToken
})

export default connect(mapStateToProps, {
    hideAllPopups,
    completeResetPassword,
    login
})(CompleteRegisterPopup)
