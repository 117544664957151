import { format } from 'date-fns'

import { store } from '../store/store'
import Settings from '../Settings'
import getPossibleTimeSlots from './getPossibleTimeSlots.js'

function calcLocalTime(city, offset) {
	// create Date object for current location
	var d = new Date()

	// convert to msec
	// subtract local time zone offset
	// get UTC time in msec
	var utc = d.getTime() + d.getTimezoneOffset() * 60000

	// create new Date object for different city
	// using supplied offset
	var nd = new Date(utc + 3600000 * offset)

	// return time as a string
	return nd
}

const calculateTimeSlots = (length, date, selectedCalendar = null) => {
	let calendar = selectedCalendar
	if (!selectedCalendar) {
		console.error('calculateTimeSlots called without selectedCalendar')
		calendar = 'main'
	}
	const possibleTimeSlots = getPossibleTimeSlots()
	let isHourSlot = false
	if (typeof length === 'number') {
		length = `${length}h`
		isHourSlot = true
	}

	if (!(length in possibleTimeSlots)) {
		console.error(
			`Type Error: length "${length}" not found in calculateTimeSlots()`
		)
	}
	if (store.getState().calendar.loading) return { slots: [], nrSlots: 0 }

	const allHourTImeSlots = possibleTimeSlots['1h']

	let blockedTimeSlots = null
	if (
		store.getState().calendar.calendarEntries[Settings.dollName][calendar]
	) {
		blockedTimeSlots = store.getState().calendar.calendarEntries[
			Settings.dollName
		][calendar]
	} else {
		// If calendar is not found, fall back to main calendar
		// console.error(`Could't find calendar: ${Settings.dollName}.${calendar}`)
		blockedTimeSlots = store.getState().calendar.calendarEntries[
			Settings.dollName
		].main
	}

	// console.log(`blockedTimeSlots[${length}]`)
	// console.log(blockedTimeSlots[length])

	const timeSlots = {}

	allHourTImeSlots.map(slot => {
		timeSlots[slot] = { time: slot, available: false }
	})

	possibleTimeSlots[length].map(slot => {
		timeSlots[slot] = { time: slot, available: true }
	})

	let nrUnavailable = 0

	const formattedDate = format(date, 'yyyy-MM-dd')
	if (blockedTimeSlots) {
		if (length in blockedTimeSlots) {
			if (formattedDate in blockedTimeSlots[length]) {
				blockedTimeSlots[length][formattedDate].map(slot => {
					nrUnavailable++
					timeSlots[slot] = { time: slot, available: false }
				})
			}
		}
	}
	// console.log('calculateTimeSlots')
	// console.log({
	// 	slots: availableTimeSlots,
	// 	nrSlots: availableTimeSlots.length
	// })

	const timeBerlin = calcLocalTime('Berlin', '+2')

	const formattedDateBerlin = format(timeBerlin, 'yyyy-MM-dd')
	if (formattedDate === formattedDateBerlin)
		return {
			date: formattedDate,
			slots: [],
			nrSlots: 0
		}

	const timeSlotsArray = []

	Object.keys(timeSlots).map(key => {
		timeSlotsArray.push(timeSlots[key])
	})

	const result = {
		date: formattedDate,
		slots: timeSlotsArray,
		nrSlots: possibleTimeSlots[length].length - nrUnavailable
	}
	return result
}

export default calculateTimeSlots
