import React from 'react'
import { connect } from 'react-redux'
import ReactPixel from 'react-facebook-pixel'

import { completeRegisterUser, login } from '../../actions/userActions'
import { hideAllPopups } from '../../actions/uiActions'

import CompleteRegisterForm from '../../forms/CompleteRegisterForm'

class CompleteRegisterPopup extends React.Component {
    static propTypes = {}

    constructor(props) {
        super(props)
        this.submit = this.submit.bind(this)
    }

    submit = data => {
        console.log('registerPopup submitted.')
        ReactPixel.track('CompleteRegistration')
        return this.props.completeRegisterUser(data).then(res => {
            return res
        })
    }

    login = data => {
        console.log('login!')
        return this.props.login(data).then(res => {
            console.log(res)
            return res
        })
    }

    render() {
        const {
            props: { completeRegisterPopupVisible },
            submit,
            login
        } = this
        return (
            <div
                className={`popup complete_register_popup ${
                    completeRegisterPopupVisible ? 'active' : ''
                }`}
            >
                <div className="content_wrapper">
                    <div className="content">
                        <CompleteRegisterForm submit={submit} login={login} />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    completeRegisterPopupVisible: state.ui.completeRegisterPopupVisible
})

export default connect(mapStateToProps, {
    hideAllPopups,
    completeRegisterUser,
    login
})(CompleteRegisterPopup)
