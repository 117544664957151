import { combineReducers } from 'redux'
import pagesReducer from './pagesReducer'
import uiReducer from './uiReducer'
import chatReducer from './chatReducer'
import uuidReducer from './uuidReducer'
import userReducer from './userReducer'
import calendarReducer from './calendarReducer'
import productsReducer from './productsReducer'

const rootReducer = combineReducers({
	pages: pagesReducer,
	ui: uiReducer,
	chat: chatReducer,
	uuid: uuidReducer,
	calendar: calendarReducer,
	user: userReducer,
	products: productsReducer
})

export default rootReducer
