import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'

import { login } from '../../actions/userActions'
import { hideAllPopups, showPopup } from '../../actions/uiActions'
import { sendEvent } from '../../actions/chatActions'

import _t from '../../utils/translate'
import Settings from '../../Settings'

const initialState = {
    message: '',
    messageCallback: null
}

class SxLoggedOutPopup extends React.Component {
    constructor(props) {
        super(props)
        this.state = initialState
    }

    register = () => {
        const { userHasSignedUp, sendEvent, hideAllPopups, email } = this.props
        ReactGA.pageview('register')
        if (!userHasSignedUp) {
            sendEvent('register_no_ok', false)
            ReactPixel.track('Lead')
        }
        if (userHasSignedUp) sendEvent('register_again', false, { email })
        hideAllPopups()
        setTimeout(() => {
            this.setState({ message: '', messageCallback: null })
        }, 1000)
    }

    login = () => {
        const { hideAllPopups, showPopup } = this.props
        hideAllPopups()
        showPopup('loginPopup')
    }

    onRegisterConfirm = e => {
        e.preventDefault()
        this.displayPopupMessage(
            Settings.messages.registerConfirm,
            this.onRegister
        )
    }

    onRegister = e => {
        e.preventDefault()
        const { register } = this
        setTimeout(() => {
            this.setState({ message: '', messageCallback: null })
        }, 1000)
        register()
    }

    displayPopupMessage = (message, messageCallback) => {
        this.setState({ message, messageCallback })
    }

    render() {
        const {
            props: { sxLoggedOutPopupVisible },
            state: { message, messageCallback },
            submit,
            closeMessage,
            onRegisterConfirm,
            register,
            login
        } = this
        return (
            <div
                className={`popup sx_loggedout_popup ${
                    sxLoggedOutPopupVisible ? 'active' : ''
                }`}
            >
                <div className="content_wrapper">
                    <div className="content">
                        <h1>SX Tech</h1>
                        <p>
                            In order to book a slot for SX Tech, please login or
                            register
                        </p>
                        <button className="primary full_width" onClick={login}>
                            Log In
                        </button>
                        <p className="or">or</p>
                        <button
                            className="primary full_width"
                            onClick={onRegisterConfirm}
                        >
                            Register
                        </button>
                    </div>
                </div>

                <div className={`message ${message !== '' ? 'active' : ''}`}>
                    <div className="content">
                        <span>{_t(message)}</span>
                        <button onClick={messageCallback || closeMessage}>
                            OK
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    sxLoggedOutPopupVisible: state.ui.sxLoggedOutPopupVisible,
    userHasSignedUp: state.user.userHasSignedUp,
    email: state.user.email
})

SxLoggedOutPopup.propTypes = {
    sxLoggedOutPopupVisible: PropTypes.bool.isRequired
}

export default connect(mapStateToProps, {
    login,
    hideAllPopups,
    sendEvent,
    showPopup
})(SxLoggedOutPopup)
