const processResponse = (res, userInput) => {
	let parameters = {}
	console.log(res.result.metadata.intentName)
	console.log(res.result)
	switch (res.result.metadata.intentName) {
		case '04 - girlfriend':
			parameters = { ...parameters, girlfriend_fckbuddy: 'girlfriend' }
			break

		case '04 - fun':
			parameters = { ...parameters, girlfriend_fckbuddy: 'fun' }
			break

		case '05 - dominative':
			parameters = { ...parameters, dominative_submissive: 'dominative' }
			break

		case '05 - submissive':
			parameters = { ...parameters, dominative_submissive: 'submissive' }
			break

		case '05 - both':
			parameters = { ...parameters, dominative_submissive: 'both' }
			break

		case '06 - fetish':
			parameters = { ...parameters, fetishes: userInput }
			break

		case '06 - fetish default':
			parameters = { ...parameters, fetishes: userInput }
			break

		case '06 - No fetish':
			parameters = { ...parameters, fetishes: 'none' }
			break

		case '07 - fallback':
			parameters = { ...parameters, what_turns_on: userInput }
			break

		case '08 - fallback':
			parameters = { ...parameters, movies_porn: userInput }
			break

		case '09 - toys':
			parameters = { ...parameters, toys: userInput }
			break

		case '10 - City Berlin':
			parameters = { ...parameters, city: 'Berlin' }
			break

		case '12 - confirm email Yes':
			parameters = { ...parameters, userHasSignedUp: true }
			break

		default:
			break
	}

	if (
		res &&
		res.result &&
		res.result.parameters &&
		res.result.parameters &&
		res.result.parameters.name
	) {
		parameters = { ...parameters, firstName: res.result.parameters.name }
	}
	if (
		res &&
		res.result &&
		res.result.parameters &&
		res.result.parameters &&
		res.result.parameters.Name
	) {
		parameters = { ...parameters, firstName: res.result.parameters.Name }
	}

	if (
		res &&
		res.result &&
		res.result.parameters &&
		res.result.parameters['phone-number']
	) {
		console.log(
			`Captured Phone number: ${res.result.parameters['phone-number']}`
		)
	}

	// console.log(parameters)

	return parameters
}

export default processResponse
