//
import { PRODUCTS_LOADED, SET_LOADING } from '../actions/types'

const initialState = {
    loading: true,
    products: []
}

// let result

export default function (state = initialState, action) {
    switch (action.type) {
        case PRODUCTS_LOADED:
            return {
                ...state,
                loading: false,
                products: [...action.payload]
            }
        case SET_LOADING:
            return initialState
        default:
            return state
    }
}
