import shortid from 'shortid'
import {
	ADD_CHAT_USER,
	ADD_CHAT_KOKESHI,
	SESSION_ID,
	WAITING_FOR_ANSWER,
	FORCE_CHAT_START
} from '../actions/types'

const initialState = {
	chatMessages: [
		// {
		// 	type: 'kokeshi',
		// 	message: "Hi, I'm Kokeshi! Who are you?",
		// 	id: 'jq6AjoCbAm'
		// }
	],
	waitingForAnswer: false,
	uuid: shortid.generate(),
	contexts: [],
	forceStart: false,
	lastMessageUser: ''
}

export default function(state = initialState, action) {
	switch (action.type) {
		case WAITING_FOR_ANSWER:
			return { ...state, waitingForAnswer: true }
		case ADD_CHAT_USER:
			return {
				...state,
				chatMessages: [
					...state.chatMessages,
					{
						type: 'user',
						message: action.payload,
						id: shortid.generate()
					}
				],
				lastMessageUser: action.payload
			}
		case ADD_CHAT_KOKESHI:
			return {
				...state,
				chatMessages: [
					...state.chatMessages,
					{
						type: 'kokeshi',
						message: action.payload.message,
						id: shortid.generate()
					}
				],
				waitingForAnswer: false,
				contexts: action.payload.contexts
			}

		case SESSION_ID:
			return { ...state, uuid: action.payload }
		case FORCE_CHAT_START:
			return { ...state, forceStart: true }
		default:
			return state
	}
}
