import { find as _find } from 'lodash-es'
import axios from 'axios'

import { store } from '../store/store'
import translations from './translations'

import Settings from '../Settings'

const LOG_ON_SERVER = false

const sendToServer = (input, namespace) => {
    // const instance = axios.create({
    //  timeout: 10000,
    //  transformRequest: [
    //      (data, headers) => {
    //          delete headers.common.Authorization
    //          return data
    //      }
    //  ]
    // })
    // instance
    //  .post(
    //      'https://api.one-squared.com/sn/translations/public/_/items/sn_translations_log',
    //      { string: input, namespace },
    //      {}
    //  )
    //  .then(res => console.log(res))
    //  .catch(err => console.error(err))
    fetch(
        'https://api.one-squared.com/sn/translations/public/_/items/sn_translations_log',
        {
            method: 'POST', // or 'PUT'
            body: JSON.stringify({
                string: input,
                namespace,
                uid: `${input}---${namespace}`
            }), // data can be `string` or {object}!
            headers: {
                'Content-Type': 'application/json'
            }
        }
    )
}

const translate = (input, namespace = 'general', replace = {}) => {
    // const language = 'fr'
    let { language } = store.getState().ui

    // console.log(language)

    if (language == null) language = 'en'
    // let searchIn
    // if (namespace) searchIn = translations[language][namespace]
    const searchIn = translations[language][namespace]

    if (input === null) {
        return null
    }

    const find = _find(searchIn, x => x[0] === input)
    let translated = input
    if (find) {
        translated = find[1]
    }

    if (LOG_ON_SERVER && !find && language === 'fr')
        sendToServer(input, namespace)

    if (replace !== {}) {
        // console.log(Object.keys(replace).length)
    }
    if (Object.keys(replace).length > 0) {
        // console.log(`input: ${input}`)
        // console.log(`find: ${find}`)
        for (var key in replace) {
            // console.log(new RegExp("\\{" + key + "\\}", "gi"))
            // console.log(translated.replace(new RegExp("\\{" + key + "\\}", "gi"), replace[key]))

            translated = translated.replace(
                new RegExp('\\{' + key + '\\}', 'gi'),
                replace[key]
            )
        }
        // console.log(`translated: ${translated}`)
    }

    return translated
}

export default translate
