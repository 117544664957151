import {
    SHOW_START_SCREEN,
    HIDE_START_SCREEN,
    SHOW_CHAT,
    SHOW_CHAT_SCREEN,
    HIDE_CHAT_BACKGROUND,
    SHOW_MAIN_SCREEN,
    HIDE_MAIN_SCREEN,
    SHOW_PAGE,
    HIDE_PAGE,
    HIDE_ALL_PAGES,
    SHOW_POPUP,
    HIDE_POPUP,
    GO_TO_START,
    USER_SIGNUP_LOADING,
    USER_REGISTERED,
    STARTED_TYPING,
    FINISHED_TYPING,
    SHOW_MENU,
    HIDE_MENU,
    SHOW_LOGIN_POPUP,
    HIDE_ALL_POPUPS,
    SHOW_REGISTER_POPUP,
    HIDE_REGISTER_POPUP,
    SHOW_COMPLETE_RESET_PASSWORD_POPUP,
    HIDE_COMPLETE_RESET_PASSWORD_POPUP,
    SHOW_HAMBURGER,
    HIDE_HAMBURGER,
    SHOW_SPACE_LIGHTBOX,
    HIDE_SPACE_LIGHTBOX
} from '../actions/types'

import Settings from '../Settings'

const initialPages = {
    pageVisible: false,
    faqVisible: false,
    imprintVisible: false,
    spaceVisible: false
}

let initialState = {
    startScreenVisible: false,
    registerVisible: false,
    registerFormVisible: false,
    registerQuestionsVisible: false,
    firstInteraction: true,
    popupActive: false,
    chatWindowVisible: false,
    chatWindowSize: 'hidden',
    typing: false,
    pageVisible: false,
    menuVisible: false,
    imprintVisible: false,
    spaceVisible: false,
    faqVisible: false,
    loginPopupVisible: false,
    meetPopupVisible: false,
    sxPopupVisible: false,
    completeRegisterPopupVisible: false,
    forgotPasswordPopupVisible: false,
    completeCompleteResetPasswordPopupVisible: false,
    hamburgerVisible: true,
    spaceLightboxVisible: false,
    sxLoggedOutPopupVisible: false
}

if (Settings.devMode) {
    initialState = {
        startScreenVisible: false,
        registerVisible: false,
        registerFormVisible: false,
        registerQuestionsVisible: false,
        firstInteraction: false,
        popupActive: false,
        chatWindowVisible: false,
        chatWindowSize: 'third',
        faqVisible: false,
        typing: false,
        sxPopupVisible: false,
        pageVisible: false,
        spaceVisible: false,
        menuVisible: false,
        loginPopupVisible: false,
        chatVisible: true,
        chatBackgroundVisible: false,
        mainScreenVisible: true,
        completeRegisterPopupVisible: false,
        forgotPasswordPopupVisible: false,
        completeCompleteResetPasswordPopupVisible: false,
        hamburgerVisible: true,
        spaceLightboxVisible: false,
        sxLoggedOutPopupVisible: false
    }
}

const startWithPopupState = {
    ...initialState,
    startScreenVisible: false,
    registerVisible: false,
    registerFormVisible: false,
    registerQuestionsVisible: false,
    popupActive: true,
    chatWindowVisible: true,
    chatWindowSize: 'third',
    faqVisible: false,
    pageVisible: false,
    sxPopupVisible: false,
    menuVisible: false,
    loginPopupVisible: false,
    chatVisible: true,
    spaceVisible: false,
    chatBackgroundVisible: false,
    mainScreenVisible: true,
    completeRegisterPopupVisible: false,
    forgotPasswordPopupVisible: false,
    completeCompleteResetPasswordPopupVisible: false,
    hamburgerVisible: true,
    spaceLightboxVisible: false,
    sxLoggedOutPopupVisible: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SHOW_START_SCREEN:
            return {
                ...state,
                startScreenVisible: true,
                firstInteraction: false
            }
        case HIDE_START_SCREEN:
            return { ...state, startScreenVisible: false }
        case SHOW_CHAT_SCREEN:
            return {
                ...state,
                chatBackgroundVisible: true
            }
        case SHOW_CHAT:
            return {
                ...state,
                chatVisible: true,
                chatWindowSize: 'half'
            }
        case HIDE_CHAT_BACKGROUND:
            return {
                ...state,
                chatBackgroundVisible: false,
                chatWindowSize: 'third'
            }
        case SHOW_MAIN_SCREEN:
            return {
                ...state,
                mainScreenVisible: true,
                chatVisible: true,
                chatWindowSize: 'third'
            }
        case HIDE_MAIN_SCREEN:
            return { ...state, mainScreenVisible: false }
        case SHOW_PAGE:
            return {
                ...state,
                [`${action.payload}Visible`]: true,
                pageVisible: true
            }
        case HIDE_PAGE:
            return {
                ...state,
                [`${action.payload}Visible`]: false,
                pageVisible: false
            }
        case HIDE_ALL_PAGES:
            return {
                ...state,
                ...initialPages
            }
        case SHOW_POPUP:
            return {
                ...state,
                [`${action.payload}Visible`]: true,
                popupActive: true
            }
        case HIDE_POPUP:
            return {
                ...state,
                [`${action.payload}Visible`]: false,
                popupActive: false
            }
        case HIDE_ALL_POPUPS:
            if (state.completeRegisterPopupVisible) return state
            return {
                ...state,
                loginPopupVisible: false,
                popupActive: false,
                meetPopupVisible: false,
                completeRegisterPopupVisible: false,
                experiencePopupVisible: false,
                virtualGfPopupVisible: false,
                forgotPasswordPopupVisible: false,
                sxPopupVisible: false,
                sxLoggedOutPopupVisible: false
            }
        case GO_TO_START:
            return { ...initialState, startScreenVisible: true }
        case USER_SIGNUP_LOADING:
            return { ...state, userSignupLoading: true }
        case USER_REGISTERED:
            return { ...state, userSignupLoading: false }
        case STARTED_TYPING:
            return { ...state, typing: true }
        case FINISHED_TYPING:
            return { ...state, typing: false }

        case SHOW_MENU:
            return { ...state, menuVisible: true }
        case HIDE_MENU:
            return { ...state, menuVisible: false }

        case SHOW_LOGIN_POPUP:
            return { ...state, loginPopupVisible: true, popupActive: true }

        case SHOW_REGISTER_POPUP:
            return {
                ...state,
                ...startWithPopupState,
                completeRegisterPopupVisible: true
            }
        case SHOW_COMPLETE_RESET_PASSWORD_POPUP:
            return {
                ...state,
                ...startWithPopupState,
                completeCompleteResetPasswordPopupVisible: true
            }
        case HIDE_REGISTER_POPUP:
            return {
                ...state,
                completeRegisterPopupVisible: false,
                popupActive: false
            }
        case HIDE_COMPLETE_RESET_PASSWORD_POPUP:
            return {
                ...state,
                completeCompleteResetPasswordPopupVisible: false,
                popupActive: false
            }
        case SHOW_HAMBURGER:
            return {
                ...state,
                hamburgerVisible: true
            }
        case HIDE_HAMBURGER:
            return {
                ...state,
                hamburgerVisible: false
            }
        case SHOW_SPACE_LIGHTBOX:
            return {
                ...state,
                spaceLightboxVisible: true
            }
        case HIDE_SPACE_LIGHTBOX:
            return {
                ...state,
                spaceLightboxVisible: false
            }
        default:
            return state
    }
}
