import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _t from '../utils/translate'
import Settings from '../Settings'

import VoiceSelector from './fields/VoiceSelector'

class VoiceSelectionForm extends React.Component {
    state = {
        playing: ''
    }

    onChange = value => {
        const { changeData, changePage } = this.props
        changeData('voice', value)
        changePage('calendar', 3)
    }

    changePlaying = id => {
        // console.log({ playing: id })
        this.setState({ playing: id })
    }

    render() {
        const {
            onChange,
            changePlaying,
            state: { playing }
        } = this

        return (
            <div>
                <h2>Please choose a voice</h2>
                <VoiceSelector
                    id="voice_1"
                    playing={playing}
                    changePlaying={changePlaying}
                    label="Voice 1 (English)"
                    onChange={onChange}
                />
                {/*<VoiceSelector
                    id="voice_2"
                    playing={playing}
                    changePlaying={changePlaying}
                    label="Voice 2 (German)"
                    onChange={onChange}
                />*/}
                <p>German voice coming soon!</p>
            </div>
        )
    }
}

VoiceSelectionForm.propTypes = {
    changePage: PropTypes.func.isRequired,
    changeData: PropTypes.func.isRequired
}

const mapsStateToProps = state => ({
    meetPopupVisible: state.ui.meetPopupVisible
})

export default connect(mapsStateToProps, null)(VoiceSelectionForm)
