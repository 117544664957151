import React, { Suspense } from 'react'
import PropTypes from 'prop-types'
// import { Form, Button, Message } from 'semantic-ui-react'
import Validator from 'validator'
import { connect } from 'react-redux'
import ReactPixel from 'react-facebook-pixel'
import shortId from 'shortid'
import ReactGA from 'react-ga'
// import { Link } from 'react-router-dom'

import InlineError from '../messages/InlineError'
import { showMessage } from '../actions/uiActions'
import { fetchCalendar } from '../actions/calendarActions'
import _t from '../utils/translate'
import formatPrice from '../utils/formatPrice'

import ToggleButtons from './fields/ToggleButtons'
import PaypalCheckout from './fields/PaypalCheckout'
// const StripeCheckout = React.lazy(() => import('./fields/StripeCheckout'))
import StripeCheckout from './fields/StripeCheckout'

import Summary from './fields/Summary'
// import { LanguageLink } from '../utils/LanguageLink'

const initialFormData = {
    paymentMethod: 'paypal'
}

class PaymentForm extends React.Component {
    state = {
        data: initialFormData,
        loading: false,
        errors: {}
    }

    componentDidMount() {}

    componentDidUpdate(prevProps) {
        if (!prevProps.meetPopupVisible && this.props.meetPopupVisible) {
            this.setState({ data: initialFormData })
        }
    }

    onChange = e => {
        const { recalculateHeight } = this.props
        this.setState(
            {
                data: {
                    ...this.state.data,
                    [e.target.name]: e.target.value
                },
                errors: { ...this.state.errors, [e.target.name]: false }
            },
            () => recalculateHeight()
        )
    }

    onSubmit = e => {
        const { submit, showMessage } = this.props
        const { data } = this.state
        e.preventDefault()
        const errors = this.validate(data)
        this.setState({ errors })
        if (Object.keys(errors).length === 0) {
            // this.setState({ loading: true })
            submit(data)
        }
    }

    validate = data => {
        const errors = {}
        // if (!Validator.isEmail(data.username))
        //     errors.username = _t('Invalid email', 'account')
        if (!data.terms)
            errors.terms = _t(`You have to accept the terms.`, 'account')

        if (!data.selectedDate)
            errors.selectedDate = _t(`Please select a day.`, 'account')

        return errors
    }

    onSuccess = resData => {
        // console.log(resData)
        const {
            props: { showMessage, paymentSuccess },
            state: { data }
        } = this
        paymentSuccess({ paymentMethod: data.paymentMethod, ...resData })

        this.trackPurchase(data.paymentMethod)
    }

    sendOrderCash = () => {
        const { sendOrder, displayMessage } = this.props
        displayMessage('loading_generic')
        sendOrder({ paymentMethod: 'cash' })
        this.trackPurchase('cash')
    }

    trackPurchase = paymentMethod => {
        const {
            cart: { total }
        } = this.props
        ReactPixel.track(
            'Purchase',
            // begin parameter object data
            {
                value: total,
                currency: 'EUR',
                contents: [
                    {
                        id: '301',
                        quantity: 1
                    }
                ],
                content_type: 'product',
                payment_method: paymentMethod
            }
            // end parameter object data
        )
        ReactGA.plugin.execute('ecommerce', 'addTransaction', {
            id: shortId.generate(),
            revenue: total,
            currency: 'EUR'
        })
        ReactGA.plugin.execute('ecommerce', 'send')
    }

    render() {
        const {
            state: { data, errors, loading },
            props: {
                displayMessage,
                cart: { total },
                pageActive
            },
            onChange,
            onSuccess,
            sendOrderCash
        } = this
        // const errors = {}

        // console.log(this.state)
        // console.log(data.terms)

        return (
            <div
                className={`${loading === true ? 'loading' : ''}
                    `}
            >
                {errors.global && (
                    <div>
                        <div>{_t('Something went wrong', 'account')}</div>
                        <p>{errors.global}</p>
                    </div>
                )}
                <h2 className="align_center">{_t('Payment', 'account')}</h2>
                <Summary />
                <ToggleButtons
                    name="paymentMethod"
                    value={data.paymentMethod}
                    onChange={onChange}
                    values={[
                        { label: 'Paypal', value: 'paypal' },
                        { label: 'Credit Card', value: 'credit_card' }
                    ]}
                />
                {/*{ label: 'Cash', value: 'cash' },*/}
                <div className="payment_details">
                    {data.paymentMethod === 'credit_card' && pageActive && (
                        <Suspense>
                            <StripeCheckout />
                        </Suspense>
                    )}
                    {data.paymentMethod === 'paypal' && pageActive && (
                        <React.Fragment>
                            <p>
                                <b>Total: {formatPrice(total)}</b>
                            </p>
                            <p>
                                You'll receive an email with the address of my
                                flat and all the other relevant information.
                            </p>
                            <PaypalCheckout
                                price={total}
                                displayMessage={displayMessage}
                                onSuccess={onSuccess}
                            />
                        </React.Fragment>
                    )}
                    {/*data.paymentMethod === 'cash' && (
                        <div className="cash_descr">
                            <p>
                                <b>Total: {formatPrice(total)}</b>
                            </p>
                            <p>
                                You pay in cash once you arrive at my flat.
                                Please bring the exact amount. You'll receive a
                                printed out invoice there as well.
                            </p>
                            <p>
                                You'll receive an email with the address of my
                                flat and all the other relevant information.
                            </p>
                            <br />
                            <button
                                type="button"
                                className="primary full_width disabled"
                                style={{
                                    lineHeight: '48px',
                                    height: 'auto'
                                }}
                                onClick={sendOrderCash}
                            >
                                Reserve Appointment
                            </button>
                        </div>
                    )*/}
                </div>
            </div>
        )
    }
}

PaymentForm.propTypes = {
    // submit: PropTypes.func.isRequired
    pageActive: PropTypes.bool.isRequired,
    recalculateHeight: PropTypes.func.isRequired
}

export default connect(null, { showMessage, fetchCalendar })(PaymentForm)
