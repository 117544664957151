import { LOAD_PAGES } from '../actions/types'

const initialState = {
	loading: true
}

export default function(state = initialState, action) {
	switch (action.type) {
		case LOAD_PAGES:
			return { ...state, ...action.payload, loading: false }
		default:
			return state
	}
}
