import React from 'react'
import PropTypes from 'prop-types'
import ReactGA from 'react-ga'
// import { Form, Button, Message } from 'semantic-ui-react'
import Validator from 'validator'
import { connect } from 'react-redux'
// import { Link } from 'react-router-dom'
import { find as _find } from 'lodash-es'
import { addHours } from 'date-fns'

import InlineError from '../messages/InlineError'
import { showMessage, showPage } from '../actions/uiActions'
import { fetchCalendar } from '../actions/calendarActions'
import _t from '../utils/translate'
import calculateTimeSlots from '../utils/calculateTimeSlots'
import Settings from '../Settings'

import LenghtField from './fields/LenghtField'
import Calendar from './fields/Calendar'
import Checkbox from './fields/Checkbox'
import TimeSelect from './fields/TimeSelect'
import Summary from './fields/Summary'
// import { LanguageLink } from '../utils/LanguageLink'

const initialFormData = {
    price: null,
    priceTalk: null,
    length: 1,
    selectedDate: null,
    selectedTime: null,
    product: {},
    productTalk: {},
    productId: null,
    productIdTalk: null,
    lengthHours: 0
}

class MeetForm extends React.Component {
    constructor(props) {
        super(props)
        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.calcPrice = this.calcPrice.bind(this)
        this.showImprint = this.showImprint.bind(this)

        this.state = {
            data: initialFormData,
            loading: false,
            errors: {}
        }
    }

    componentDidMount() {
        const {
            calcPrice,
            props: { fetchCalendar }
        } = this
        // fetchCalendar()
        // .then(res => console.log(res))
        calcPrice()
    }

    calcPrice = () => {
        const {
            state: {
                data: { length }
            },
            props: { products, displayMessage },
            calcPrice
        } = this

        if (!products.loading && length) {
            let productSlug = ''
            let productSlugTalk = ''
            let lengthHours = ''
            if (typeof length === 'number') {
                productSlug = `kokeshi-${length}h`
                productSlugTalk = `kokeshi-${length}h-talk`
                lengthHours = length
            }
            if (typeof length === 'string') {
                productSlug = `kokeshi-${length}`
                productSlugTalk = `kokeshi-${length}-talk`
                if (length === 'night') lengthHours = 10
                if (length === 'day') lengthHours = 20
                if (length === 'test') lengthHours = 1
            }
            const selectedProduct = _find(products.products, {
                slug: productSlug
            })
            const selectedProductTalk = _find(products.products, {
                slug: productSlugTalk
            })
            if (!selectedProduct || !selectedProductTalk) {
                console.error(
                    `Tried to find product ${productSlug} and ${productSlugTalk}. Didn't work.`
                )
                displayMessage(
                    'There is a technical problem, please try again later',
                    'error'
                )
                this.setState({ data: initialFormData })
                return null
            }

            // console.log(lengthHours)
            // console.log(selectedProduct)
            const price = parseFloat(selectedProduct.price)
            const priceTalk = parseFloat(selectedProductTalk.price)

            this.setState(oldState => ({
                data: {
                    ...oldState.data,
                    price,
                    priceTalk,
                    productId: selectedProduct.id,
                    productIdTalk: selectedProductTalk.id,
                    lengthHours,

                    product: selectedProduct,
                    productTalk: selectedProductTalk
                }
            }))
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            calcPrice,
            props: { changeData }
        } = this
        if (!prevProps.meetPopupVisible && this.props.meetPopupVisible) {
            this.props
                .fetchCalendar()
                .then(res => {
                    calcPrice()
                    return res
                })
                .catch(err => {
                    alert(err.message)
                })
            this.setState({ data: initialFormData })
        }

        if (prevProps.calendar.loading && !this.props.calendar.loading) {
            calcPrice()
        }
        if (prevProps.products.loading && !this.props.products.loading) {
            calcPrice()
        }

        if (prevState.data.price !== this.state.data.price) {
            changeData('price', this.state.data.price)
        }

        if (prevState.data.priceTalk !== this.state.data.priceTalk) {
            changeData('priceTalk', this.state.data.priceTalk)
            changeData('actualPrice', this.state.data.priceTalk)
        }

        if (prevState.data.productId !== this.state.data.productId) {
            changeData('productId', this.state.data.productId)
        }

        if (prevState.data.productIdTalk !== this.state.data.productIdTalk) {
            changeData('productIdTalk', this.state.data.productIdTalk)
            changeData('actualProductId', this.state.data.productIdTalk)
        }
        if (prevState.data.timeStart !== this.state.data.timeStart) {
            changeData('timeStart', this.state.data.timeStart)
        }
        if (prevState.data.timeEnd !== this.state.data.timeEnd) {
            changeData('timeEnd', this.state.data.timeEnd)
        }
        if (prevState.data.length !== this.state.data.length) {
            changeData('length', this.state.data.length)
        }
        if (prevState.data.lengthHours !== this.state.data.lengthHours) {
            changeData('lengthHours', this.state.data.lengthHours)
        }
    }

    onChange = e => {
        // console.log(e)
        const { length, selectedDate, selectedTime } = this.state.data
        const { selectedCalendar } = this.props
        let override = {}
        if (e.target.name === 'selectedDate') {
            override.selectedTime = null
            override.timeStart = null
            override.timeEnd = null
        }

        if (e.target.name === 'selectedDate' && length === 'night') {
            override.timeStart = addHours(
                e.target.value,
                Settings.nightSlot.start
            )
            override.timeEnd = addHours(e.target.value, Settings.nightSlot.end)
        }
        if (e.target.name === 'selectedDate' && length === 'day') {
            override.timeStart = addHours(
                e.target.value,
                Settings.daySlot.start
            )
            override.timeEnd = addHours(e.target.value, Settings.daySlot.end)
        }
        if (e.target.name === 'selectedDate' && length === 'test') {
            override.timeStart = addHours(
                e.target.value,
                Settings.testSlot.start
            )
            override.timeEnd = addHours(e.target.value, Settings.testSlot.end)
        }

        // If the selected day is not available with the new length, deselect it
        if (
            e.target.name === 'length' &&
            selectedDate &&
            calculateTimeSlots(e.target.value, selectedDate, selectedCalendar)
                .nrSlots === 0
        ) {
            override.selectedDate = null
        }

        // If length is changed after time has been chosen, unselect the time
        if (e.target.name === 'length' && selectedTime) {
            override.selectedTime = null
        }

        // if (e.target.name === 'length')
        // overrideTime = { selectedTime: null, selectedDate: null }

        this.setState(
            {
                data: {
                    ...this.state.data,
                    [e.target.name]: e.target.value,
                    ...override
                },
                errors: { ...this.state.errors, [e.target.name]: false }
            },
            this.calcPrice
        )
    }

    onChangeTime = data => {
        const { length, selectedDate } = this.state.data
        const { selectedTime } = data

        // console.log(data)

        // let numericLength =  length;
        // if (length === 'night') numericLength = Settings.nightSlot.end - Settings.nightSlot.start
        // if (length === 'day') numericLength = Settings.daySlot.end - Settings.daySlot.start

        const timeStart = addHours(selectedDate, selectedTime)
        const timeEnd = addHours(selectedDate, selectedTime + length)

        this.setState(
            {
                data: {
                    ...this.state.data,
                    ...data,
                    timeStart,
                    timeEnd
                },
                errors: { ...this.state.errors, selectedTime: false }
            },
            this.calcPrice
        )
    }

    onSubmit = e => {
        const { changePage, showMessage } = this.props
        const { data } = this.state
        e.preventDefault()
        const errors = this.validate(data)
        this.setState({ errors })
        if (Object.keys(errors).length === 0) {
            // this.setState({ loading: true })
            // submit(data)
            changePage('extras', 4)
            //     .then(res => {
            //         // console.log(res)
            //         this.setState({ loading: false })
            //         return res
            //     })
            //     .catch(err => {
            //         // console.error(err.response.data.code)
            //         console.error(err)
            //         showMessage(
            //             err.response &&
            //                 err.response.data &&
            //                 err.response.data.message
            //                 ? _t(err.response.data.message)
            //                 : _t('There was a problem logging you in.'),
            //             'error'
            //         )
            //         this.setState({
            //             errors: { global: 'There is a problem.' } || {},
            //             loading: false
            //         })
            //     })
        }
    }

    showImprint() {
        ReactGA.pageview('imprint')
        const { showPage } = this.props
        showPage('imprint')
    }

    validate = data => {
        // const {}
        const errors = {}

        if (!data.selectedDate)
            errors.selectedDate = _t(`Please select a day.`, 'account')
        if (typeof data.length === 'number' && !data.selectedTime)
            errors.selectedTime = _t(
                `Please select a Check-in time.`,
                'account'
            )

        return errors
    }

    render() {
        const {
            state: { data, errors, loading },
            props: { calendar, selectedCalendar, voiceNoVoice },
            onChange,
            onChangeTime,
            showImprint
        } = this
        // const errors = {}

        // console.log(this.state)
        // console.log(data.terms)
        const price = voiceNoVoice === 'no_voice' ? data.price : data.priceTalk

        return (
            <form onSubmit={this.onSubmit}>
                {errors.global && (
                    <div>
                        <div>{_t('Something went wrong', 'account')}</div>
                        <p>{errors.global}</p>
                    </div>
                )}
                <h2 className="align_center">
                    {_t('Please choose a time', 'account')}
                </h2>
                <p className="align_left">
                    To meet me, please select your date and time. <br />
                </p>
                <p className="align_left">
                    <b>
                        My flat is located near Kollwitzplatz in Prenzlauer
                        Berg, Berlin.
                    </b>
                </p>
                <LenghtField
                    onChange={onChange}
                    value={data.length}
                    label="Length of your visit:"
                    price={price}
                />
                <Calendar
                    value={data.selectedDate}
                    onChange={onChange}
                    error={errors.selectedDate}
                    calendar={calendar}
                    selectedCalendar={selectedCalendar}
                    length={data.length}
                />
                <TimeSelect
                    length={data.length}
                    selectedDate={data.selectedDate}
                    onChange={onChangeTime}
                    error={errors.selectedTime}
                    selectedTime={data.selectedTime}
                    name="selectedTime"
                    label="Choose your check-in time:"
                    calendar={calendar}
                    selectedCalendar={selectedCalendar}
                />
                <button
                    className="primary full_width meet_continue"
                    type="submit"
                >
                    {_t('Continue', 'account')}
                </button>
            </form>
        )
    }
}

MeetForm.propTypes = {
    changePage: PropTypes.func.isRequired,
    selectedCalendar: PropTypes.string.isRequired,
    voiceNoVoice: PropTypes.string.isRequired
}

const mapsStateToProps = state => ({
    calendar: state.calendar,
    meetPopupVisible: state.ui.meetPopupVisible,
    products: state.products
})

export default connect(mapsStateToProps, {
    showMessage,
    fetchCalendar,
    showPage
})(MeetForm)
