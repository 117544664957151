import React from 'react'
import { connect } from 'react-redux'
import { hideAllPopups } from '../actions/uiActions'

class BlurWrapper extends React.Component {
    constructor(props) {
        super(props)
        this.closePopup = this.closePopup.bind(this)
    }

    closePopup() {
        const { hideAllPopups } = this.props
        hideAllPopups()
        console.log('close')
    }

    render() {
        const {
            children,
            popupActive,
            completeRegisterPopupVisible,
            completeCompleteResetPasswordPopupVisible
        } = this.props
        const { closePopup } = this
        return (
            <div className={`blur ${popupActive ? 'active' : ''}`}>
                {children}
                <div
                    className={`mouse_blocker ${
                        completeRegisterPopupVisible ||
                        completeCompleteResetPasswordPopupVisible
                            ? 'no_click'
                            : ''
                    }`}
                    onClick={closePopup}
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    popupActive: state.ui.popupActive,
    completeRegisterPopupVisible: state.ui.completeRegisterPopupVisible,
    completeCompleteResetPasswordPopupVisible:
        state.ui.completeCompleteResetPasswordPopupVisible
})
export default connect(mapStateToProps, { hideAllPopups })(BlurWrapper)
