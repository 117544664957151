import shortid from 'shortid'
import ReactGA from 'react-ga'

import api from '../api'
import setAuthorizationHeader from '../utils/setAuthorizationHeader'
import { store } from '../store/store'

import {
    USER_REGISTERED,
    SESSION_ID,
    ADD_USER_DATA_STORAGE,
    USER_LOGGED_IN,
    USER_LOGGED_OUT,
    CONSOLIDATE_USER_DATA
} from './types'

export const addUserDataStorage = data => dispatch => {
    dispatch({ type: ADD_USER_DATA_STORAGE, payload: data })
}

export const registerUser = data => {
    ReactGA.pageview('user_registered')
    console.log(`signup: ${JSON.stringify(data)}`)
    return api.user
        .register(data)
        .then(res => {
            console.log(res)
            return res
        })
        .then(res => {
            store.dispatch({
                type: USER_REGISTERED,
                payload: res
            })
            return res
        })
}

export const completeRegisterUser = data => dispatch => {
    return api.user.completeRegister(data).then(res => {
        ReactGA.pageview('user_completed_registeration')
        // console.log(res)
        return res
    })
}

export const completeResetPassword = data => dispatch =>
    api.user.completeResetPassword(data)

export const addPhone = data => {
    console.log('addPhone Action triggered')
    return api.user
        .addPhone(data)
        .then(res => {
            return res
        })
        .then(res => {
            store.dispatch({ type: 'PHONE_ADDED_TO_SERVER' })
            ReactGA.pageview('user_added_phone_nr')
        })
        .catch(err => {
            console.error('Error adding the Phone to the server:')
            console.error(JSON.stringify(err))
        })
}

export const resetPassword = data => {
    console.log('resetPassword Action triggered')
    return api.user.resetPassword(data)
}

export const consolidateUser = () => dispatch => {
    dispatch({ type: CONSOLIDATE_USER_DATA })
}

export const sendOrder = data => dispatch => {
    console.log('sendOrder Action')
    console.log(data)
    return api.user.order(data).then(res => {
        return res
    })
}

export const sendOrderSx = data => dispatch => {
    console.log('sendOrderSx Action')
    console.log(data)
    return api.user.orderSx(data).then(res => {
        return res
    })
}

export const sendOrderGf = data => dispatch => {
    console.log('sendOrderGf Action')
    console.log(data)
    return api.user.orderGf(data).then(res => {
        // console.log('res')
        // console.log(res)
        console.log('res.data.data')
        console.log(res.data.data)
        dispatch({
            type: ADD_USER_DATA_STORAGE,
            payload: { gfEndDate: res.data.data.new_end_date }
        })
        return res
    })
}

const userLoggedIn = user => ({
    type: USER_LOGGED_IN,
    payload: user
})

export const checkLogin = () => dispatch =>
    api.user
        .checkLogin()
        .then(res => {
            // console.log('login is fine')
            ReactGA.pageview('logged_in')
            return res
        })
        .catch(() => {
            dispatch({ type: USER_LOGGED_OUT })
            // console.log('login is expired')
        })

export const login = credentials => dispatch => {
    // console.log(credentials)
    return api.user.login(credentials).then(user => {
        // console.log(user.data)
        // localStorage.snJWT = user.data.token
        setAuthorizationHeader(user.data.token)
        dispatch(userLoggedIn(user.data))
        // loadUserData()
        return user
    })
}

export const logout = () => dispatch => {
    dispatch({ type: USER_LOGGED_OUT })
}
