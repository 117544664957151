import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { find as _find, sortBy as _sortBy } from 'lodash-es'

import ExtrasCheckbox from './ExtrasCheckbox'

class ExtrasDisplay extends React.Component {
	constructor(props) {
		super(props)
		this.state = { products: [] }
	}

	static getDerivedStateFromProps(nextProps, lastState) {
		const {
			products: { loading, products }
		} = nextProps

		if (!loading) {
			const filteredProducts = products.filter(element => {
				return _find(element.categories, cat => cat.slug === 'extras')
				// return element.name === 'pizza'
			})
			const sortedProducts = _sortBy(filteredProducts, [
				o => o.acf.sorting_index,
				'id'
			])
			// console.log(sortedProducts)
			return { products: sortedProducts }
		}
		return null
	}

	render() {
		const {
			props: {
				products: { loading },
				changeExtras,
				data,
				extras,
				displayMessage,
				lengthHours
			},
			state: { products }
		} = this

		// console.log(data.extras)
		// console.log(
		// 	`Found: ${_find(data.extras, a => a === 'prosecco') !== undefined}`
		// )
		// console.log(_find(data.extras, a => a === 'prosecco'))
		if (loading)
			return (
				<div className="extras_display">
					Extras Display
					<br />
					<p>loading...</p>
				</div>
			)
		return (
			<div className="extras_display">
				{products.map(product => (
					<div className="product" key={`product_id_${product.id}`}>
						<ExtrasCheckbox
							displayMessage={displayMessage}
							productName={product.name}
							hourly={product?.acf?.hourly}
							price={product.price}
							x={null /*callBack={}*/}
							name={product.slug}
							productId={product.id}
							checked={
								_find(extras, a => a === product.id) !==
								undefined
							}
							lengthHours={lengthHours}
							changeExtras={changeExtras}
							error={false}
							className="field_extras"
						/>
						{/*checked={data[product.slug]}*/}
					</div>
				))}
			</div>
		)
	}
}

ExtrasDisplay.propTypes = {
	products: PropTypes.shape({
		loading: PropTypes.bool.isRequired
	}).isRequired,
	changeExtras: PropTypes.func.isRequired,
	extras: PropTypes.array.isRequired,
	lengthHours: PropTypes.number.isRequired
}

const mapStateToProps = state => ({
	products: state.products
})

export default connect(mapStateToProps)(ExtrasDisplay)
