import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as dateFns from 'date-fns'

import calculateTimeSlots from '../../utils/calculateTimeSlots'

const DEBUG = false

class Calendar extends React.Component {
	static propTypes = {
		// name: React.PropTypes.string,
	}

	constructor(props) {
		super(props)
		this.state = {
			currentMonth: new Date(),
			selectedDate: new Date(),
			monthBackButtonActive: false
		}
		// console.log(this.state.possibleTimeSlots)
	}

	componentDidUpdate(prevProps) {
		if (prevProps.meetPopupVisible && !this.props.meetPopupVisible)
			setTimeout(
				() =>
					this.setState({
						currentMonth: new Date(),
						monthBackButtonActive: false
					}),
				500
			)
	}

	renderHeader() {
		const dateFormat = 'MMMM yyyy'
		const { monthBackButtonActive } = this.state

		return (
			<div className="header row flex-middle">
				<div className="col col-start">
					<button
						type="button"
						onClick={this.prevMonth}
						className={`last_month round ${
							monthBackButtonActive ? '' : 'disabled'
						}`}
					>
						{'<'}
					</button>
				</div>
				<div className="col col-center">
					<span>
						{dateFns.format(this.state.currentMonth, dateFormat)}
					</span>
				</div>
				<div className="col col-end">
					<button
						type="button"
						onClick={this.nextMonth}
						className="next_month round"
					>
						{'>'}
					</button>
				</div>
			</div>
		)
	}

	renderDays() {
		const dateFormat = 'EE'
		const days = []

		let startDate = dateFns.startOfWeek(this.state.currentMonth)

		for (let i = 1; i < 8; i++) {
			days.push(
				<div className="col col-center" key={i}>
					{dateFns.format(dateFns.addDays(startDate, i), dateFormat)}
				</div>
			)
		}

		return <div className="days row">{days}</div>
	}

	renderCells() {
		const { currentMonth } = this.state
		const { value, length, selectedCalendar } = this.props
		const monthStart = dateFns.startOfMonth(currentMonth)
		const monthEnd = dateFns.endOfMonth(monthStart)
		const startDate = dateFns.startOfWeek(monthStart, { weekStartsOn: 1 })
		const endDate = dateFns.endOfWeek(monthEnd, { weekStartsOn: 1 })

		// console.log(monthStart)

		const dateFormat = 'd'
		const rows = []

		let days = []
		// let day = dateFns.startOfWeek(dateFns.subDays(startDate, 1))
		let day = startDate
		// day = dateFns.addDays(day, 1)
		let formattedDate = ''

		while (day <= endDate) {
			for (let i = 1; i < 8; i++) {
				formattedDate = dateFns.format(day, dateFormat)
				const cloneDay = day
				const isBeforeToday =
					dateFns.format(cloneDay, 'yyyyMMdd') <
					dateFns.format(new Date(), 'yyyyMMdd')
				// console.log('length')
				// console.log(length)
				// console.log('cloneDay')
				// console.log(cloneDay)
				let isFree = true
				if (!isBeforeToday)
					isFree =
						calculateTimeSlots(length, cloneDay, selectedCalendar)
							.nrSlots > 0
				// isFree = true

				// console.log(isFree)
				// debugger
				days.push(
					<div
						className={`col cell ${
							!dateFns.isSameMonth(day, monthStart)
								? 'disabled'
								: dateFns.isSameDay(day, value)
								? 'selected'
								: isBeforeToday
								? 'blocked'
								: isFree
								? ''
								: 'not_free'
						} ${day}`}
						key={day}
						onClick={
							() =>
								// this.onDateClick(dateFns.parse(cloneDay))
								// this.onDateClick(dateFns.parse(cloneDay))
								this.onDateClick(cloneDay)
							// console.log(cloneDay)
						}
					>
						<span className="number">{formattedDate}</span>
					</div>
				)
				day = dateFns.addDays(day, 1)
			}
			rows.push(
				<div className="row" key={day}>
					{days}
				</div>
			)
			days = []
		}
		return <div className="body">{rows}</div>
	}

	onDateClick = day => {
		const { onChange } = this.props
		onChange({ target: { name: 'selectedDate', value: day } })
		// this.setState({
		// 	selectedDate: day
		// })
	}

	nextMonth = () => {
		this.setState({
			currentMonth: dateFns.addMonths(this.state.currentMonth, 1),
			monthBackButtonActive: true
		})
	}

	prevMonth = () => {
		if (
			dateFns.format(
				dateFns.subMonths(this.state.currentMonth, 1),
				'yyyyMM'
			) >= dateFns.format(new Date(), 'yyyyMM')
		) {
			const monthBackButtonActive =
				dateFns.format(
					dateFns.subMonths(this.state.currentMonth, 2),
					'yyyyMM'
				) >= dateFns.format(new Date(), 'yyyyMM')

			this.setState({
				currentMonth: dateFns.subMonths(this.state.currentMonth, 1),
				monthBackButtonActive
			})
		}
	}

	render() {
		const { error, selectedCalendar } = this.props
		return (
			<div className="calendar">
				<span className="error_msg">{error}</span>
				{this.renderHeader()}
				{this.renderDays()}
				{this.renderCells()}
				{/*<p>{console.log(this.props.calendar)}</p>*/}
				{DEBUG && <p>selected calendar: {selectedCalendar}</p>}
			</div>
		)
	}
	// render() {
	// 	return <div className="calendar">CALENDAR</div>
	// }
}

Calendar.propTypes = {
	selectedCalendar: PropTypes.string.isRequired,
	meetPopupVisible: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
	meetPopupVisible: state.ui.meetPopupVisible
})

export default connect(mapStateToProps)(Calendar)
