import React from 'react'
import { connect } from 'react-redux'
import { find as _find } from 'lodash-es'
import { format, parseISO, differenceInCalendarDays } from 'date-fns'

import { login, sendOrderGf } from '../../actions/userActions'
import {
    hidePopup,
    showPopup,
    hideAllPopups,
    showHamburger,
    hideHamburger
} from '../../actions/uiActions'

import BookVirtualGirlfriendForm from '../../forms/BookVirtualGirlfriendForm'
import _t from '../../utils/translate'

const initialFormData = { quantity: 1, paymentMethod: 'paypal', price: 0 }

class VirtualGfPopup extends React.Component {
    static propTypes = {}

    constructor(props) {
        super(props)
        this.displayMessage = this.displayMessage.bind(this)
        this.findProduct = this.findProduct.bind(this)
        this.sendOrderHandler = this.sendOrderHandler.bind(this)
        // this.hideError = this.hideError.bind(this)

        this.state = {
            nrSlides: 3,
            activeSlide: 1,
            data: initialFormData
        }
    }

    componentDidMount = () => {
        if (!this.props.products.loading) this.findProduct()
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.products.loading && !this.props.products.loading)
            this.findProduct()

        if (
            prevProps.virtualGfPopupVisible &&
            !this.props.virtualGfPopupVisible
        )
            this.setState({ activeSlide: 1 })

        if (prevState.message === null && this.state.message !== null) {
            hideHamburger()
        }
        if (prevState.message !== null && this.state.message === null) {
            showHamburger()
        }
        if (prevState.error === null && this.state.error !== null) {
            hideHamburger()
        }
        if (prevState.error !== null && this.state.error === null) {
            showHamburger()
        }
    }

    changeData = (dataKey, dataValue) => {
        // // console.log(dataKey)
        // // console.log(dataValue)
        this.setState(oldState => ({
            data: { ...oldState.data, [dataKey]: dataValue }
        }))
    }

    findProduct = () => {
        const { products } = this.props
        const productSlug = 'kokeshi-virtual-girlfriend-1-week'
        const selectedProduct = _find(products.products, {
            slug: productSlug
        })

        if (!selectedProduct) {
            console.error(`Tried to find product ${productSlug}. Didn't work.`)
            // displayMessage(
            //     'There is a technical problem, please try again later',
            //     'error'
            // )
            // this.setState({ data: initialFormData })
            return null
        }

        const price = selectedProduct.price

        this.setState(oldState => ({
            data: {
                ...oldState.data,
                price: price * 1,
                productId: selectedProduct.id,
                product: selectedProduct
            }
        }))
    }

    finishBooking = () => {
        const { hideAllPopups } = this.props
        hideAllPopups()
        setTimeout(() => this.setState({ message: null, activeSlide: 1 }), 2000)
    }

    goToSlide = slide => this.setState({ activeSlide: slide })

    hideError = () => this.setState({ message: null, error: false })

    paymentSuccess = data => {
        // console.log('paymentSuccess')
        return this.sendOrderHandler(data)
    }

    sendOrderHandler = data => {
        // console.log('sendOrder')
        const {
            state: {
                data: { paymentMethod, productId, quantity }
            },
            props: { sendOrderGf },
            displayMessage
        } = this

        if (paymentMethod === 'paypal') {
            // console.log('Payment from Paypal successfully registered.')
        }
        if (paymentMethod === 'cash') {
            // console.log('Order paid with cash successfully registered.')
        }
        // const duration = typeof length === 'number' ? `${length}h` : length

        const formData = {
            ...data,
            cart: { items: [{ id: productId, quantity: quantity }] },
            payment_method: paymentMethod
        }
        // console.log(formData)
        sendOrderGf(formData)
            .then(res => {
                // console.log(res)
                if (paymentMethod === 'paypal') displayMessage('order_success')
                if (paymentMethod === 'cash')
                    displayMessage('reservation_success')
            })
            .catch(err => {
                displayMessage(
                    err.response &&
                        err.response.data &&
                        err.response.data.message
                        ? _t(err.response.data.message)
                        : _t('There was a problem. Please try again later.'),
                    'error'
                )
                console.error('error creating the order:')
                console.error(err.response)
            })
        // // console.log(formData)
    }

    displayMessage = (message, type) => {
        // // console.log(message)
        this.setState({ message, error: type === 'error' })
    }

    render() {
        const {
            props: { virtualGfPopupVisible, gfEndDate },
            state: {
                nrSlides,
                activeSlide,
                message,
                error,
                data: { quantity, paymentMethod, price }
            },
            goToSlide,
            displayMessage,
            changeData,
            finishBooking,
            hideError,
            paymentSuccess
        } = this

        let virtualGfDaysLeft = 0

        // console.log('gfEndDate')
        // console.log(gfEndDate)
        const gfEndDateActual = parseISO(gfEndDate)
        // console.log('gfEndDateActual')
        // console.log(gfEndDateActual)
        const today = new Date()
        // console.log('today')
        // console.log(today)
        if (gfEndDateActual > today)
            virtualGfDaysLeft = differenceInCalendarDays(gfEndDateActual, today)
        // console.log('virtualGfDaysLeft')
        // console.log(virtualGfDaysLeft)

        return (
            <div
                className={`popup slider meet_popup ${
                    virtualGfPopupVisible ? 'active' : ''
                }`}
            >
                <div className="slider_wrapper">
                    <div
                        className={`slider`}
                        style={{
                            transform: `translate3d(${
                                ((activeSlide - 1) * -100) / nrSlides
                            }%, 0, 0)`,
                            width: `${nrSlides * 100}%`
                        }}
                    >
                        <div
                            className="content_wrapper_slider"
                            style={{ width: `${100 / nrSlides}%` }}
                        >
                            <div className="content content_1">
                                <h2>Virtual Girlfriend</h2>
                                <p>
                                    Are you lonely? I've evolved to meet human
                                    needs in this time of self-isolation.
                                </p>
                                <p>
                                    My experience package will provide the
                                    attention you crave. For 50€ a week, we can
                                    go on a sexy seductive journey together.
                                    This includes:
                                </p>
                                <ul className="no_dots">
                                    <li>introductory 15 minute phone call</li>
                                    <li>daily text chat for 10 minutes</li>
                                    <li>a sexy video</li>
                                    <li>three personalized pictures</li>
                                    <li>one more 20 minute phone call</li>
                                    <li>
                                        after four weeks of being your virtual
                                        girlfriend, you get 50% off a blindfold
                                        or full session with me
                                    </li>
                                </ul>
                                <p>
                                    I can listen, give you advice...or maybe you
                                    just want some fun. My mission is to provide
                                    a shame free environment.
                                </p>
                                <p>
                                    If you have any questions, just send me an
                                    email at hi@kokeshi.ai
                                </p>
                                <p> xoxoxo, Kokeshi</p>
                                <br />
                                <p>
                                    <b>
                                        Your Virtual Girlfriend is:{' '}
                                        {virtualGfDaysLeft > 0
                                            ? 'active'
                                            : 'not active'}
                                        .
                                    </b>
                                </p>
                                {virtualGfDaysLeft > 0 && (
                                    <p>
                                        <b>
                                            There are {virtualGfDaysLeft} days
                                            left.
                                        </b>
                                    </p>
                                )}
                                {
                                    <button
                                        type="button"
                                        onClick={() => {
                                            goToSlide(2)
                                        }}
                                    >
                                        Book now
                                    </button>
                                }
                            </div>
                        </div>
                        <div
                            className="content_wrapper_slider"
                            style={{ width: `${100 / nrSlides}%` }}
                        >
                            <div className="content content_2">
                                <BookVirtualGirlfriendForm
                                    quantity={quantity}
                                    onChange={changeData}
                                    paymentMethod={paymentMethod}
                                    paymentSuccess={paymentSuccess}
                                    formVisible={activeSlide === 2}
                                    price={price}
                                    displayMessage={displayMessage}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className={`loading ${
                        message === 'loading_generic' ? 'active' : ''
                    }`}
                >
                    <div className="content">
                        <span>{_t('Loading...')}</span>
                    </div>
                </div>
                <div
                    className={`message ${
                        message === 'order_success' ? 'active' : ''
                    }`}
                >
                    <span>
                        <div className="content">
                            <span>
                                Yes! We now have <b>{virtualGfDaysLeft}</b> more
                                days to go deeper.
                            </span>
                            <span>
                                Check your email, I’ve sent you something.
                            </span>
                            <button
                                onClick={finishBooking}
                                className="error_ok"
                            >
                                {_t('OK', 'error')}
                            </button>
                        </div>
                    </span>
                </div>

                <div
                    className={`message ${
                        message === 'payment_success' ? 'active' : ''
                    }`}
                >
                    <span>
                        <div className="content">
                            <span>{`almost there...`}</span>
                        </div>
                    </span>
                </div>

                <div
                    className={`message ${
                        message === 'payment_failure' ? 'active' : ''
                    }`}
                >
                    <span>
                        <div className="content">
                            <span>
                                {_t(
                                    'There was a problem with your payment, please try again.'
                                )}
                            </span>
                            <button onClick={hideError} className="error_ok">
                                {_t('OK', 'error')}
                            </button>
                        </div>
                    </span>
                </div>

                <div className={`error ${error ? 'active' : ''}`}>
                    <span>
                        <div className="content">
                            <span>{_t(message)}</span>
                            <button onClick={hideError} className="error_ok">
                                {_t('OK', 'error')}
                            </button>
                        </div>
                    </span>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    virtualGfPopupVisible: state.ui.virtualGfPopupVisible,
    calendar: state.calendar,
    products: state.products,
    gfEndDate: state.user.gfEndDate
})

export default connect(mapStateToProps, {
    hidePopup,
    showPopup,
    sendOrderGf,
    hideAllPopups,
    showHamburger,
    hideHamburger
})(VirtualGfPopup)
