import React from 'react'
import { Link } from 'react-router-dom'

export default class Checkbox extends React.Component {
	static propTypes = {
		// name: React.PropTypes.string,
	}

	constructor(props) {
		super(props)
		this.onClick = this.onClick.bind(this)
		this.onChange = this.onChange.bind(this)
	}

	onChange = e => {
		const { onChange: _onChange } = this.props

		const target = e.target
		const value = target.checked
		const name = target.name

		// console.log(target)
		// console.log(value)

		_onChange({ target: { name: name, value: value } })
	}

	onClick = e => {
		e.preventDefault()
		this.props.callBack()
	}

	render() {
		const {
			props: {
				error,
				checked,
				label,
				name,
				required,
				link,
				linkLabel,
				linkInternal,
				className,
				callBack
			},
			onChange,
			onClick
		} = this
		// console.log(this.props)
		return (
			<div
				className={`field checkbox ${
					error ? 'error' : ''
				} ${className}`}
			>
				<span className="error_msg">{error}</span>
				<input
					type="checkbox"
					id={name}
					name={name}
					value={`${name}_checked`}
					checked={checked}
					onChange={onChange}
				/>
				<label htmlFor={name}>
					{label}
					{callBack && linkLabel && (
						<span className="link" onClick={onClick}>
							{linkLabel}
						</span>
					)}
					{!callBack && link && linkLabel && linkInternal && (
						<Link
							className="link"
							target="_blank"
							rel="noopener noreferrer"
							to={link}
						>
							{linkLabel}
						</Link>
					)}
					{!callBack && link && linkLabel && !linkInternal && (
						<a
							className="link"
							target="_blank"
							rel="noopener noreferrer"
							href={link}
						>
							{linkLabel}
						</a>
					)}
					{required && <span> *</span>}
				</label>
			</div>
		)
	}
}

Checkbox.defaultProps = {
	error: false,
	data: false,
	label: 'Label missing!',
	required: false
}
