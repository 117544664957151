const Settings = {
	devMode: false,
	offlineDevMode: false,
	safeMode: false,
	currency: '€',
	instagramUrl: 'https://www.instagram.com/kokeshiberlin/',
	messages: {
		registerConfirm: "Ok, great. I'll ask you some questions in the chat."
	},
	timeSlots: [
		10,
		11,
		12,
		13,
		14,
		15,
		16,
		17,
		18,
		19,
		20,
		21,
		'night',
		'day',
		'test'
	],
	// using more than 24h to push the end to next day
	daySlot: { start: 14, end: 33 },
	nightSlot: { start: 23, end: 33 },
	testSlot: { start: 10, end: 11 },
	dollName: 'kokeshi',
	primaryColor: '#00557d',
	nrSlides: 13,
	chatStartDelay: 62000,
	paypalLive: true,
	payPalClientIdSandbox:
		'AUnHe9Qp8jm2NpLCT41QyTBtVQKn0Rjh_XKaujq-LsLgRFfes-xcr1S0BXG8O-HNMMamyLc_6_NH-Zu6',
	payPalClientId:
		'AW2_5CuafmG0dAEUbE2driysPaSKPmZOYFNTzhd7pnNlasy6OoBzGMtt2ruxXAaBLdw_af-CyJi3lwHC'
}

export default Settings
