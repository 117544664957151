import shortid from 'shortid'
import { mapKeys as _mapKeys } from 'lodash-es'
import ReactGA from 'react-ga'
// import { dispatch as dispatchX } from 'react-redux'

import processResponse from '../utils/processResponse'
import { addUserDataStorage, resetPassword } from './userActions'

import {
	ADD_CHAT_USER,
	ADD_CHAT_KOKESHI,
	SESSION_ID,
	WAITING_FOR_ANSWER,
	HIDE_CHAT_BACKGROUND,
	SHOW_MAIN_SCREEN,
	ADD_USER_DATA_STORAGE,
	FORCE_CHAT_START
} from './types'

import { registerUser, addPhone } from './userActions'

import { hideChatBackground, showMainScreen } from './uiActions'

import api from '../api'
import { store } from '../store/store'

export const sendMessage = message => dispatch => {
	if (store.getState().chat.chatMessages.length === 1) {
		// console.log('First message sent, moving to next screen.')
		ReactGA.pageview('main_screen')
		dispatch({
			type: HIDE_CHAT_BACKGROUND
		})
		dispatch({
			type: SHOW_MAIN_SCREEN
		})
	}
	dispatch({
		type: ADD_CHAT_USER,
		payload: message
	})
}

export const startConversation = eventName => dispatch => {
	let dialogflowEvent = 'start_conversation'
	if (
		store.getState().user.firstName !== '' &&
		store.getState().user.firstName !== 'Human'
	)
		dialogflowEvent = 'start_conversation_user'
	return api.chat
		.sendDialogflowEvent(dialogflowEvent, {
			name: store.getState().user.firstName
		})
		.then(res => {
			dispatch({
				type: ADD_CHAT_KOKESHI,
				payload: {
					message: res.data.result.fulfillment.speech,
					contexts: res.data.result.contexts
				}
			})
			return res
		})
}

export const forceChatStart = () => dispatch => {
	dispatch({
		type: FORCE_CHAT_START
	})
}

export const sendEvent = (
	eventName,
	repeatQuestion = true,
	eventArguments = {},
	timeout = 500
) => dispatch => {
	dispatch({
		type: WAITING_FOR_ANSWER
	})
	return api.chat
		.sendDialogflowEvent(eventName, eventArguments)
		.then(res => {
			// const timeout = 500
			// console.log(res.data.result.fulfillment.speech.length)

			window.setTimeout(
				() =>
					dispatch({
						type: ADD_CHAT_KOKESHI,
						payload: {
							message: res.data.result.fulfillment.speech,
							contexts: res.data.result.contexts
						}
					}),
				timeout
			)

			return res
		})
		.then(() => {
			if (repeatQuestion) {
				api.chat.sendDialogflowEvent('repeat_question').then(res => {
					const timeout =
						1000 + res.data.result.fulfillment.speech.length * 20
					// console.log(res.data.result.fulfillment.speech.length)

					window.setTimeout(
						() =>
							dispatch({
								type: ADD_CHAT_KOKESHI,
								payload: {
									message: res.data.result.fulfillment.speech,
									contexts: res.data.result.contexts
								}
							}),
						timeout
					)

					return res
				})
			}
		})
}

export const getAnswer = message => dispatch => {
	const oldContext = store.getState().chat.context
	// console.log(`Context: ${oldContext}`)
	dispatch({
		type: WAITING_FOR_ANSWER
	})
	return api.chat
		.getDialogflow(message, oldContext)
		.then(res => {
			console.log(res.data.result.fulfillment.speech)
			console.log(res.data)
			return res
		})
		.then(res => {
			// Add Userdata to State
			const parameters = _mapKeys(res.data.result.parameters, function (
				v,
				k
			) {
				return k.toLowerCase()
			})

			const userDataFromIntent = processResponse(
				res.data,
				store.getState().chat.lastMessageUser
			)

			console.log('parameters:')
			console.log(parameters)
			if ('phone-number' in parameters) {
				console.log('phone NR found')
				addPhone({ phoneNr: parameters['phone-number'] })
			}

			dispatch({
				type: ADD_USER_DATA_STORAGE,
				payload: { ...parameters, ...userDataFromIntent }
			})
			// console.log(parameters)

			// If the intent is '12 - confirm email Yes' sign user up

			if (
				res.data.result.metadata.intentName === '12 - confirm email Yes'
			) {
				console.log('Sign user up!')
				const userData = store.getState().user

				const chatHistory = store.getState().chat.chatMessages
				registerUser({
					...userData,
					chatHistory: chatHistory
						.map(message => `${message.type}: ${message.message}`)
						.join('\r\n')
				})
			}

			// If the intent is 'B02 - send email' send reset password email

			if (res.data.result.metadata.intentName === 'B02 - send email') {
				const userData = store.getState().user
				console.log(`Reset Password for: ${userData.email}`)
				// store.dispatch(
				resetPassword({ email_reset_password: userData.email })
					.then(res => console.log(`Password successfully reset.`))
					.catch(err => {
						console.error(
							'there was an error resetting the password'
						)
					})
				// )
				// 	const chatHistory = store.getState().chat.chatMessages
				// 	registerUser({
				// 		...userData,
				// 		chatHistory: chatHistory
				// 			.map(message => `${message.type}: ${message.message}`)
				// 			.join('\r\n')
				// 	})
			}

			// Add answer to the Chat
			const timeout = 500 + res.data.result.fulfillment.speech.length * 20
			// console.log(res.data.result.fulfillment.speech.length)

			window.setTimeout(
				() =>
					dispatch({
						type: ADD_CHAT_KOKESHI,
						payload: {
							message: res.data.result.fulfillment.speech,
							contexts: res.data.result.contexts
						}
					}),
				timeout
			)

			return res
		})
}

export const createUUID = () => dispatch => {
	dispatch({
		type: SESSION_ID,
		payload: shortid.generate()
	})
}
