import { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import ReactGA from 'react-ga'

const TryToLogPage = userId => {
	// ReactGA.set({ userId })
	console.log(userId)
	if (document.title !== 'loading...') {
		ReactGA.pageview(window.location.pathname)
		console.log(
			'Page Load: ' + window.location.pathname + '\n' + document.title
		)
	} else {
		console.log('waiting...')
		window.setTimeout(TryToLogPage, 500)
	}
}

class PageLoad extends Component {
	componentDidMount() {
		// window.setTimeout(TryToLogPage, 500)
	}

	componentDidUpdate(prevProps) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			window.scrollTo(0, 0)
			window.setTimeout(() => TryToLogPage(this.props.login), 500)
		}
	}

	render() {
		return this.props.children
	}
}

const mapStateToProps = state => ({
	login: state.user.login
})

export default withRouter(connect(mapStateToProps)(PageLoad))
