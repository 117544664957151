import React from 'react'
import { connect } from 'react-redux'
// import Typing from 'react-typing-animation'

import myid from '../../myid'
import Type from '../../Type'

import {
	sendMessage,
	getAnswer,
	sendEvent,
	startConversation
} from '../../actions/chatActions'

const DEBUG = false

class ChatWindow extends React.Component {
	state = {
		chatStart: false,
		data: { message: '' },
		scrolling: false,
		waitingForAnswer: false
	}

	constructor(props) {
		super(props)
		this.hide = this.hide.bind(this)
		this.sendMessage = this.sendMessage.bind(this)
		this.onChange = this.onChange.bind(this)
		this.componentDidUpdate = this.componentDidUpdate.bind(this)
		this.scrollToBottom = this.scrollToBottom.bind(this)
	}

	componentDidMount() {
		this.scrollToBottom()
	}

	componentDidUpdate(prevProps, prevState) {
		const { startConversation, popupActive } = this.props
		if (this.props.chatMessages !== prevProps.chatMessages)
			this.scrollToBottom()

		if (
			!prevProps.chatVisible &&
			this.props.chatVisible &&
			!this.props.completeRegisterPopupVisible
		) {
			startConversation()

			this.timer = setTimeout(() => {
				this.setState({ chatStart: true })
				if (!popupActive) this.messageInput.focus()
			}, 3000)
		}

		if (
			prevProps.completeRegisterPopupVisible &&
			!this.props.completeRegisterPopupVisible
		) {
			this.setState({ chatStart: true })
			if (!popupActive) this.messageInput.focus()
		}

		if (this.props.forceStart && !prevProps.forceStart) {
			this.setState({ chatStart: true })
		}
	}

	scrollToBottom = () => {
		if (!this.state.scrolling) {
			DEBUG && console.log('scroll to bottom')
			const objDiv = this.chatWindow
			objDiv.scrollTop = objDiv.scrollHeight
			this.setState({ scrolling: true })
			window.setTimeout(() => {
				this.setState({ scrolling: false })
			}, 2000)
		}
	}

	onChange = e => {
		const target = e.target
		// console.log(a)

		this.setState(prevState => ({
			data: { ...prevState.data, [target.name]: target.value },
			errors: { ...prevState.errors, [target.name]: false }
		}))
		// () => ()console.log(this.state.data)
	}

	sendMessage(e) {
		const { sendMessage, getAnswer } = this.props
		const { data } = this.state
		e.preventDefault()
		if (data.message === '') {
			return
		}

		sendMessage(data.message)
		this.setState(prevState => ({
			data: { ...prevState.data, message: '' },
			waitingForAnswer: true
		}))
		getAnswer(data.message).then(() => {
			this.scrollToBottom()
		})

		this.messageInput.focus()
	}

	hide() {
		this.props.hideChatWindow()
		this.props.showMainScreen()
	}

	onFocus() {
		// window.scrollTo(0, 0);
		// document.body.scrollTop = 0
	}

	render() {
		const {
			chatVisible,
			chatMessages,
			chatWindowSize,
			waitingForAnswer,
			popupActive
		} = this.props
		const { chatStart, data } = this.state
		const { sendMessage, onChange, onFocus } = this
		// const timer = window.setTimeout(
		// 	() => elementClasses.push('visible'),
		// 	2000
		// )

		return (
			<div className={`chat_window ${chatWindowSize}`}>
				<div
					className="chat_holder"
					key={myid.generate('chat_holder')}
					ref={el => {
						this.chatWindow = el
					}}
				>
					{chatMessages.map(element => (
						<p
							className={`chat_message ${element.type}`}
							key={element.id}
						>
							<Type
								text={element.message}
								active={chatStart}
								enabled={element.type === 'kokeshi'}
								onType={this.scrollToBottom}
							/>
						</p>
					))}
					<div
						id="wave"
						className={`wave ${
							waitingForAnswer ? 'visible' : 'hidden'
						}`}
					>
						<span className="dot" />
						<span className="dot" />
						<span className="dot" />
					</div>
				</div>
				<form onSubmit={sendMessage} className="chat_input">
					<input
						type="text"
						name="message"
						onChange={onChange}
						onFocus={onFocus}
						value={data.message}
						ref={input => {
							this.messageInput = input
						}}
					/>
					<button type="submit">send</button>
				</form>
			</div>
		)
	}
}

ChatWindow.propTypes = {
	// loading: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
	chatVisible: state.ui.chatVisible,
	chatMessages: state.chat.chatMessages,
	chatWindowSize: state.ui.chatWindowSize,
	completeRegisterPopupVisible: state.ui.completeRegisterPopupVisible,
	waitingForAnswer: state.chat.waitingForAnswer,
	forceStart: state.chat.forceStart,
	popupActive: state.ui.popupActive
})

export default connect(mapStateToProps, {
	sendMessage,
	getAnswer,
	sendEvent,
	startConversation
})(ChatWindow)
