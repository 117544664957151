import axios from 'axios'
import { assign as _assign } from 'lodash-es'
import { store } from './store/store'
import Settings from './Settings'
import { format } from 'date-fns'

const apiBase = 'https://api.one-squared.com/kokeshi/0.1.0/wp-json'
const calendarApiUrl = Settings.offlineDevMode
    ? 'http://localhost:8888/kokeshi_timeslots.php'
    : 'https://api.one-squared.com/kokeshi/timeslots_calculator_api_beta/'

const calendarSXApiUrl =
    'https://api.one-squared.com/kokeshi/timeslots_calculator_api_sxtech/'

const getAuthHeader = () => {
    if (store.getState().user.token) {
        return {
            Authorization: `Bearer ${store.getState().user.token}`
        }
    }
    return {}
}

const api = {
    user: {
        register: inputData => {
            const defaultData = {
                email: '',
                firstName: 'Human',
                girlfriend_fckbuddy: '',
                city: '',
                dominative_submissive: '',
                famous_person: '',
                fetishes: '',
                what_turns_on: '',
                movies_porn: '',
                toys: '',
                chatHistory: '',
                userId: ''
            }
            let data = {}
            _assign(data, defaultData, inputData)

            const formData = new FormData()

            formData.append('email', data.email)
            formData.append('first_name', data.firstName)
            formData.append('girlfriend_fckbuddy', data.girlfriend_fckbuddy)
            formData.append('city', data.city)
            formData.append('dominative_submissive', data.dominative_submissive)
            formData.append('fetishes', JSON.stringify(data.fetishes))
            formData.append('what_turns_on', data.what_turns_on)
            formData.append('movies_porn', data.movies_porn)
            formData.append('toys', data.toys)
            formData.append('chat_history', data.chatHistory)
            formData.append('user_id', data.userId)

            return axios
                .post(`${apiBase}/kokeshi/users/create`, formData)
                .then(res => res)
        },
        addPhone: inputData => {
            const formData = new FormData()
            formData.append('phone_nr', inputData.phoneNr)

            return axios
                .post(`${apiBase}/kokeshi/users/add_phone`, formData, {
                    headers: {
                        ...getAuthHeader()
                    }
                })
                .then(res => res)
        },
        resetPassword: inputData => {
            const formData = new FormData()
            formData.append('email', inputData.email_reset_password)

            return axios.post(
                `${apiBase}/kokeshi/users/reset_password`,
                formData,
                {}
            )
            // .catch(err => {
            //     console.error(err)
            // })
        },

        completeResetPassword: inputData => {
            const formData = new FormData()
            formData.append('password', inputData.password)
            formData.append('token', inputData.token)
            return axios.post(
                `${apiBase}/kokeshi/users/complete_reset_password`,
                formData
            )
        },
        completeRegister: inputData => {
            const defaultData = {
                signup_first_name: '',
                signup_last_name: '',
                new_password: '',
                activation_token: ''
            }
            let data = {}
            _assign(data, defaultData, inputData)

            const formData = new FormData()
            formData.append('first_name', data.signup_first_name)
            formData.append('last_name', data.signup_last_name)
            formData.append('password', data.new_password)
            formData.append('activation_token', data.activation_token)

            console.log(formData)
            // debugger

            return axios
                .post(`${apiBase}/kokeshi/users/confirm`, formData)
                .then(res => res)
        },

        order: inputData => {
            console.log('sendOrder Action')
            let data = {}

            const timeFormat = 'yyyy-MM-dd HH:mm:ss'

            const formData = new FormData()
            formData.append('type', 'appointment')
            formData.append('payment_method', inputData.paymentMethod)
            formData.append('payment_token', inputData.paymentToken)
            formData.append('option', inputData.option)
            formData.append('special_wishes', inputData.specialWishes)
            formData.append('voice', inputData.voice)
            formData.append('voice_no_voice', inputData.voiceNoVoice)
            formData.append('special_wishes', inputData.specialWishes)
            formData.append('cart', JSON.stringify(inputData.cart))
            formData.append('extras', JSON.stringify(inputData.extras))
            formData.append(
                'time_start',
                format(inputData.timeStart, timeFormat)
            )
            formData.append('time_end', format(inputData.timeEnd, timeFormat))
            formData.append('duration', inputData.duration)

            console.log(formData)
            // debugger

            return axios
                .post(`${apiBase}/kokeshi/payment`, formData, {
                    headers: {
                        ...getAuthHeader()
                    }
                })
                .then(res => res)
        },
        orderSx: inputData => {
            console.log('sendOrderSx API')
            let data = {}

            const formData = new FormData()
            formData.append('type', 'sx')
            formData.append('cart', JSON.stringify(inputData.cart))
            formData.append('selected_slot', inputData.selectedSlot)
            formData.append('payment_method', 'none')
            formData.append('slot_id', inputData.slotId)

            console.log(formData)
            // debugger

            return axios
                .post(`${apiBase}/kokeshi/payment`, formData, {
                    headers: {
                        ...getAuthHeader()
                    }
                })
                .then(res => res)
        },

        orderGf: inputData => {
            console.log('api.orderGf')
            let data = {}

            const timeFormat = 'yyyy-MM-dd HH:mm:ss'

            const formData = new FormData()
            formData.append('type', 'girlfriend')
            formData.append('payment_method', inputData.paymentMethod)
            formData.append('payment_token', inputData.paymentToken)
            formData.append('cart', JSON.stringify(inputData.cart))
            console.log(formData)

            // const jsonData = {
            //     type: 'girlfriend',
            //     payment_method: inputData.paymentMethod,
            //     payment_token: inputData.paymentToken,
            //     cart: inputData.cart
            // }
            // debugger

            return axios
                .post(`${apiBase}/kokeshi/payment`, formData, {
                    headers: {
                        ...getAuthHeader()
                    }
                })
                .then(res => res)
        },

        login: credentials => {
            const formData = new FormData()
            formData.append('username', credentials.username)
            formData.append('password', credentials.password)

            return axios
                .post(`${apiBase}/jwt-auth/v1/token`, formData, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                })
                .then(res => res)
        },

        checkLogin: () => {
            return axios
                .post(`${apiBase}/jwt-auth/v1/token/validate`, null, {
                    headers: {
                        ...getAuthHeader()
                    }
                })
                .then(res => res)
        },
        data: () =>
            axios
                .get(`${apiBase}/sn_api/user`, {
                    headers: {
                        ...getAuthHeader()
                    }
                })
                .then(res => res),
        // updateUserData: newUserData =>
        // axios
        //  .post(`${apiBase}/sn_api/user`, encodeForm(newUserData), {
        //      headers: { 'Content-Type': 'multipart/form-data' }
        //  })
        //  .then(res => res)
        updateUserData: newUserData =>
            axios
                .post(`${apiBase}/sn_api/user`, newUserData, {
                    headers: {
                        ...getAuthHeader()
                    }
                })
                .then(res => res)

        // newUser: newUserData =>
        //     axios
        //     .post(`${apiBase}/sn_api/new_user`, newUserData, {})
        //     .then(res => res)
    },
    pages: {
        getPages: () =>
            axios.get(`${apiBase}/wp/v2/pages?per_page=100`).then(res => res)
    },
    calendar: {
        get: () =>
            axios
                .get(`${calendarApiUrl}?t=${new Date().getTime()}`)
                .then(res => res)
    },
    calendarSX: {
        get: () =>
            axios
                .get(`${calendarSXApiUrl}?t=${new Date().getTime()}`)
                .then(res => res)
    },
    chat: {
        getDialogflow: (input, sessionId, userId) => {
            // import qs from 'qs';
            // const data = { 'bar': 123 };
            // const url = 'https://api.dialogflow.com/v1/query?v=20150910'
            console.log(
                `getDialogflow(input:${input}, sessionId:${
                    store.getState().uuid.uuid
                }, userId:${store.getState().user.userId})`
            )
            const url =
                'https://api.one-squared.com/kokeshi/users/api_2/index.php'

            const data = {
                query: input,
                lang: 'en',
                // contexts: [...store.getState().chat.contexts],
                // contexts: [{ name: 'name' }, { name: 'name' }],
                sessionId: store.getState().uuid.uuid,
                userId: store.getState().user.userId
            }
            const options = {
                method: 'GET',
                params: data,
                headers: {
                    'Content-Type': 'application/json'
                },
                url
            }
            const response = axios(options)
            console.log('Dialogflow response:')
            console.log(response)
            return response

            // axios.get(
            //  `https://api.dialogflow.com/v1/query?query=Can+you+give+me+a+blowjob%3F%3F&lang=en&v=20150910&sessionId=0.7750277092749729`,
            //  {},
            //  {
            //      headers: {
            //          Authorization: 'Bearer eac3ebaeac384533a89caf759b4537a1'
            //      }
            //  }
            // )
        },
        sendDialogflowEvent: (eventName, eventData) => {
            // import qs from 'qs';
            // const data = { 'bar': 123 };
            const url =
                'https://api.one-squared.com/kokeshi/users/api_2/index.php'

            const data = {
                event: { name: eventName, data: eventData },
                lang: 'en',
                // contexts: [...store.getState().chat.contexts],
                // contexts: [{ name: 'name' }, { name: 'name' }],
                sessionId: store.getState().uuid.uuid,
                userId: store.getState().user.userId
            }
            const options = {
                method: 'GET',
                params: data,
                headers: {
                    // Authorization: 'Bearer 716aae8541c24c45906e4a6630e8d8cd',
                    'Content-Type': 'application/json'
                },
                url
            }
            return axios(options)

            // axios.get(
            //  `https://api.dialogflow.com/v1/query?query=Can+you+give+me+a+blowjob%3F%3F&lang=en&v=20150910&sessionId=0.7750277092749729`,
            //  {},
            //  {
            //      headers: {
            //          Authorization: 'Bearer eac3ebaeac384533a89caf759b4537a1'
            //      }
            //  }
            // )
        }
    },
    products: {
        products: `${apiBase}/wc_api_proxy/products`,
        getProducts: () =>
            // axios.get(`${apiBase}/wc_api_proxy/products/`).then(res => {
            axios.get(`${apiBase}/wc_api_proxy/products`).then(res => {
                // console.log(res)
                return res
            })
    }
}

export default api
