import shortid from 'shortid'
import {
    ADD_USER_DATA_STORAGE,
    USER_LOGGED_IN,
    USER_LOGGED_OUT,
    CONSOLIDATE_USER_DATA
} from '../actions/types'

const initialState = {
    userId: shortid.generate(),
    loggedIn: false,
    firstName: 'Human',
    lastName: '',
    activationToken: '',
    userHasSignedUp: false,
    gfEndDate: ''
}

export default function(state = initialState, action) {
    let data = {}
    switch (action.type) {
        case CONSOLIDATE_USER_DATA:
            let override = {}
            if (state.name && !state.firstName)
                override = { firstName: state.name }
            data = {
                userId: shortid.generate(),
                firstName: 'Human',
                lastName: '',
                activationToken: '',
                ...state,
                ...override
            }
            delete data.name
            return data

        case ADD_USER_DATA_STORAGE:
            // we dont use the key name any more, instead we use firstName
            data = { ...state, ...action.payload, name: null }
            delete data.name
            return data

        case USER_LOGGED_IN:
            return {
                userId: shortid.generate(),
                ...state,
                loggedIn: true,
                token: action.payload.token,
                firstName: action.payload.user_first_name,
                lastName: action.payload.user_last_name,
                gfEndDate: action.payload.gf_end_date
            }
        case USER_LOGGED_OUT:
            return {
                loggedIn: false,
                firstName: state.firstName,
                userId: state.userId
            }
        default:
            return state
    }
}
