import React from 'react'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player'

import { ReactComponent as IconPlay } from '../../img/icons/play.svg'
import { ReactComponent as IconStop } from '../../img/icons/stop.svg'

import voice1Mp3 from '../../audio/voices/voice_1.mp3'
import voice1Ogg from '../../audio/voices/voice_1.ogg'

import voice2Mp3 from '../../audio/voices/voice_2.mp3'
import voice2Ogg from '../../audio/voices/voice_2.ogg'

function Circle({ svgProps, circleStyle }) {
	return (
		<svg data-name="Layer 1" viewBox="0 0 18.75 18.75" {...svgProps}>
			<circle
				cx={9.37}
				cy={9.37}
				r={9}
				fill="none"
				stroke="#ddd"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={0.75}
			/>
			<circle
				cx={9.37}
				cy={9.37}
				r={9}
				style={circleStyle}
				fill="none"
				stroke="#00557d"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={0.75}
			/>
		</svg>
	)
}

class VoiceSelector extends React.Component {
	state = { playedPercent: 0 }

	togglePlaying = () => {
		const {
			player,
			props: { changePlaying, id, playing }
		} = this
		const isPlaying = id === playing
		//
		// changePlaying(id)
		if (isPlaying) changePlaying('')
		if (!isPlaying) {
			changePlaying(id)
			player.seekTo(0)
		}
	}
	onEnded = () => {
		const { changePlaying } = this.props
		changePlaying('')
	}

	onProgress = played => {
		this.setState({ playedPercent: played.played })
	}

	onChoose = () => {
		const {
			player,
			props: { changePlaying, onChange, id }
		} = this
		onChange(id)
		changePlaying('')
		player.seekTo(0)
	}

	ref = player => {
		this.player = player
	}
	render() {
		const {
			togglePlaying,
			onEnded,
			ref,
			onProgress,
			onChoose,
			props: { label, id, playing },
			state: { playedPercent }
		} = this
		const isPlaying = id === playing
		const strokeDasharray = 57
		const strokeDashoffset = isPlaying
			? strokeDasharray * (1 - playedPercent)
			: 0

		let sources = []
		if (playing === 'voice_1') sources = [voice1Mp3, voice1Ogg]
		if (playing === 'voice_2') sources = [voice2Mp3, voice2Ogg]

		return (
			<div className="voice_selector">
				<div className="player_hidden">
					<ReactPlayer
						playing={isPlaying}
						url={sources}
						onEnded={onEnded}
						onProgress={onProgress}
						progressInterval={50}
						ref={ref}
					/>
				</div>
				<div className="play_button_holder">
					<button className="play_button" onClick={togglePlaying}>
						<Circle
							circleStyle={{
								strokeDasharray: strokeDasharray,
								strokeDashoffset: strokeDashoffset
							}}
							svgProps={{
								className: 'circle'
							}}
						/>
						<div className="icon_holder">
							<IconPlay
								className={`icon ${isPlaying ? '' : 'visible'}`}
							/>
							<IconStop
								className={`icon ${isPlaying ? 'visible' : ''}`}
							/>
						</div>
					</button>
				</div>
				<div className="label">{label}</div>
				<div className="choose_button_holder">
					<button className="choose primary small" onClick={onChoose}>
						Choose
					</button>
				</div>
			</div>
		)
	}
}

VoiceSelector.propTypes = {
	playing: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	changePlaying: PropTypes.func.isRequired
}

export default VoiceSelector
