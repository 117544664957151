import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import formatPrice from '../../utils/formatPrice'

export default class ExtrasCheckbox extends React.Component {
	onChange = e => {
		const { changeExtras, productId, name } = this.props
		const value = e.target.checked
		changeExtras({
			id: productId,
			value: value
		})
	}

	openDescription = () => {
		const { productId, displayMessage } = this.props
		displayMessage(`extra_${productId}`)
		console.log(`open Description for extra_${productId}`)
	}

	render() {
		const {
			props: {
				error,
				checked,
				label,
				name,
				required,
				link,
				linkLabel,
				linkInternal,
				className,
				lengthHours,
				price,
				hourly,
				productName
			},
			onChange,
			openDescription
		} = this

		// _find(data.extras, product.slug) == true

		const finalPrice = hourly
			? parseFloat(price) * lengthHours
			: parseFloat(price)

		// label={`${product.name} (+${)})`}

		return (
			<div
				className={`field checkbox ${
					error ? 'error' : ''
				} ${className}`}
			>
				<input
					type="checkbox"
					id={name}
					name={name}
					value={true}
					checked={checked}
					onChange={onChange}
				/>
				<label htmlFor={name}>
					{productName} (+{formatPrice(finalPrice)})
				</label>
				<div onClick={openDescription} className="questionmark">
					?
				</div>
			</div>
		)
	}
}

ExtrasCheckbox.propTypes = {
	changeExtras: PropTypes.func.isRequired,
	lengthHours: PropTypes.number.isRequired,
	productName: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	price: PropTypes.string.isRequired,
	hourly: PropTypes.bool.isRequired
}

ExtrasCheckbox.defaultProps = {
	error: false,
	data: false,
	label: 'Label missing!',
	required: false
}
