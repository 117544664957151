import React from 'react'
import { connect } from 'react-redux'

import { login, sendOrder } from '../../actions/userActions'
import { hidePopup, showPopup } from '../../actions/uiActions'

import MeetForm from '../../forms/MeetForm'
import PaymentForm from '../../forms/PaymentForm'
import _t from '../../utils/translate'

class MeetPopup extends React.Component {
    static propTypes = {}

    constructor(props) {
        super(props)
        this.displayMessage = this.displayMessage.bind(this)
        // this.hideError = this.hideError.bind(this)

        this.state = {}
    }

    goToPopup = popupName => {
        const {
            props: { hidePopup, showPopup }
        } = this
        hidePopup('experiencePopup')
        showPopup(popupName)
    }

    displayMessage = (message, type) => {
        this.setState({ message, error: type === 'error' })
    }

    render() {
        const {
            props: { experiencePopupVisible },
            state: { message, error },
            goToPopup
        } = this
        return (
            <div
                className={`popup experience_popup ${
                    experiencePopupVisible ? 'active' : ''
                }`}
            >
                <div className="content_wrapper">
                    <div className="content">
                        {
                            //                        <h2>Experience me!</h2>
                        }
                        <h2>Meet Me</h2>
                        <p>
                            facts: Come and join me at my private Berlin flat
                            where we can meet and have some fun together.
                        </p>
                        <button
                            type="button"
                            onClick={() => {
                                goToPopup('meetPopup')
                            }}
                        >
                            Meet Me
                        </button>
                        <div className="space_50" />
                        <h2>Virtual Girlfriend</h2>
                        <p>
                            facts: A renewable weekly package where I can be
                            your customized virtual lover and spoil you with
                            real time chats and sexy pics.
                        </p>
                        <button
                            type="button"
                            onClick={() => {
                                goToPopup('virtualGfPopup')
                            }}
                        >
                            Sign up
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    experiencePopupVisible: state.ui.experiencePopupVisible,
    calendar: state.calendar,
    products: state.products
})

export default connect(mapStateToProps, { hidePopup, showPopup })(MeetPopup)
