import React from 'react'
import { PropTypes } from 'prop-types'

export default class QuantityField extends React.Component {
    constructor(props) {
        super(props)
        this.changeHandler = this.changeHandler.bind(this)
        this.change = this.change.bind(this)
    }

    changeHandler = e => {
        const { change } = this
        // const value = parseInt(e.target.value, 10) <= 1 ? e.target.value : 1
        const value = e.target.value
        change(value)
    }

    increase = () => {
        const {
            props: { quantity },
            change
        } = this
        change(quantity + 1)
    }

    decrease = () => {
        const {
            props: { quantity },
            change
        } = this
        if (quantity > 1) change(quantity - 1)
    }

    change = value => {
        const { onChange, name } = this.props
        onChange({ target: { name, value } })
    }

    render() {
        const { quantity, style } = this.props
        const { changeHandler, increase, decrease } = this
        return (
            <div className="quantity_field" style={style}>
                <input
                    type="number"
                    className="quantity"
                    min="1"
                    name="quantity"
                    value={quantity}
                    onChange={changeHandler}
                />
                <button
                    type="button"
                    className="change_qty add"
                    onClick={increase}
                >
                    +
                </button>
                <button
                    type="button"
                    className="change_qty substract"
                    onClick={decrease}
                >
                    -
                </button>
            </div>
        )
    }
}

QuantityField.defaultProps = {
    style: {}
}

QuantityField.propTypes = {
    onChange: PropTypes.func.isRequired,
    quantity: PropTypes.number.isRequired,
    style: PropTypes.object // eslint-disable-line
}
