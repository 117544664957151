import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

class WelcomeSX extends React.Component {
	componentDidUpdate = prevProps => {
		const { recalculateHeight, sxPopupVisible } = this.props
		if (!prevProps.sxPopupVisible && sxPopupVisible) {
			// console.log('recalculateHeight from VoiceNoVoice')
			setTimeout(() => {
				recalculateHeight()
			}, 500)
		}
	}
	render() {
		const { changePage } = this.props
		return (
			<>
				<h1>SX TECH</h1>
				<p>
					I look forward to meeting you at Sx Tech 2020 on November
					20th. You can book a time to meet me in my special room
					during the conference.
				</p>
				<p>
					I have both individual and group slots available. You must
					purchase a ticket to Sx Tech in order to book a time with
					me.
				</p>
				<p>
					You can buy tickets at{' '}
					<a
						href="https://sxtech.eu"
						target="_blank"
						rel="noopener noreferrer"
					>
						https://sxtech.eu
					</a>
				</p>
				<button
					className="primary"
					onClick={() => changePage('calendarSX', 2)}
				>
					Book Slot!
				</button>
			</>
		)
	}
}

WelcomeSX.propTypes = {
	sxPopupVisible: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
	sxPopupVisible: state.ui.sxPopupVisible
})

export default connect(mapStateToProps)(WelcomeSX)
