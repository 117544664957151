import React from 'react'
import { connect } from 'react-redux'
import ReactPlayer from 'react-player/youtube'

import {
	hideChatBackground,
	showChat,
	showMainScreen
} from '../../actions/uiActions'
import Settings from '../../Settings'

class ChatBackground extends React.Component {
	static propTypes = {}

	constructor(props) {
		super(props)
		this.playerRef = React.createRef()
		this.hide = this.hide.bind(this)
		this.onPlayerStarted = this.onPlayerStarted.bind(this)
		this.state = {
			playing: false,
			muted: true,
			isPlaying: false,
			loading: true
		}
	}

	componentDidUpdate(prevProps) {
		if (
			!prevProps.chatBackgroundVisible &&
			this.props.chatBackgroundVisible
		) {
			console.log('Video started loading')
			this.setState({ playing: true })
		}
	}

	hide() {
		this.setState({ playing: false, isPlaying: false })
		this.props.hideChatBackground()
		this.props.showMainScreen()
		clearTimeout(this.timer)
	}

	onPlayerStarted = () => {
		if (this.props.chatBackgroundVisible) {
			console.log('Video Started playing')
			this.setState({ isPlaying: true, loading: false })
			setTimeout(() => {
				console.log('Video unmuted')
				this.setState({ muted: false })
			}, 500)
			this.timer = setTimeout(() => {
				this.props.showChat()
				this.setState({ isPlaying: false })
			}, Settings.chatStartDelay)
		}
	}

	onPlayerEnded = () => {
		console.log('Video Ended')
		this.setState({ isPlaying: false, playing: false })
	}
	render() {
		const {
			props: { chatBackgroundVisible },
			state: { playing, muted, isPlaying, loading },
			playerRef,
			onPlayerStarted,
			onPlayerEnded
		} = this
		return (
			<div className={`chat ${chatBackgroundVisible ? 'visible' : ''}`}>
				<div className="chat_right">
					<div className="bg_video">
						<ReactPlayer
							url="https://youtu.be/o3o043S9MUw"
							playing={playing}
							width="100%"
							muted={muted}
							height="100%"
							controls={false}
							config={{
								youtube: {
									playerVars: { showinfo: 0 },
									preload: true
								}
							}}
							ref={playerRef}
							onStart={onPlayerStarted}
							onEnded={onPlayerEnded}
						/>
					</div>
					<div className={`bg_image ${isPlaying ? '' : 'visible'}`} />
				</div>
				<div
					id="wave2"
					className={`wave ${loading ? 'visible' : 'hidden'}`}
				>
					<span className="dot" />
					<span className="dot" />
					<span className="dot" />
				</div>

				<button className="close" onClick={this.hide}>
					SKIP
				</button>
			</div>
		)
	}
}

const mapStateToProps = state => ({
	chatBackgroundVisible: state.ui.chatBackgroundVisible
})

export default connect(mapStateToProps, {
	hideChatBackground,
	showMainScreen,
	showChat
})(ChatBackground)

//
