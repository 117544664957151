import React from 'react'
import PropTypes from 'prop-types'
// import { Form, Button, Message } from 'semantic-ui-react'
import Validator from 'validator'
import { connect } from 'react-redux'
// import { Link } from 'react-router-dom'

import InlineError from '../messages/InlineError'
import { showMessage } from '../actions/uiActions'
import _t from '../utils/translate'
import formatPrice from '../utils/formatPrice'

import ToggleButtons from './fields/ToggleButtons'
import QuantityField from './fields/QuantityField'
import PaypalCheckout from './fields/PaypalCheckout'
import StripeCheckout from './fields/StripeCheckout'
import Summary from './fields/Summary'
// import { LanguageLink } from '../utils/LanguageLink'

const initialFormData = {}

class BookVirtualGirlFriendForm extends React.Component {
    constructor(props) {
        super(props)
        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.onSuccess = this.onSuccess.bind(this)
        this.sendOrderCash = this.sendOrderCash.bind(this)

        this.state = {
            data: initialFormData,
            loading: false,
            errors: {}
        }
    }

    onChange = e => {
        const { onChange } = this.props
        onChange(e.target.name, e.target.value)
    }

    onSubmit = e => {
        const { submit, showMessage } = this.props
        const { data } = this.state
        e.preventDefault()
        const errors = this.validate(data)
        this.setState({ errors })
        if (Object.keys(errors).length === 0) {
            // this.setState({ loading: true })
            submit(data)
        }
    }

    validate = data => {
        const errors = {}
        // if (!Validator.isEmail(data.username))
        //     errors.username = _t('Invalid email', 'account')
        if (!data.terms)
            errors.terms = _t(`You have to accept the terms.`, 'account')

        return errors
    }

    onSuccess = resData => {
        console.log('onSuccess')
        console.log(resData)
        const {
            props: { showMessage, paymentSuccess },
            state: { data }
        } = this
        paymentSuccess({ ...resData, paymentMethod: 'paypal' })
    }

    sendOrderCash = () => {
        const { sendOrder, displayMessage } = this.props
        displayMessage('loading_generic')
        sendOrder({ paymentMethod: 'cash' })
    }

    render() {
        const {
            state: { data, errors, loading },
            props: {
                displayMessage,
                productId,
                price,
                quantity,
                paymentMethod,
                formVisible
            },
            onChange,
            onSuccess,
            sendOrderCash
        } = this
        // const errors = {}

        // console.log(this.state)
        // console.log(data.terms)
        // console.log(quantity)
        const total = price * quantity

        return (
            <div
                className={`${loading === true ? 'loading' : ''}
                    `}
            >
                {errors.global && (
                    <div>
                        <div>{_t('Something went wrong', 'account')}</div>
                        <p>{errors.global}</p>
                    </div>
                )}
                <h2 className="align_center">
                    {_t('Virtual Girlfriend', 'account')}
                </h2>
                <p>
                    You can book more time with me here. If you have still some
                    time left, the weeks you book will be added to it.
                </p>
                <h3>How many weeks do you want do add?</h3>
                <QuantityField
                    quantity={quantity}
                    onChange={onChange}
                    name="quantity"
                />

                <h3>Summary</h3>
                <p>
                    <b>
                        {quantity} {quantity === 1 ? 'week' : 'weeks'} with me à{' '}
                        {formatPrice(price)} <br />
                        Total: {formatPrice(total)}
                    </b>
                </p>

                <ToggleButtons
                    name="paymentMethod"
                    value={paymentMethod}
                    onChange={onChange}
                    values={[
                        { label: 'Paypal', value: 'paypal' },
                        { label: 'Credit Card', value: 'credit_card' }
                    ]}
                />
                <div className="payment_details">
                    {paymentMethod === 'credit_card' && formVisible && (
                        <StripeCheckout />
                    )}
                    {paymentMethod === 'paypal' && formVisible && (
                        <PaypalCheckout
                            price={total}
                            displayMessage={displayMessage}
                            onSuccess={onSuccess}
                        />
                    )}
                </div>
            </div>
        )
    }
}

BookVirtualGirlFriendForm.propTypes = {
    // submit: PropTypes.func.isRequired
}

export default connect(null, { showMessage })(BookVirtualGirlFriendForm)
