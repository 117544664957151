import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {
	addHours,
	subMinutes,
	areIntervalsOverlapping,
	isWithinInterval
} from 'date-fns'

import calculateTimeSlots from '../../utils/calculateTimeSlots'

const DEBUG = true

const Console = {}
Console.log = text => {
	DEBUG && console.log(text)
}

// import formatLength from '../../utils/formatLength'

const checkIfOptionIsAvaialable = () => {}

export default class TimesSelect extends React.Component {
	static propTypes = {
		// name: React.PropTypes.string,
	}

	constructor(props) {
		super(props)
		this.onChange = this.onChange.bind(this)
		this.getTimeSlots = this.getTimeSlots.bind(this)
	}

	onChange = time => {
		const { onChange: _onChange, selectedDate } = this.props

		_onChange({ selectedTime: time })
	}

	getTimeSlots = () => {
		const { length, selectedDate, selectedCalendar } = this.props
		return calculateTimeSlots(length, selectedDate, selectedCalendar)
	}

	render() {
		const {
			props: { error, selectedDate, label, length, selectedTime },
			onChange,
			getTimeSlots
		} = this

		// Console.log('type of length:')
		// Console.log(typeof length)
		// When the user hasnt selected the day yet, we dont display the checkin time
		if (!selectedDate) return null

		// When the user chooses day or night, we dont need to select the checkin time
		if (typeof length === 'string') return null

		const availableTimeSlots = getTimeSlots()

		// console.log(availableTimeSlots)

		// Console.log(availableTimeSlots)
		return (
			<div className={`time_select field ${error ? 'error' : ''}`}>
				<span className="error_msg">{error}</span>
				<p>
					{label} <br />
					{/*Length: {formatLength(length)} */}
				</p>
				<div className="button_holder">
					{availableTimeSlots.slots.map(timeSlot => {
						const { time, available } = timeSlot
						return (
							<button
								type="button"
								key={time}
								className={`${
									time === selectedTime ? 'selected' : ''
								} ${available ? '' : 'disabled'}`}
								onClick={() => onChange(time)}
							>
								{time}:00
							</button>
						)
					})}
				</div>
			</div>
		)
	}
}

TimesSelect.defaultProps = {
	selectedCalendar: PropTypes.string.isRequired
}

TimesSelect.defaultProps = {
	error: false,
	data: false,
	label: 'Label missing!',
	required: false
}
//
