import React from 'react'
import { Provider } from 'react-redux'

import { store, persistor } from './store/store'
import { PersistGate } from 'redux-persist/integration/react'

import AppWrapper from './AppWrapper'
import StartScreen from './components/pages/StartScreen'
import MainScreen from './components/pages/MainScreen'
import Chat from './components/pages/Chat'
import Space from './components/pages/Space'
import ChatBackground from './components/pages/ChatBackground'
// import Register from './components/pages/Register'
import Imprint from './components/pages/Imprint'
import Faq from './components/pages/Faq'
import LoginPopup from './components/popups/LoginPopup'
import MeetPopup from './components/popups/MeetPopup'
import SxPopup from './components/popups/SxPopup'
import ExperiencePopup from './components/popups/ExperiencePopup'
import VirtualGfPopup from './components/popups/VirtualGfPopup'
import CompleteRegisterPopup from './components/popups/CompleteRegisterPopup'
import ResetPasswordPopup from './components/popups/ResetPasswordPopup'
import CompleteResetPasswordPopup from './components/popups/CompleteResetPasswordPopup'
import SxLoggedOutPopup from './components/popups/SxLoggedOutPopup'

import PageLoad from './utils/PageLoad'

import BlurWrapper from './components/BlurWrapper'

import milligram from 'milligram' // eslint-disable-line
// import shortid from 'shortid'

import { createUUID } from './actions/chatActions'

import './scss/_index.scss'
createUUID()

const App = () => (
	<Provider store={store}>
		<PageLoad>
			<PersistGate loading={null} persistor={persistor}>
				<AppWrapper>
					<BlurWrapper>
						<MainScreen />
						<StartScreen />
						<ChatBackground />
						<Chat />
						<Space />
					</BlurWrapper>
					<LoginPopup />
					<MeetPopup />
					<SxPopup />
					<CompleteRegisterPopup />
					<ExperiencePopup />
					<VirtualGfPopup />
					<ResetPasswordPopup />
					<CompleteResetPasswordPopup />
					<SxLoggedOutPopup />
					<Imprint />
					<Faq />
				</AppWrapper>
			</PersistGate>
		</PageLoad>
	</Provider>
)

export default App
