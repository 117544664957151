import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

const SlotListSX = ({ loading, slots, setTime, selectedSlot }) => {
	if (loading) return <p>loading...</p>
	const slotsForDisplay = slots.map(slot => {
		return {
			...slot,
			label: slot.start_time.substr(11, 5),
			// length: slot.length,
			booked: `${slot.nr_people_booked}/${slot.max_nr_persons}`,
			available: slot.nr_people_booked < slot.max_nr_persons
		}
	})

	// console.log(slotsForDisplay)

	return (
		<>
			<div className="sx_time_select">
				{slotsForDisplay.map(slot => (
					<button
						key={`sx_slot_${slot.start_time}`}
						className={` ${slot.available ? '' : 'disabled'} ${
							slot.start_time === selectedSlot ? 'selected' : ''
						}`}
						onClick={() => {
							setTime(
								slot.start_time,
								slot.max_nr_persons,
								slot.id
							)
						}}
					>
						<span className="time"> {slot.label}</span>
						<br />
						<span className="length">
							{slot.length_minutes} min
						</span>
						{' - '}
						<span className="booked">{slot.booked}</span>
					</button>
				))}
			</div>
		</>
	)
}

class CalendarSX extends React.Component {
	setTime = (time, maxNrPersons, slotId) => {
		const { changeData, changePage } = this.props
		changeData('selectedSlot', time)
		changeData('maxNrPersons', maxNrPersons)
		changeData('slotId', slotId)
		changePage('confirmationSX', 3)
	}

	render() {
		const {
			setTime,
			props: { changePage, sxCalendar, changeData, selectedSlot }
		} = this
		return (
			<>
				<h1>Choose Time</h1>
				<p>
					Each slots shows the bookings (first nr.) and max people
					(second nr.).
					<br />
					e.g. <b>2/5</b>
				</p>
				<SlotListSX
					loading={sxCalendar.loading}
					slots={sxCalendar.slots}
					setTime={setTime}
					selectedSlot={selectedSlot}
				/>
			</>
		)
	}
}

CalendarSX.propTypes = {
	changePage: PropTypes.func.isRequired,
	changeData: PropTypes.func.isRequired,
	selectedSlot: PropTypes.oneOfType([
		PropTypes.oneOf([null]),
		PropTypes.string
	]),
	sxCalendar: PropTypes.shape({
		loading: PropTypes.bool.isRequired,
		slots: PropTypes.arrayOf(
			PropTypes.shape({ lengthMinutes: PropTypes.number })
		)
	}).isRequired
}

const mapStateToProps = state => ({
	sxCalendar: state.calendar.sx
})

export default connect(mapStateToProps)(CalendarSX)
