import React from 'react'
import PropTypes from 'prop-types'
import { find as _find } from 'lodash-es'
import { connect } from 'react-redux'

import formatPrice from '../../utils/formatPrice'

class VoiceNoVoiceRadio extends React.Component {
	handleOptionChange = e => {
		const { onChange, name } = this.props
		// console.log(e.target.value)
		onChange({ target: { name: name, value: e.target.value } })
	}

	setHover = value => {
		const { onChange, name } = this.props
		onChange({ target: { name: 'voiceNoVoiceHover', value } })
	}

	render() {
		const {
			props: { value, displayMessage, products },
			setHover
		} = this

		const basePriceVoice = _find(products.products, {
			slug: 'kokeshi-1h-talk'
		})?.price

		const basePriceNoVoice = _find(products.products, {
			slug: 'kokeshi-1h'
		})?.price

		return (
			<div className="radioGroup">
				<div
					className="radio"
					onMouseOver={() => {
						setHover('voice')
					}}
					onMouseOut={() => {
						setHover('')
					}}
				>
					<input
						type="radio"
						value="voice"
						id="voice"
						checked={value === 'voice'}
						onChange={this.handleOptionChange}
					/>
					<label htmlFor="voice">
						<span className="title">Voice</span>
						{
							<span className="price">
								Base Price: {basePriceVoice}€
							</span>
						}
						<span className="descr">
							Using audio technology, I can hear you and speak
							with you in real time during our visit. You meet me
							at my private flat in Berlin with no human
							interaction involved.{' '}
							<button
								type="button"
								className="link"
								onClick={() => {
									displayMessage('more_information_voice')
								}}
							>
								more information
							</button>
						</span>
					</label>
				</div>
				<div
					className="radio"
					onMouseOver={() => {
						setHover('no_voice')
					}}
					onMouseOut={() => {
						setHover('')
					}}
				>
					<input
						type="radio"
						value="no_voice"
						id="no_voice"
						checked={value === 'no_voice'}
						onChange={this.handleOptionChange}
					/>
					<label htmlFor="no_voice">
						<span className="title">No Voice</span>
						{
							<span className="price">
								Base Price: {basePriceNoVoice}€
							</span>
						}
						<span className="descr">
							Enjoy just my medical grade silicone body with no
							audio or visual technology.{' '}
							<button
								type="button"
								className="link"
								onClick={() => {
									displayMessage('more_information_no_voice')
								}}
							>
								more information
							</button>
						</span>
					</label>
				</div>
			</div>
		)
	}
}

VoiceNoVoiceRadio.propTypes = {
	value: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
	products: state.products
})

export default connect(mapStateToProps)(VoiceNoVoiceRadio)
