import React from 'react'
import PropTypes from 'prop-types'

import formatPrice from '../../utils/formatPrice'

export default class OptionsRadio extends React.Component {
	static propTypes = {
		value: PropTypes.string.isRequired,
		// valueHover: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired
	}

	constructor(props) {
		super(props)
		this.setHover = this.setHover.bind(this)
	}

	handleOptionChange = e => {
		const { onChange, name } = this.props
		// console.log(e.target.value)
		onChange({ target: { name: name, value: e.target.value } })
	}

	setHover = value => {
		const { onChange, name } = this.props
		onChange({ target: { name: `${name}Hover`, value } })
	}

	render() {
		const {
			props: { value, displayMessage, price, priceTalk, voiceNoVoice },
			setHover
		} = this
		if (!voiceNoVoice === 'voice') return null
		return (
			<div className="radioGroup">
				<div
					className="radio"
					onMouseOver={() => {
						setHover('full')
					}}
					onMouseOut={() => {
						setHover('')
					}}
				>
					<input
						type="radio"
						value="full"
						id="full"
						checked={value === 'full'}
						onChange={this.handleOptionChange}
					/>
					<label htmlFor="full">
						<span className="title">Full</span>
						{/*<span className="price">{formatPrice(priceTalk)}</span>*/}
						<span className="descr">
							Audio and visual features are turned on in the room.
							I can hear you, speak with you AND see you.{' '}
							<button
								type="button"
								className="link"
								onClick={() => {
									displayMessage('more_information_full')
								}}
							>
								more information
							</button>
						</span>
					</label>
				</div>
				<div
					className="radio"
					onMouseOver={() => {
						setHover('blindfold')
					}}
					onMouseOut={() => {
						setHover('')
					}}
				>
					<input
						type="radio"
						value="blindfold"
						id="blindfold"
						checked={value === 'blindfold'}
						onChange={this.handleOptionChange}
					/>
					<label htmlFor="blindfold">
						<span className="title">Blindfold</span>
						{/*<span className="price">{formatPrice(priceTalk)}</span>*/}
						<span className="descr">
							Audio features are turned on in the room. I can hear
							you and then speak with you in real time.{' '}
							<button
								type="button"
								className="link"
								onClick={() => {
									displayMessage('more_information_blindfold')
								}}
							>
								more information
							</button>
						</span>
					</label>
				</div>
			</div>
		)
	}
}

OptionsRadio.propTypes = {
	voiceNoVoice: PropTypes.string.isRequired
}
