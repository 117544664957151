import React from 'react'
import PropTypes from 'prop-types'
import ReactGA from 'react-ga'
import Validator from 'validator'
import { connect } from 'react-redux'
import { addHours } from 'date-fns'

import InlineError from '../messages/InlineError'
import { showMessage, showPage } from '../actions/uiActions'
import { fetchCalendar } from '../actions/calendarActions'
import _t from '../utils/translate'
import calculateTimeSlots from '../utils/calculateTimeSlots'
import Settings from '../Settings'

import Checkbox from './fields/Checkbox'
import OptionsImage from './fields/OptionsImage'
import OptionsRadio from './fields/OptionsRadio'
import ExtrasDisplay from './fields/ExtrasDisplay'

const initialFormData = {
    terms: false,
    option: 'full',
    specialWishes: '',
    optionHover: ''
}

class ExtraWishes extends React.Component {
    constructor(props) {
        super(props)
        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.showImprint = this.showImprint.bind(this)

        this.state = {
            data: initialFormData,
            loading: false,
            errors: {}
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            props: { changeData }
        } = this
        if (!prevProps.meetPopupVisible && this.props.meetPopupVisible) {
            this.setState({ data: initialFormData }, () => {
                changeData('specialWishes', this.state.data.specialWishes)
                changeData('option', this.state.data.option)
            })
        }

        if (prevState.data.specialWishes !== this.state.data.specialWishes) {
            changeData('specialWishes', this.state.data.specialWishes)
        }
        if (prevState.data.option !== this.state.data.option) {
            changeData('option', this.state.data.option)
        }
    }

    onChange = e => {
        // console.log(e)
        // console.log(e)
        // console.log({ [e.target.name]: e.target.value })
        const { length, selectedDate } = this.state.data
        let override = {}

        this.setState(
            {
                data: {
                    ...this.state.data,
                    [e.target.name]: e.target.value,
                    ...override
                },
                errors: { ...this.state.errors, [e.target.name]: false }
            },
            this.calcPrice
        )
    }

    changeExtras = e => {
        const { addProduct, removeProduct } = this
        if (e.value) addProduct(e.id)
        if (!e.value) removeProduct(e.id)
    }

    addProduct = product => {
        const { changeData, extras } = this.props
        changeData('extras', [...extras, product])
    }

    removeProduct = product => {
        const { changeData, extras } = this.props
        changeData('extras', [...extras.filter(element => element !== product)])
    }

    onSubmit = e => {
        const { changePage, showMessage } = this.props
        const { data } = this.state
        e.preventDefault()
        const errors = this.validate(data)
        this.setState({ errors })
        if (Object.keys(errors).length === 0) {
            // submit(data)
            changePage('payment', 6)
        }
    }

    showImprint() {
        ReactGA.pageview('imprint')
        const { showPage } = this.props
        showPage('imprint')
    }

    validate = data => {
        const errors = {}
        // if (!Validator.isEmail(data.username))
        //     errors.username = _t('Invalid email', 'account')
        if (!data.terms)
            errors.terms = _t(`You have to accept the terms.`, 'account')

        // if (!data.selectedDate)
        //     errors.selectedDate = _t(`Please select a day.`, 'account')

        return errors
    }

    render() {
        const {
            state: { data, errors, loading, extraProducts },
            props: {
                calendar,
                displayMessage,
                price,
                priceTalk,
                voiceNoVoice,
                extras,
                lengthHours
            },
            onChange,
            onProduct,
            onChangeTime,
            changeExtras,
            showImprint,
            addProduct,
            removeProduct
        } = this
        // const errors = {}

        // console.log(this.state)
        // console.log(data.terms)

        return (
            <form onSubmit={this.onSubmit}>
                {errors.global && (
                    <div>
                        <div>{_t('Something went wrong', 'account')}</div>
                        <p>{errors.global}</p>
                    </div>
                )}
                {voiceNoVoice === 'voice' && (
                    <>
                        <h2 className="align_center">
                            {_t('Options', 'account')}
                        </h2>
                        <p className="align_left">
                            <b>Please choose between those options:</b>
                        </p>
                        <OptionsRadio
                            value={data.option}
                            name="option"
                            price={price}
                            priceTalk={priceTalk}
                            onChange={onChange}
                            displayMessage={displayMessage}
                            voiceNoVoice={voiceNoVoice}
                        />
                    </>
                )}
                <h3>Extras</h3>
                <ExtrasDisplay
                    changeExtras={changeExtras}
                    displayMessage={displayMessage}
                    extras={extras}
                    lengthHours={lengthHours}
                />
                <h3>Special Wishes</h3>
                <p>We will try to accommodate you as best as we can.</p>
                <textarea
                    name="specialWishes"
                    value={data.specialWishes}
                    onChange={onChange}
                    placeholder="eg: wig color, toys or clothing"
                />
                <Checkbox
                    label="I've read and agree to the "
                    callBack={showImprint}
                    linkInternal
                    linkLabel="Terms of Service"
                    required
                    name="terms"
                    checked={data.terms}
                    onChange={onChange}
                    error={errors.terms}
                    className="field_terms"
                />
                <button className="primary full_width" type="submit">
                    {_t('Proceed to pay', 'account')}
                </button>
            </form>
        )
    }
}

ExtraWishes.propTypes = {
    submit: PropTypes.func.isRequired,
    voiceNoVoice: PropTypes.string.isRequired,
    lengthHours: PropTypes.number.isRequired
}

const mapsStateToProps = state => ({
    calendar: state.calendar,
    meetPopupVisible: state.ui.meetPopupVisible,
    products: state.products
})

export default connect(mapsStateToProps, {
    showMessage,
    fetchCalendar,
    showPage
})(ExtraWishes)
