import React from 'react'
import { connect } from 'react-redux'

import { find as _find, sortBy as _sortBy } from 'lodash-es'

import { login, sendOrder } from '../../actions/userActions'
import {
    hideAllPopups,
    showHamburger,
    hideHamburger
} from '../../actions/uiActions'

import VoiceNoVoiceForm from '../../forms/VoiceNoVoiceForm'
import VoiceSelectionForm from '../../forms/VoiceSelectionForm'
import MeetForm from '../../forms/MeetForm'
import ExtraWishesForm from '../../forms/ExtraWishesForm'
import PaymentForm from '../../forms/PaymentForm'
import FormPage from './FormPage'
import _t from '../../utils/translate'

const minHeight = 300
// maxHeight = 450

const initialFormData = {
    nrSlides: 5,
    activeSlide: 1,
    activeSlideName: 'voiceNoVoice',
    message: null,
    error: false,
    productId: null,
    productIdTalk: null,
    price: null,
    priceTalk: null,
    length: 1,
    actualProductId: null,
    actualPrice: null,
    voiceNoVoice: 'voice',
    voice: null,
    selectedCalendar: 'main',
    history: [{ index: 1, name: 'voiceNoVoice' }],
    height: 450,
    extras: [],
    cart: { total: 0, items: [] },
    lengthHours: 1
}

class MeetPopup extends React.Component {
    static propTypes = {}

    constructor(props) {
        super(props)
        this.changeData = this.changeData.bind(this)
        this.goToSlide = this.goToSlide.bind(this)
        this.paymentSuccess = this.paymentSuccess.bind(this)
        this.displayMessage = this.displayMessage.bind(this)
        this.hideError = this.hideError.bind(this)

        this.state = initialFormData
    }

    static getDerivedStateFromProps(nextProps, lastState) {
        const {
            products: { loading, products }
        } = nextProps

        if (loading) return null
        const filteredProducts = products.filter(element => {
            return _find(element.categories, cat => cat.slug === 'extras')
            // return element.name === 'pizza'
        })
        const sortedProducts = _sortBy(filteredProducts, [
            o => o.acf.sorting_index,
            'id'
        ])
        // console.log(sortedProducts)
        return { extraProducts: sortedProducts }
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { showHamburger, hideHamburger } = this.props
        if (prevProps.meetPopupVisible && !this.props.meetPopupVisible)
            setTimeout(() => {
                this.setState(initialFormData)
                this.changePage('voiceNoVoice', 1)
                console.log('reset state')
            }, 1000)

        if (!prevProps.meetPopupVisible && this.props.meetPopupVisible) {
            // this.changePage('voiceNoVoice', 1)
            // console.log('reset state')
            // this.setState(initialFormData)
        }

        if (prevState.message === null && this.state.message !== null) {
            hideHamburger()
        }
        if (prevState.message !== null && this.state.message === null) {
            showHamburger()
        }
        if (prevState.error === null && this.state.error !== null) {
            hideHamburger()
        }
        if (prevState.error !== null && this.state.error === null) {
            showHamburger()
        }
    }

    changeData = (dataKey, dataValue) => {
        const { calculatePrice } = this

        if (dataKey === 'voiceNoVoice' && dataValue === 'no_voice')
            this.setState({
                selectedCalendar: 'main'
            })

        if (dataKey === 'voice')
            this.setState({
                selectedCalendar: dataValue
            })

        this.setState(
            {
                [dataKey]: dataValue
            },
            calculatePrice
        )
    }

    calculatePrice = () => {
        const { products } = this.props
        const {
            voiceNoVoice,
            duration,
            extras,
            productId,
            productIdTalk,
            price,
            priceTalk,
            cart,
            lengthHours
        } = this.state

        const extrasToSet = []
        let totalToSet = 0
        const itemsToSet = []

        if (products.loading) this.setState({ cart: { price: 0, items: [] } })
        if (!products.loading) {
            // console.log('price calculation:')

            // calculate extras price and compose extras array

            extras.map(extra => {
                const selectedProduct = _find(products.products, {
                    id: extra
                })
                let quantity = 1
                if (selectedProduct?.acf?.hourly) {
                    quantity = lengthHours
                }
                extrasToSet.push({ id: extra, quantity })
                totalToSet += parseFloat(selectedProduct.price) * quantity
                // console.log(selectedProduct)
            })

            // check whether to use voice and no voice and add id to items and price to total
            if (voiceNoVoice === 'no_voice') {
                itemsToSet.push({ id: productId, quantity: 1 })
                totalToSet += price
            }
            if (voiceNoVoice === 'voice') {
                itemsToSet.push({ id: productIdTalk, quantity: 1 })
                totalToSet += priceTalk
            }

            this.setState({
                cart: {
                    total: totalToSet,
                    items: itemsToSet,
                    extras: extrasToSet
                }
            })
        }
    }

    changeHeight = height => {
        let heightToSet = height
        if (height < minHeight) heightToSet = minHeight

        this.setState({
            height: heightToSet + 60
        })
    }
    changePage = (page, index) => {
        // let activeFlow = ''
        // if (page in flowsPages) {
        //     activeFlow = flowsPages[page]
        //     // console.log(flowsPages?.[page])
        // }
        // if (page !== 'start') this.scrollToTop()

        // console.log(
        //  `${page} index: ${
        //      index
        //          ? index
        //          : this.state.ui.history[this.state.ui.history.length - 1]
        //                  .index + 1
        //  }`
        // )
        this.setState(oldState => ({
            history: [
                ...oldState.history,
                {
                    name: page,
                    index: index
                        ? index
                        : oldState.history[oldState.history.length - 1].index +
                          1
                }
            ]
        }))
    }

    goBack = () => {
        // this.scrollToTop()
        this.setState(oldState => ({
            history:
                oldState.history.length > 1
                    ? [...oldState.history.slice(0, -1)]
                    : [...oldState.history]
        }))
    }

    finishBooking = () => {
        const { hideAllPopups } = this.props
        hideAllPopups()
        setTimeout(() => this.setState({ message: null, activeSlide: 1 }), 2000)
    }

    goToSlide = slide => this.setState({ activeSlide: slide })

    hideError = () => this.setState({ message: null, error: false })

    paymentSuccess = data => this.sendOrder(data)

    sendOrder = data => {
        const {
            state: {
                actualProductId,
                timeStart,
                timeEnd,
                length,
                option,
                specialWishes,
                voice,
                voiceNoVoice,
                extras,
                cart
            },
            props: { sendOrder },
            displayMessage
        } = this

        if (data.paymentMethod === 'paypal') {
            console.log('Payment from Paypal successfully registered.')
        }
        if (data.paymentMethod === 'cash') {
            console.log('Order paid with cash successfully registered.')
        }
        const duration = typeof length === 'number' ? `${length}h` : length

        const formData = {
            ...data,
            cart: {
                items: cart.items
            },
            extras: {
                items: cart.extras
            },
            timeStart,
            timeEnd,
            duration,
            option,
            voice,
            voiceNoVoice,
            specialWishes
        }
        // console.log(formData)
        sendOrder(formData)
            .then(res => {
                if (data.paymentMethod === 'paypal')
                    displayMessage('payment_success')
                if (data.paymentMethod === 'cash')
                    displayMessage('reservation_success')
            })
            .catch(err => {
                displayMessage(
                    err.response &&
                        err.response.data &&
                        err.response.data.message
                        ? _t(err.response.data.message)
                        : _t('There was a problem. Please try again later.'),
                    'error'
                )
                console.error('error creating the order:')
                console.error(err.response)
            })
        console.log(formData)
    }

    displayMessage = (message, type) => {
        // console.log(message)
        this.setState({ message, error: type === 'error' })
    }

    render() {
        const {
            props: { meetPopupVisible, calendar, products },
            state: {
                nrSlides,
                activeSlide,
                message,
                error,
                productId,
                price,
                priceTalk,
                actualPrice,
                actualProductId,
                selectedCalendar,
                history,
                height,
                voiceNoVoice,
                extras,
                cart,
                extraProducts,
                lengthHours
            },
            changeData,
            finishBooking,
            goToSlide,
            displayMessage,
            hideError,
            paymentSuccess,
            sendOrder,
            changePage,
            changeHeight,
            goBack
        } = this

        // console.log('cart')
        // console.log(cart)

        const activePage =
            history.length > 0 ? history[history.length - 1].name : ''
        const activeIndex =
            history.length > 0 ? history[history.length - 1].index : 0

        return (
            <div
                className={`popup slider meet_popup ${
                    meetPopupVisible ? 'active' : ''
                }`}
                style={{ height: height }}
            >
                <div className="pages">
                    <FormPage
                        changePage={changePage}
                        changeHeight={changeHeight}
                        goBack={goBack}
                        title="voiceNoVoice"
                        index={1}
                        activePage={activePage}
                        activeIndex={activeIndex}
                    >
                        <VoiceNoVoiceForm
                            displayMessage={displayMessage}
                            changeData={changeData}
                            meetPopupVisible={meetPopupVisible}
                            voiceNoVoice={voiceNoVoice}
                        />
                    </FormPage>

                    <FormPage
                        changePage={changePage}
                        changeHeight={changeHeight}
                        goBack={goBack}
                        title="voice"
                        index={2}
                        activePage={activePage}
                        activeIndex={activeIndex}
                    >
                        <VoiceSelectionForm
                            displayMessage={displayMessage}
                            changeData={changeData}
                        />
                    </FormPage>

                    <FormPage
                        changePage={changePage}
                        changeHeight={changeHeight}
                        goBack={goBack}
                        title="calendar"
                        index={3}
                        activePage={activePage}
                        activeIndex={activeIndex}
                    >
                        <MeetForm
                            displayMessage={displayMessage}
                            changeData={changeData}
                            selectedCalendar={selectedCalendar}
                            voiceNoVoice={voiceNoVoice}
                        />
                    </FormPage>
                    <FormPage
                        changePage={changePage}
                        changeHeight={changeHeight}
                        goBack={goBack}
                        title="extras"
                        index={4}
                        activePage={activePage}
                        activeIndex={activeIndex}
                    >
                        <ExtraWishesForm
                            displayMessage={displayMessage}
                            price={price}
                            priceTalk={priceTalk}
                            changeData={changeData}
                            voiceNoVoice={voiceNoVoice}
                            extras={extras}
                            lengthHours={lengthHours}
                            submit={() => goToSlide(5)}
                        />
                    </FormPage>

                    <FormPage
                        changePage={changePage}
                        changeHeight={changeHeight}
                        goBack={goBack}
                        title="payment"
                        index={5}
                        activePage={activePage}
                        activeIndex={activeIndex}
                    >
                        <PaymentForm
                            displayMessage={displayMessage}
                            paymentSuccess={paymentSuccess}
                            sendOrder={sendOrder}
                            cart={cart}
                            formVisible={activeSlide === 5}
                        />
                    </FormPage>
                </div>
                <div
                    className={`loading ${
                        message === 'loading_generic' ? 'active' : ''
                    }`}
                >
                    <div className="content">
                        <span>{_t('Loading...')}</span>
                    </div>
                </div>
                <div
                    className={`loading ${
                        calendar.loading || products.loading ? 'active' : ''
                    }`}
                >
                    <span>
                        <div className="content">
                            {_t('Checking for free appointments...')}
                        </div>
                    </span>
                </div>
                <div
                    className={`message ${
                        message === 'payment_success' ? 'active' : ''
                    }`}
                >
                    <span>
                        <div className="content">
                            <span>
                                {
                                    // _t( 'Your payment has been received, thanks! Please check your email for further information.' )
                                    _t(
                                        'My makers thank you for your payment. Check your email for more information. To us!'
                                    )
                                }
                            </span>
                            <button
                                onClick={finishBooking}
                                className="error_ok"
                            >
                                {_t('OK', 'error')}
                            </button>
                        </div>
                    </span>
                </div>
                <div
                    className={`message ${
                        message === 'reservation_success' ? 'active' : ''
                    }`}
                >
                    <span>
                        <div className="content">
                            <span>
                                {
                                    // _t( 'Your reservation has been received, thanks! Please check your email for further information.' )
                                    _t(
                                        'Puuurrrfect, Darling. Your reservation is confirmed! Check your email for more information.'
                                    )
                                }
                            </span>
                            <button
                                onClick={finishBooking}
                                className="error_ok"
                            >
                                {_t('OK', 'error')}
                            </button>
                        </div>
                    </span>
                </div>
                <div
                    className={`message ${
                        message === 'payment_failure' ? 'active' : ''
                    }`}
                >
                    <span>
                        <div className="content">
                            <span>
                                {_t(
                                    'There was a problem with your payment, please try again.'
                                )}
                            </span>
                            <button onClick={hideError} className="error_ok">
                                {_t('OK', 'error')}
                            </button>
                        </div>
                    </span>
                </div>
                <div
                    className={`message ${
                        message === 'more_information_basic' ? 'active' : ''
                    }`}
                >
                    <span>
                        <div className="content">
                            <h3>Basic</h3>
                            <p>
                                In a private flat in Berlin, you can come join
                                me and experience my medical grade silicone
                                body. There's a bed, a shower and a glass of
                                whatever you fancy waiting for you. Simple.
                            </p>
                            <button onClick={hideError} className="error_ok">
                                {_t('OK', 'error')}
                            </button>
                        </div>
                    </span>
                </div>
                <div
                    className={`message ${
                        message === 'more_information_blindfold' ? 'active' : ''
                    }`}
                >
                    <span>
                        <div className="content">
                            <h3>Blindfold</h3>
                            <p>
                                We can talk, laugh, get into deep conversations
                                or just keep it simple with some classic dirty
                                talk. Nothing is recorded or connected to the
                                Internet.
                            </p>
                            <button onClick={hideError} className="error_ok">
                                {_t('OK', 'error')}
                            </button>
                        </div>
                    </span>
                </div>
                <div
                    className={`message ${
                        message === 'more_information_full' ? 'active' : ''
                    }`}
                >
                    <span>
                        <div className="content">
                            <h3>Full</h3>
                            <p>
                                This is the most intimate of the options because
                                I can see you! All of this is happening in real
                                time, creating an immersive experience. Nothing
                                is recorded or connected to the Internet.
                            </p>
                            <button onClick={hideError} className="error_ok">
                                {_t('OK', 'error')}
                            </button>
                        </div>
                    </span>
                </div>
                <div
                    className={`message ${
                        message === 'more_information_voice' ? 'active' : ''
                    }`}
                >
                    <span>
                        <div className="content">
                            <h3>Voice</h3>
                            <p>
                                This option provides a more intimate encounter.
                                We can speak and hear one another in real time
                                while you interact with my silicone body. All is
                                audio is live and nothing is recorded.
                            </p>
                            <button onClick={hideError} className="error_ok">
                                {_t('OK', 'error')}
                            </button>
                        </div>
                    </span>
                </div>
                <div
                    className={`message ${
                        message === 'more_information_no_voice' ? 'active' : ''
                    }`}
                >
                    <span>
                        <div className="content">
                            <h3>No Voice</h3>
                            <p>
                                In my private flat in Berlin, come join me
                                without any human interaction. Entry is
                                anonymous. There will be a short introductory
                                message when you arrive to explain the
                                guidelines and to ask if you want any porn or
                                music to be played during our visit. I have a
                                shower for your comfort as well.
                            </p>
                            <button onClick={hideError} className="error_ok">
                                {_t('OK', 'error')}
                            </button>
                        </div>
                    </span>
                </div>

                {extraProducts &&
                    extraProducts.map(product => (
                        <div
                            key={`details_extra_${product.id}`}
                            className={`message ${
                                message === `extra_${product.id}`
                                    ? 'active'
                                    : ''
                            }`}
                        >
                            <span>
                                <div className="content">
                                    <h3>{product.name}</h3>
                                    <div
                                        className=""
                                        dangerouslySetInnerHTML={{
                                            __html: product.description || null
                                        }}
                                    ></div>
                                    <button
                                        onClick={hideError}
                                        className="error_ok"
                                    >
                                        {_t('OK', 'error')}
                                    </button>
                                </div>
                            </span>
                        </div>
                    ))}

                <div className={`error ${error ? 'active' : ''}`}>
                    <span>
                        <div className="content">
                            <span>{_t(message)}</span>
                            <button onClick={hideError} className="error_ok">
                                {_t('OK', 'error')}
                            </button>
                        </div>
                    </span>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    meetPopupVisible: state.ui.meetPopupVisible,
    calendar: state.calendar,
    products: state.products
})

export default connect(mapStateToProps, {
    login,
    hideAllPopups,
    sendOrder,
    showHamburger,
    hideHamburger
})(MeetPopup)
