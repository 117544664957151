import shortid from 'shortid'

import api from '../api'
import setAuthorizationHeader from '../utils/setAuthorizationHeader'
import { store } from '../store/store'

import {
    CALENDAR_REFRESHED,
    CALENDAR_LOADING,
    SX_LOADING,
    SX_REFRESHED
} from './types'

export const fetchCalendar = () => dispatch => {
    store.dispatch({
        type: CALENDAR_LOADING,
        payload: 'loading_slots'
    })
    return api.calendar
        .get()
        .then(res => {
            // console.log(res)
            return res
        })
        .then(res => {
            store.dispatch({
                type: CALENDAR_REFRESHED,
                payload: res.data
            })
            return res
        })
}

export const fetchCalendarSX = () => dispatch => {
    store.dispatch({
        type: SX_LOADING,
        payload: 'loading_slots'
    })
    return api.calendarSX
        .get()
        .then(res => {
            // console.log(res)
            return res
        })
        .then(res => {
            store.dispatch({
                type: SX_REFRESHED,
                payload: res.data
            })
            return res
        })
}
