import React from 'react'
import { connect } from 'react-redux'
import ReactGA from 'react-ga'

import { resetPassword } from '../../actions/userActions'
import { hideAllPopups } from '../../actions/uiActions'
import { sendEvent } from '../../actions/chatActions'

import ResetPasswordForm from '../../forms/ResetPasswordForm'

class LoginPopup extends React.Component {
    static propTypes = {}

    constructor(props) {
        super(props)
        this.submit = this.submit.bind(this)
    }

    submit = data =>
        // this.props
        resetPassword(data).then(res => {
            console.log('resetted password')
            return res
        })

    render() {
        const {
            props: { forgotPasswordPopupVisible },
            submit,
            register
        } = this
        return (
            <div
                className={`popup forgot_password_popup ${
                    forgotPasswordPopupVisible ? 'active' : ''
                }`}
            >
                <div className="content_wrapper">
                    <div className="content">
                        <ResetPasswordForm submit={submit} />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    forgotPasswordPopupVisible: state.ui.forgotPasswordPopupVisible
})

export default connect(mapStateToProps, {
    resetPassword,
    hideAllPopups
    // sendEvent
})(LoginPopup)
