import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import WebFont from 'webfontloader'
import ReactGA from 'react-ga'
import LogRocket from 'logrocket'
import ReactPixel from 'react-facebook-pixel'

import * as serviceWorker from './serviceWorker'
import App from './App'

import 'hamburgers'

// const advancedMatching = { em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const advancedMatching = {} // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
    autoConfig: false, // set pixel's autoConfig
    debug: false // enable logs
}

ReactPixel.init('336229374181496', advancedMatching, options)
ReactPixel.pageView()
if (!window.location.href.includes('localhost')) {
    // console.log('Not local testing. Start Logging.')
    // ReactPixel.init('336229374181496', advancedMatching, options)
    LogRocket.init('rbbscl/kokeshi', {
        release:
            process.env.REACT_APP_GIT_SHA && process.env.REACT_APP_GIT_COUNT
                ? `${process.env.REACT_APP_GIT_COUNT} // ${process.env.REACT_APP_GIT_SHA}`
                : 'unknown'
    })
} else {
    // console.log("Local testing. Don't start logging.")
}

WebFont.load({
    google: {
        families: ['Roboto', 'sans-serif']
    }
})

ReactGA.initialize('UA-145054280-1', {
    debug: false,
    gaOptions: {
        // userId: 123
    }
})
ReactGA.plugin.require('ecommerce')

// prevent Zooming on mobile
document.addEventListener('gesturestart', function (e) {
    e.preventDefault()
})

ReactDOM.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
