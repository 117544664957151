import React from 'react'
import { PayPalButton } from 'react-paypal-button-v2'

import Settings from '../../Settings'
import ReactPixel from 'react-facebook-pixel'

class PaypalCheckout extends React.Component {
	constructor(props) {
		super(props)
		this.state = { showButton: false }
	}

	render() {
		const {
			props: { displayMessage, price }
		} = this

		const options = {
			clientId: 'sb',
			currency: 'EUR',
			// commit: true,
			disableFunding: 'sepa,card,giropay,sofort',
			components: 'buttons',
			clientId: Settings.paypalLive
				? Settings.payPalClientId
				: Settings.payPalClientIdSandbox
		}

		return (
			<div className="paypal_checkout">
				<PayPalButton
					amount={price}
					options={options}
					shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
					onSuccess={(details, data) => {
						// alert(
						// 	'Transaction completed by ' +
						// 		details.payer.name.given_name
						// )
						// console.log('PaypalCheckout.js: Success!')
						// onSuccess()
						displayMessage('payment_success')

						// OPTIONAL: Call your server to save the transaction
						// return fetch('/paypal-transaction-complete', {
						// 	method: 'post',
						// 	body: JSON.stringify({
						// 		orderID: data.orderID
						// 	})
						// })
						this.props.onSuccess({ paymentToken: data.orderID })
						// console.log(details)
						// console.log(data)
					}}
					onError={(details, data) => {
						displayMessage('payment_failure')
					}}
					onCancel={data => {
						console.error('payment cancel:')
						console.error(data)

						displayMessage('payment_failure')
						return null
					}}
					onClick={() => {
						displayMessage('loading_generic')
					}}
				/>
			</div>
		)
	}
}
export default PaypalCheckout
