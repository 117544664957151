import { PRODUCTS_LOADED } from './types'
import api from '../api'

function handleErrors(response) {
	if (response.status !== 200) {
		throw Error(JSON.stringify(response))
	} else return response
}

export const loadProducts = () => dispatch => {
	// console.log('loadSingleRemedies')
	// console.log('load Products!')
	api.products
		.getProducts()
		.then(handleErrors)
		// .then(res => res.data.json())
		.then(res => {
			// console.log(res.data)
			return res.data
		})
		.then(products =>
			dispatch({
				type: PRODUCTS_LOADED,
				payload: products
			})
		)
		.catch(err => {
			console.error(
				`There seems to be a problem with the products api: ${err}`
			)
		})
}

export const test = 'test'
