import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import base64url from 'base64url'
import ReactPixel from 'react-facebook-pixel'
import ReactGA from 'react-ga'

import {
	showStartScreen,
	showChat,
	showRegisterPopup,
	showCompleteResetPasswordPopup,
	showMainScreen
} from './actions/uiActions'
import { loadPages } from './actions/pagesActions'
import { loadProducts } from './actions/productActions'
import {
	addUserDataStorage,
	checkLogin,
	consolidateUser
} from './actions/userActions'
import { forceChatStart } from './actions/chatActions'

class AppWrapper extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isPopup: false
		}
	}

	componentDidMount() {
		const {
			showStartScreen,
			consolidateUser,
			firstInteraction,
			pagesLoading,
			loadPages,
			loadProducts,
			addUserDataStorage,
			showRegisterPopup,
			showCompleteResetPasswordPopup,
			productsLoading,
			location: { hash },
			firstName,
			loggedIn,
			checkLogin
		} = this.props

		// console.log(hash.slice(1, 15))

		this.props.consolidateUser()
		const showRegister = hash.slice(1, 9) === 'register'

		const showResetPassword = hash.slice(1, 15) === 'reset_password'
		// console.log(showResetPassword)

		if (showRegister) {
			const activationTokenParts = base64url
				.decode(hash.slice(9))
				.split('&')
			console.log(activationTokenParts)
			const activationToken = activationTokenParts[0]
			const name = activationTokenParts[1]
			const email = activationTokenParts[2]
			this.setState({ isPopup: true })

			ReactGA.pageview('complete_registration')

			addUserDataStorage({ activationToken, firstName: name, email })
			showRegisterPopup()
			// this.props.forceChatStart()
			// console.log('SHOW REGISTER POPUP')
		}

		if (showResetPassword) {
			const resetPasswordTokenParts = base64url
				.decode(hash.slice(15))
				.split('&')
			console.log(resetPasswordTokenParts)
			const resetPasswordToken = resetPasswordTokenParts[0]
			const name = resetPasswordTokenParts[1]
			const email = resetPasswordTokenParts[2]
			this.setState({ isPopup: true })

			addUserDataStorage({ resetPasswordToken, firstName: name, email })
			showCompleteResetPasswordPopup()
			// this.props.forceChatStart()
			// console.log('SHOW RESET PASSWORD')
			// console.log('token')
		}
		if (
			!showRegister &&
			firstInteraction &&
			(firstName === '' || firstName === 'Human')
		)
			this.timer = setTimeout(() => showStartScreen(), 50)
		else if (!showRegister) {
			this.props.showMainScreen()
		}

		if (loggedIn) {
			checkLogin()
		}

		// if user is logged In move ahead to main screen
		// this.props.hideStartScreen()

		if (pagesLoading) {
			loadPages()
		}

		if (productsLoading) {
			loadProducts()
		}
	}

	componentDidUpdate = prevProps => {
		const {
			location: { hash }
		} = this.props
		if (prevProps.location.hash !== hash) {
			const showRegister = hash.slice(1, 9) === 'register'

			const showResetPassword = hash.slice(1, 15) === 'reset_password'

			if (!showRegister && !showResetPassword)
				this.setState({ isPopup: false })

			// console.log(hash)
		}
	}

	render() {
		const {
			props: { children, pagesLoading, hamburgerVisible },
			state: { isPopup }
		} = this

		return (
			<div
				className={`${
					pagesLoading ? 'app_wrapper loading' : 'app_wrapper'
				} ${isPopup ? 'no_menu_animation no_chat_animation' : ''} ${
					isPopup ? 'is_nonclosable_popup' : ''
				} ${hamburgerVisible ? '' : 'is_nonclosable_popup'}`}
			>
				{children}
			</div>
		)
	}
}

const mapStatetoProps = state => ({
	pagesLoading: state.pages.loading,
	productsLoading: state.products.loading,
	firstInteraction: state.ui.firstInteraction,
	firstName: state.user.firstName,
	loggedIn: state.user.loggedIn,
	hamburgerVisible: state.ui.hamburgerVisible
})

AppWrapper.defaultProps = {
	children: ''
}

AppWrapper.propTypes = {
	// children: PropTypes.array, // eslint-disable-line
	pagesLoading: PropTypes.bool.isRequired
}

export default connect(mapStatetoProps, {
	showStartScreen,
	loadPages,
	forceChatStart,
	showChat,
	showRegisterPopup,
	showCompleteResetPasswordPopup,
	addUserDataStorage,
	loadProducts,
	showMainScreen,
	checkLogin,
	consolidateUser
})(withRouter(AppWrapper))
