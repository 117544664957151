import api from '../api'

import { LOAD_PAGES } from './types'

export const loadPages = () => dispatch => {
	return api.pages
		.getPages()
		.then(res => {
			// console.log(res)
			return res
		})
		.then(res => {
			dispatch({
				type: LOAD_PAGES,
				payload: res.data
			})
			return res
		})
}
