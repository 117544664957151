import React from 'react'
import PropTypes from 'prop-types'
// import { Form, Button, Message } from 'semantic-ui-react'
import Validator from 'validator'
import { connect } from 'react-redux'
// import { Link } from 'react-router-dom'

import InlineError from '../messages/InlineError'
import { showMessage, showPopup, hidePopup } from '../actions/uiActions'
import _t from '../utils/translate'
import Settings from '../Settings'
// import { LanguageLink } from '../utils/LanguageLink'

class LoginForm extends React.Component {
    constructor(props) {
        super(props)
        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.onRegister = this.onRegister.bind(this)
        this.displayPopupMessage = this.displayPopupMessage.bind(this)
        this.forgotPasswordHandler = this.forgotPasswordHandler.bind(this)

        this.state = {
            data: {
                username: '',
                password: ''
            },
            loading: false,
            errors: {},
            message: '',
            messageCallback: null
        }
    }

    closeMessage = () => {
        this.setState({ message: null, messageCallback: null })
    }

    displayPopupMessage = (message, messageCallback) => {
        this.setState({ message, messageCallback })
    }

    forgotPasswordHandler = e => {
        const { showPopup, hidePopup } = this.props
        e.preventDefault()
        hidePopup('loginPopup')
        showPopup('forgotPasswordPopup')
    }

    onChange = e =>
        this.setState({
            data: { ...this.state.data, [e.target.name]: e.target.value },
            errors: { ...this.state.errors, [e.target.name]: false }
        })

    onRegister = e => {
        e.preventDefault()
        const { register } = this.props
        setTimeout(() => {
            this.setState({ message: '', messageCallback: null })
        }, 1000)
        register()
    }

    onRegisterConfirm = e => {
        e.preventDefault()
        this.displayPopupMessage(
            Settings.messages.registerConfirm,
            this.onRegister
        )
        // const { register } = this.props
        // register()
        // console.log('show register message')
    }

    onSubmit = e => {
        const { submit, showMessage } = this.props
        const { data } = this.state
        e.preventDefault()
        const errors = this.validate(data)
        this.setState({ errors })
        if (Object.keys(errors).length === 0) {
            this.setState({ loading: true })
            submit(data)
                .then(res => {
                    // console.log(res)
                    this.setState({
                        loading: false,
                        data: { username: '', password: '' }
                    })
                    return res
                })
                .catch(err => {
                    // console.error(err.response.data.code)
                    console.error(err)
                    showMessage(
                        err.response &&
                            err.response.data &&
                            err.response.data.message
                            ? _t(err.response.data.message)
                            : _t('The login data you provided is not correct.'),
                        'error'
                    )
                    this.setState({
                        errors:
                            {
                                global:
                                    'The login data you provided is not correct. Please try again.'
                            } || {},
                        loading: false
                    })
                })
        }
    }

    validate = data => {
        const errors = {}
        if (!Validator.isEmail(data.username))
            errors.username = _t('Invalid email', 'account')
        if (!data.password) errors.password = _t(`Can't be blank`, 'account')

        return errors
    }

    render() {
        const {
            onRegister,
            forgotPasswordHandler,
            resetPasswordHandler,
            onRegisterConfirm,
            closeMessage
        } = this
        const {
            data,
            errors,
            loading,
            forgotPasswordActive,
            message,
            messageCallback
        } = this.state
        // const errors = {}

        // console.log(this.state)

        return (
            <form
                onSubmit={this.onSubmit}
                className={`${loading ? 'loading' : ''}`}
            >
                {errors.global && (
                    <div>
                        <div>{_t('Something went wrong', 'account')}</div>
                        <p>{errors.global}</p>
                    </div>
                )}
                <h2 className="align_center">{_t('Log In', 'account')}</h2>
                <div className="field">
                    <input
                        type="email"
                        id="email"
                        name="username"
                        placeholder={_t('Email', 'account')}
                        autoComplete="email"
                        value={data.username}
                        onChange={this.onChange}
                    />

                    {errors.username && <InlineError text={errors.username} />}
                </div>
                <div className="field">
                    <input
                        type="password"
                        id="password"
                        name="password"
                        placeholder={_t('Password', 'account')}
                        value={data.password}
                        autoComplete="current-password"
                        onChange={this.onChange}
                    />
                    {errors.password && <InlineError text={errors.password} />}
                </div>
                <button className="primary" type="submit">
                    {_t('Log In', 'account')}
                </button>
                <p className="or">{_t('or')}</p>
                <button
                    className="primary"
                    type="button"
                    onClick={onRegisterConfirm}
                >
                    {_t('Register', 'account')}
                </button>
                <button
                    type="button"
                    className="link"
                    onClick={forgotPasswordHandler}
                >
                    {_t('Forgot Password?', 'account')}
                </button>
                <div className="loading">
                    <div className="content">
                        <span>{_t('Loading...')}</span>
                    </div>
                </div>
                <div className={`message ${message !== '' ? 'active' : ''}`}>
                    <div className="content">
                        <span>{_t(message)}</span>
                        <button onClick={messageCallback || closeMessage}>
                            OK
                        </button>
                    </div>
                </div>
            </form>
        )
    }
}

LoginForm.propTypes = {
    submit: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired
}

export default connect(null, { showMessage, showPopup, hidePopup })(LoginForm)
