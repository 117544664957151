import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _t from '../utils/translate'
import Settings from '../Settings'

import VoiceNoVoiceRadio from './fields/VoiceNoVoiceRadio'

const initialFormData = {
    voiceNoVoiceHover: ''
}
class VoiceNoVoiceForm extends React.Component {
    state = {
        data: initialFormData
    }

    componentDidUpdate = prevProps => {
        const { recalculateHeight, meetPopupVisible } = this.props
        if (!prevProps.meetPopupVisible && meetPopupVisible) {
            // console.log('recalculateHeight from VoiceNoVoice')
            // recalculateHeight()
            setTimeout(() => {
                recalculateHeight()
            }, 500)
        }
    }
    onChange = e => {
        const { changeData } = this.props
        // console.log({ [e.target.name]: e.target.value })
        if (e.target.name === 'voiceNoVoice')
            changeData('voiceNoVoice', e.target.value)
        if (e.target.name === 'voiceNoVoiceHover') {
            this.setState({
                data: {
                    ...this.state.data,
                    [e.target.name]: e.target.value
                }
            })
        }
    }

    onSubmit = () => {
        const { changePage, changeData, voiceNoVoice } = this.props
        if (voiceNoVoice === 'no_voice') changePage('calendar', 3)
        if (voiceNoVoice !== 'no_voice') changePage('voice', 2)
        // changeData('voiceNoVoice', voiceNoVoice)
    }

    render() {
        const {
            onChange,
            onSubmit,
            state: { data },
            props: { displayMessage, voiceNoVoice }
        } = this

        return (
            <div>
                <h2>Please choose an option</h2>
                <VoiceNoVoiceRadio
                    value={voiceNoVoice}
                    name="voiceNoVoice"
                    onChange={onChange}
                    displayMessage={displayMessage}
                />
                <button
                    className="primary full_width"
                    type="submit"
                    onClick={onSubmit}
                >
                    {_t('Proceed', 'account')}
                </button>
            </div>
        )
    }
}

VoiceNoVoiceForm.propTypes = {
    changePage: PropTypes.func.isRequired,
    changeData: PropTypes.func.isRequired,
    displayMessage: PropTypes.func.isRequired,
    recalculateHeight: PropTypes.func.isRequired
}

const mapsStateToProps = state => ({
    meetPopupVisible: state.ui.meetPopupVisible
})

export default connect(mapsStateToProps, null)(VoiceNoVoiceForm)
