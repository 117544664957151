import React from 'react'
import PropTypes from 'prop-types'
// import { Form, Button, Message } from 'semantic-ui-react'
import Validator from 'validator'
import { connect } from 'react-redux'
import { get as _get } from 'lodash-es'
// import { Link } from 'react-router-dom'

import InlineError from '../messages/InlineError'
import { showMessage, hideAllPopups } from '../actions/uiActions'
import _t from '../utils/translate'
// import { LanguageLink } from '../utils/LanguageLink'

class ResetPasswordForm extends React.Component {
    constructor(props) {
        super(props)
        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.onRegister = this.onRegister.bind(this)
        this.forgotPasswordHandler = this.forgotPasswordHandler.bind(this)

        this.state = {
            data: {
                email_reset_password: ''
            },
            forgotPasswordActive: false,
            loading: false,
            errors: {},
            error: '',
            message: '',
            messageAction: ''
        }
    }

    componentDidUpdate = prevProps => {
        if (
            prevProps.forgotPasswordPopupVisible !==
            this.props.forgotPasswordPopupVisible
        )
            this.setState({ message: '', messageAction: '' })
    }

    forgotPasswordHandler = e => {
        e.preventDefault()
        console.log('Forgot Password')
        this.setState({ forgotPasswordActive: true, message: 'Bla' })
    }

    messageOk = () => {
        const {
            state: { messageAction }
        } = this
        if (messageAction === '') {
            this.setState({ message: '' })
        }
        if (messageAction === 'close') {
            this.props.hideAllPopups()
            this.setState({ message: '', data: { email_reset_password: '' } })
        }
    }

    onChange = e =>
        this.setState({
            data: { ...this.state.data, [e.target.name]: e.target.value },
            errors: { ...this.state.errors, [e.target.name]: false }
        })

    onRegister = e => {
        e.preventDefault()
        const { register } = this.props
        register()
    }

    onSubmit = e => {
        const {
            props: { submit },
            state: { data }
        } = this
        e.preventDefault()
        const errors = this.validate(data)
        this.setState({ errors })
        if (Object.keys(errors).length === 0) {
            this.setState({ loading: true })
            submit(data)
                .then(res => {
                    // console.log(res)
                    this.setState({
                        loading: false,
                        message: 'Thanks, I sent you an email.',
                        data: { email_reset_password: '' },
                        messageAction: 'close'
                    })
                    return res
                })
                .catch(err => {
                    // console.error(err.response.data.code)
                    console.error(err)

                    const errorMessage =
                        _get(err, ['response', 'data', 'message']) ||
                        'There was a problem, please try again'
                    // const errorMessage = err?.response?.data?.message
                    //     ? _t(err.response.data.message)
                    //     : _t('There was a problem, please try again.')
                    // const errorMessage = _t(
                    //     'There was a problem, please try again.'
                    // )

                    // err.response &&
                    // err.response.data &&
                    // err.response.data.message
                    //     ? _t(err.response.data.message)
                    //     : _t('There was a problem, please try again.')

                    this.setState({
                        error: _t(errorMessage, 'message'),
                        loading: false
                    })
                })
        }
    }

    resetError = e => {
        this.setState({ error: '' })
    }

    resetPasswordHandler = () => {
        console.log('reset password')
    }

    validate = data => {
        const errors = {}
        if (!Validator.isEmail(data.email_reset_password))
            errors.email_reset_password = _t('Invalid email', 'account')
        // if (!data.password) errors.password = _t(`Can't be blank`, 'account')

        return errors
    }

    render() {
        // const { onRegister, forgotPasswordHandler, resetPasswordHandler } = this
        const {
            state: { data, errors, loading, message, error },
            resetError,
            messageOk
        } = this
        // const errors = {}

        // console.log(this.state)

        return (
            <form
                onSubmit={this.onSubmit}
                className={`${loading ? 'loading' : ''}`}
            >
                {errors.global && (
                    <div>
                        <div>{_t('Something went wrong', 'account')}</div>
                        <p>{errors.global}</p>
                    </div>
                )}
                <h2>{_t('Reset Password')}</h2>

                <div className="field">
                    <input
                        type="email"
                        id="email_reset_password"
                        name="email_reset_password"
                        placeholder={_t('Email', 'account')}
                        autoComplete="email"
                        value={data.email_reset_password}
                        onChange={this.onChange}
                    />

                    {errors.email_reset_password && (
                        <InlineError text={errors.email_reset_password} />
                    )}
                </div>

                <button className="primary" type="submit">
                    {_t('Reset Password', 'account')}
                </button>
                <div className="loading">
                    <div className="content">
                        <span>{_t('Loading...')}</span>
                    </div>
                </div>
                <div className={`message ${message !== '' ? 'active' : ''}`}>
                    <div className="content">
                        <span>{_t(message)}</span>
                        <button type="button" onClick={messageOk}>
                            OK
                        </button>
                    </div>
                </div>
                <div className={`error ${error !== '' ? 'active' : ''}`}>
                    <div className="content">
                        <span>{_t(error)}</span>
                        <button type="button" onClick={resetError}>
                            OK
                        </button>
                    </div>
                </div>
            </form>
        )
    }
}

ResetPasswordForm.propTypes = {
    submit: PropTypes.func.isRequired,
    forgotPasswordPopupVisible: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
    forgotPasswordPopupVisible: state.ui.forgotPasswordPopupVisible
})

export default connect(mapStateToProps, { showMessage, hideAllPopups })(
    ResetPasswordForm
)
