import shortid from 'shortid'
import { ADD_CHAT_USER, ADD_CHAT_KOKESHI, SESSION_ID } from '../actions/types'

const initialState = {
	uuid: shortid.generate()
}

export default function(state = initialState, action) {
	switch (action.type) {
		default:
			return state
	}
}
