import React from 'react'
import PropTypes from 'prop-types'
// import { Form, Button, Message } from 'semantic-ui-react'
import Validator from 'validator'
import { connect } from 'react-redux'
// import { Link } from 'react-router-dom'

import InlineError from '../messages/InlineError'
import { showMessage, hideRegisterPopup, showPopup } from '../actions/uiActions'
import { sendEvent } from '../actions/chatActions'
import _t from '../utils/translate'
// import { LanguageLink } from '../utils/LanguageLink'

class CompleteRegisterForm extends React.Component {
    constructor(props) {
        super(props)
        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        // console.log(this.props.name)

        this.state = {
            data: {
                signup_first_name:
                    this.props.firstName !== 'Human'
                        ? this.props.firstName
                        : '',
                signup_last_name: '',
                new_password: '',
                repeat_password: ''
            },
            loading: false,
            success: false,
            errors: {}
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.firstName !== this.props.firstName) {
            console.log(`Name has changed: ${this.props.firstName}`)
            this.setState(oldState => ({
                data: {
                    ...oldState.data,
                    signup_first_name:
                        this.props.firstName !== 'Human'
                            ? this.props.firstName
                            : ''
                }
            }))
        }
    }

    hidePopup = e => {
        e.preventDefault()
        const { hideRegisterPopup, firstName, sendEvent } = this.props
        hideRegisterPopup()
        sendEvent(
            'user_completed_registration',
            false,
            { firstName: firstName },
            0
        )
        window.location.hash = ''
        // console.log('hidePopup')
    }

    onChange = e =>
        this.setState({
            data: { ...this.state.data, [e.target.name]: e.target.value },
            errors: { ...this.state.errors, [e.target.name]: false }
        })

    onSubmit = e => {
        const {
            props: { submit, showMessage, login, email },
            state: { data }
        } = this

        // console.log(this.props.activationToken)
        // debugger
        const dataWithCode = {
            ...data,
            activation_token: this.props.activationToken
        }
        e.preventDefault()
        const errors = this.validate(data)
        this.setState({ errors })
        if (Object.keys(errors).length === 0) {
            this.setState({ loading: true })
            submit(dataWithCode)
                .then(res => {
                    console.log('TEST')
                    return login({
                        username: email,
                        password: data.new_password
                    })
                    // return res
                })
                .then(res => {
                    this.setState({ success: true, loading: false })
                })
                .catch(err => {
                    // console.log('ERROR in CompleteRegisterForm:')
                    // console.log(JSON.stringify(err.response))
                    let errorMessage = err.message
                    if (
                        'response' in err &&
                        'data' in err.response &&
                        'message' in err.response.data
                    )
                        errorMessage = _t(err.response.data.message, 'error')
                    this.setState({
                        errors: { global: errorMessage },
                        loading: false
                    })
                })
        }
    }

    validate = data => {
        const errors = {}
        // if (!)
        // errors.username = _t('Invalid email', 'account')
        if (!data.signup_first_name)
            errors.signup_first_name = _t(
                `Please enter your first name.`,
                'account'
            )
        if (!data.signup_last_name)
            errors.signup_last_name = _t(
                `Please enter your last name.`,
                'account'
            )
        if (!data.new_password)
            errors.new_password = _t(`Please choose a password.`, 'account')

        if (data.new_password.length < 8)
            errors.new_password = _t(
                `Your Password has to be at least 8 characters long.`,
                'account'
            )

        if (data.new_password !== data.repeat_password)
            errors.repeat_password = _t(`Passwords are not the same`, 'account')
        if (!data.repeat_password)
            errors.repeat_password = _t(
                `Please enter your password again.`,
                'account'
            )

        return errors
    }

    resolveError = e => {
        e.preventDefault()
        this.setState(oldState => ({
            errors: { ...oldState.errors, global: '' }
        }))
    }

    render() {
        const {
            resolveError,
            hidePopup,
            state: { data, errors, loading, success },
            props: { firstName }
        } = this
        // const errors = {}

        // console.log(this.state)

        return (
            <form
                onSubmit={this.onSubmit}
                className={`${loading ? 'loading' : ''} ${
                    errors.global ? 'global_error' : ''
                } ${success ? 'success' : ''}`}
            >
                {null && errors.global && (
                    <div>
                        <div>{_t('Something went wrong', 'account')}</div>
                        <p>{errors.global}</p>
                    </div>
                )}
                <h2 className="align_center">
                    {/*name
                        ? _t('Hi {name}!', 'account', { name: 'Lukas' })
                        : _t('Hi there!')*/}
                    {_t('Hi {firstName}!', 'account', { firstName })}
                </h2>
                <p>
                    Great, that you want to meet me! To get started, I just need
                    some more information from you:
                </p>
                <div className="field">
                    {errors.signup_first_name && (
                        <InlineError text={errors.signup_first_name} />
                    )}
                    <input
                        type="text"
                        id="signup_first_name"
                        name="signup_first_name"
                        placeholder={_t('First Name', 'account')}
                        value={data.signup_first_name}
                        autoComplete="given-name"
                        onChange={this.onChange}
                    />
                </div>
                <div className="field">
                    {errors.signup_last_name && (
                        <InlineError text={errors.signup_last_name} />
                    )}
                    <input
                        type="text"
                        id="signup_last_name"
                        name="signup_last_name"
                        placeholder={_t('Last Name', 'account')}
                        value={data.signup_last_name}
                        autoComplete="family-name"
                        onChange={this.onChange}
                    />
                </div>
                <div className="field">
                    {errors.new_password && (
                        <InlineError text={errors.new_password} />
                    )}
                    <input
                        type="password"
                        id="new_password"
                        name="new_password"
                        placeholder={_t('Password', 'account')}
                        value={data.new_password}
                        autoComplete="new-password"
                        onChange={this.onChange}
                    />
                </div>
                <div className="field">
                    {errors.repeat_password && (
                        <InlineError text={errors.repeat_password} />
                    )}
                    <input
                        type="password"
                        id="repeat_password"
                        name="repeat_password"
                        placeholder={_t('Repeat Password', 'account')}
                        value={data.repeat_password}
                        autoComplete="new-password"
                        onChange={this.onChange}
                    />
                </div>
                <button className="primary" type="submit">
                    {_t('Continue', 'account')}
                </button>
                {/* <LanguageLink to={`/${_t('forgot_password','routes')}/`}>{_t('Forgot Password?', 'account')}</LanguageLink> */}
                <div className="loading">
                    <div className="content">
                        <span>{_t('Loading...')}</span>
                    </div>
                </div>
                <div className="error">
                    <div className="content">
                        <span>{errors && errors.global}</span>
                        <button onClick={resolveError} className="error_ok">
                            {_t('OK', 'error')}
                        </button>
                    </div>
                </div>
                <div className="error">
                    <div className="content">
                        <span>{errors && errors.global}</span>
                        <button onClick={resolveError} className="error_ok">
                            {_t('OK', 'error')}
                        </button>
                    </div>
                </div>
                <div className="success">
                    <div className="content">
                        <span>
                            {_t("That's it, you're all set up!", 'messages')}
                        </span>
                        <button onClick={hidePopup} className="error_ok">
                            {_t('OK', 'error')}
                        </button>
                    </div>
                </div>
            </form>
        )
    }
}

CompleteRegisterForm.propTypes = {
    submit: PropTypes.func.isRequired
}

const mapStateTopProps = state => ({
    activationToken: state.user.activationToken,
    email: state.user.email,
    firstName: state.user.firstName
})

export default connect(mapStateTopProps, {
    showMessage,
    hideRegisterPopup,
    showPopup,
    sendEvent
})(CompleteRegisterForm)
