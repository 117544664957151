import React from 'react'
import { Link } from 'react-router-dom'

import formatLength from '../../utils/formatLength'
import formatPrice from '../../utils/formatPrice'

const Summary = ({ length, selectedDat, price }) => {
	// let price = 9.8
	if (!length) return null
	if (!price) return null
	return (
		<div className={`summary field`}>
			<h2>Summary</h2>
			<p>Length: {formatLength(length)}</p>
			<p>Price: {formatPrice(price)}</p>
		</div>
	)
}

export default Summary
