// import { store } from '../store/store'
import Settings from '../Settings'

const getPossibleTimeSlots = () => {
	const slots_1h = []
	for (let i = 9; i <= 21; i++) {
		slots_1h.push(i)
	}

	const slots_2h = []
	for (let i = 9; i <= 20; i++) {
		slots_2h.push(i)
	}

	const slots_3h = []
	for (let i = 9; i <= 19; i++) {
		slots_3h.push(i)
	}

	const slots_4h = []
	for (let i = 9; i <= 18; i++) {
		slots_4h.push(i)
	}

	const slots_5h = []
	for (let i = 9; i <= 17; i++) {
		slots_5h.push(i)
	}

	const slots_6h = []
	for (let i = 9; i <= 16; i++) {
		slots_6h.push(i)
	}

	return {
		day: ['day'],
		night: ['night'],
		test: ['test'],
		'1h': slots_1h,
		'2h': slots_2h,
		'3h': slots_3h,
		'4h': slots_4h,
		'5h': slots_5h,
		'6h': slots_6h
	}
}

export default getPossibleTimeSlots
