import React from 'react'
import PropTypes from 'prop-types'
// import { Form, Button, Message } from 'semantic-ui-react'

// import { LanguageLink } from '../utils/LanguageLink'

class ToggleButtons extends React.Component {
    constructor(props) {
        super(props)
        this.onChange = this.onChange.bind(this)
    }

    buttonClick = newValue => {
        if (newValue !== this.props.value) this.onChange(newValue)
    }

    onChange = value => {
        const { onChange, name } = this.props

        onChange({ target: { name: name, value: value } })
    }

    render() {
        const {
            props: { value, values },
            buttonClick,
            onChange
        } = this

        return (
            <div className="toggle_buttons">
                {values.map(button => (
                    <button
                        type="button"
                        key={`toggle_button_${button.value}`}
                        onClick={() => buttonClick(button.value)}
                        className={`button ${
                            button.value === value ? 'active' : ''
                        }`}
                    >
                        {button.label}
                    </button>
                ))}
            </div>
        )
    }
}

export default ToggleButtons
