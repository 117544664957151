import React from 'react'
import { PropTypes } from 'prop-types'

import formatLength from '../../utils/formatLength'
import formatPrice from '../../utils/formatPrice'
import Settings from '../../Settings'

let timeIntervals = [1, 2, 3, 4, 5, 6, 'night', 'day']

if (
	window.location.href.includes('test') ||
	window.location.href.includes('localhost')
) {
	timeIntervals = [1, 2, 3, 4, 5, 6, 'night', 'day', 'test']
}

// const timeIntervals = [1, 2, 3, 4, 5, 6, 'night', 'day', 'test']

// const timeIntervals = Settings.timeSlots

export default class LengthField extends React.Component {
	constructor(props) {
		super(props)
		this.changeHandler = this.changeHandler.bind(this)
		this.updateValue = this.updateValue.bind(this)
		this.state = { selection: 0 }
	}

	componentDidMount() {
		const { value, onChange } = this.props
		if (value === '') {
			onChange({ target: { name: 'length', value: timeIntervals[0] } })
		}
	}

	componentDidUpdate(prevProps) {
		// const { onChange } = this.props
		// const { selection } = this.state
		if (this.props.value !== prevProps.value) {
			const selection = timeIntervals.findIndex(
				currentValue => currentValue === this.props.value
			)
			// console.log(selection)
			// this.setState({ selection: 1 }, this.updateValue)
			this.setState({ selection })
		}
	}

	changeHandler = e => {
		const { onChange } = this.props
		// const value = parseInt(e.target.value, 10) <= 1 ? e.target.value : 1
		const value = e.target.value
		onChange({ target: { name: 'length', value } })
	}

	updateValue = () => {
		const { onChange } = this.props
		const { selection } = this.state
		// clearTimeout(this.timer)
		// this.timer = setTimeout(() => {
		// console.log(`updated: ${timeIntervals[selection]}`)
		onChange({
			target: { name: 'length', value: timeIntervals[selection] }
		})
		// }, 500)
	}

	increase = () => {
		const { selection } = this.state
		if (selection + 1 !== timeIntervals.length)
			this.setState({ selection: selection + 1 }, this.updateValue)
	}

	decrease = () => {
		const { selection } = this.state
		if (selection > 0)
			this.setState({ selection: selection - 1 }, this.updateValue)
	}

	render() {
		const { value, style, label, price } = this.props
		const {
			increase,
			decrease,
			state: { selection }
		} = this
		return (
			<>
				<div className="length_field" style={style}>
					<span className="label">{label}</span>
					<div className="input">
						<button
							type="button"
							className="change_qty substract"
							onClick={decrease}
						>
							-
						</button>
						<span className="value">
							{formatLength(timeIntervals[selection])}
						</span>
						<button
							type="button"
							className="change_qty add"
							onClick={increase}
						>
							+
						</button>
					</div>
				</div>{' '}
				<div className="price_display" style={style}>
					<span className="label">Price: {formatPrice(price)}</span>
				</div>
				{(value === 'day' || value === 'night') && (
					<div className="length_description">
						<span className="label">
							{value === 'day' && (
								<>
									<strong>Day</strong> means you check in at{' '}
									<strong>13:00</strong> on the day and check
									out at <strong>09:00 the next day</strong>.
								</>
							)}
							{value === 'night' && (
								<>
									<strong>Night</strong> means you check in at{' '}
									<strong>23:00</strong> on the day and check
									out at <strong>09:00 the next day</strong>.
								</>
							)}
						</span>
					</div>
				)}
			</>
		)
	}
}

LengthField.defaultProps = {
	style: {},
	value: '',
	label: 'Label is missing!',
	price: 0
}

LengthField.propTypes = {
	onChange: PropTypes.func.isRequired,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	style: PropTypes.object, // eslint-disable-line
	price: PropTypes.number
}
