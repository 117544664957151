import React from 'react'
import { connect } from 'react-redux'
import { find as _find } from 'lodash-es'
import _t from '../../utils/translate'
import { hidePage } from '../../actions/uiActions'
import SpaceDisplay from '../blocks/SpaceDisplay'
class Space extends React.Component {
    constructor(props) {
        super(props)
        this.closePage = this.closePage.bind(this)
    }

    closePage() {
        const { hidePage } = this.props
        hidePage('space')
    }

    render() {
        const { closePage } = this
        const { pages, spaceVisible } = this.props
        const page = _find(pages, { slug: 'space' })

        if (!page) return null

        return (
            <div className={`space_page ${spaceVisible ? 'visible' : ''}`}>
                <div className="text_wrapper">
                    <h1>{_t('Space')}</h1>
                    {page && page.acf && page.acf.space_gallery && (
                        <SpaceDisplay
                            spaceVisible={spaceVisible}
                            gallery={page.acf.space_gallery}
                        />
                    )}
                </div>
                <button
                    type="button"
                    className="button_close"
                    onClick={closePage}
                >
                    <div className="icon icon_close" />
                </button>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    spaceVisible: state.ui.spaceVisible,
    pages: state.pages
})

export default connect(mapStateToProps, { hidePage })(Space)
