import React from 'react'

import { useState } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import {
	CardElement,
	Elements,
	useElements,
	useStripe
} from '@stripe/react-stripe-js'

import Settings from '../../Settings'

// Custom styling can be passed to options when creating an Element.
const CARD_ELEMENT_OPTIONS = {
	style: {
		base: {
			color: Settings.primaryColor,
			fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
			fontSmoothing: 'antialiased',
			fontSize: '16px',
			'::placeholder': {
				// color: '#aab7c4'
			}
		},
		invalid: {
			color: '#fa755a',
			iconColor: '#fa755a'
		}
	}
}

const CheckoutForm = () => {
	const [error, setError] = useState(null)
	const stripe = useStripe()
	const elements = useElements()

	// Handle real-time validation errors from the card Element.
	const handleChange = event => {
		if (event.error) {
			setError(event.error.message)
		} else {
			setError(null)
		}
	}

	// Handle form submission.
	const handleSubmit = async event => {
		event.preventDefault()
		const card = elements.getElement(CardElement)
		const result = await stripe.createToken(card)
		if (result.error) {
			// Inform the user if there was an error.
			setError(result.error.message)
		} else {
			setError(null)
			// Send the token to your server.
			stripeTokenHandler(result.token)
		}
	}

	return (
		<form onSubmit={handleSubmit} className="stripe_checkout">
			<div className="form-row">
				<label htmlFor="card-element">Credit or debit card</label>
				<CardElement
					id="card-element"
					options={CARD_ELEMENT_OPTIONS}
					onChange={handleChange}
				/>
				<div className="card-errors" role="alert">
					{error}
				</div>
			</div>
			<button type="submit">Submit Payment</button>
		</form>
	)
}

// Setup Stripe.js and the Elements provider
const stripePromise = loadStripe('pk_test_ilOHGRhMOkHDL7UKRH7F1ILO')

// const StripeCheckout = () => {
// 	return (
// 		<Elements stripe={stripePromise}>
// 			<CheckoutForm />
// 		</Elements>
// 	)
// }

const StripeCheckout = () => {
	return (
		<div style={{ marginTop: '30px' }}>
			<span>Coming soon!</span>
		</div>
	)
}

// POST the token ID to your backend.
async function stripeTokenHandler(token) {
	const response = await fetch('/charge', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({ token: token.id })
	})

	return response.json()
}

export default StripeCheckout
