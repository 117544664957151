import md5 from 'md5'

const myid = {
	generate: input => {
		if (!input) input = new Date().getTime()
		return md5(JSON.stringify(input))
	}
}

export default myid
