import React from 'react'
import { connect } from 'react-redux'
import { find as _find } from 'lodash-es'

import { hidePage } from '../../actions/uiActions'
import FaqDisplay from '../blocks/FaqDisplay'
class Faq extends React.Component {
    constructor(props) {
        super(props)
        this.closePage = this.closePage.bind(this)
    }

    closePage() {
        const { hidePage } = this.props
        hidePage('faq')
    }

    render() {
        const { closePage } = this
        const { pages, faqVisible } = this.props
        const page = _find(pages, { slug: 'faq' })

        if (!page) return null

        return (
            <div className={`faq_page ${faqVisible ? 'visible' : ''}`}>
                <div className="text_wrapper">
                    <h1>FAQ</h1>
                    {page && page.acf && page.acf.faqs && (
                        <FaqDisplay faqs={page.acf.faqs} />
                    )}
                </div>
                <button
                    type="button"
                    className="button_close"
                    onClick={closePage}
                >
                    <div className="icon icon_close" />
                </button>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    faqVisible: state.ui.faqVisible,
    pages: state.pages
})

export default connect(mapStateToProps, { hidePage })(Faq)
