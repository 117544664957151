import React from 'react'
import { connect } from 'react-redux'
import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'

import { login } from '../../actions/userActions'
import { hideAllPopups } from '../../actions/uiActions'
import { sendEvent } from '../../actions/chatActions'

import LoginForm from '../../forms/LoginForm'

class LoginPopup extends React.Component {
    static propTypes = {}

    constructor(props) {
        super(props)
        this.submit = this.submit.bind(this)
    }

    register = () => {
        const { userHasSignedUp, sendEvent, hideAllPopups, email } = this.props
        ReactGA.pageview('register')
        if (!userHasSignedUp) {
            sendEvent('register_no_ok', false)
            ReactPixel.track('Lead')
        }
        if (userHasSignedUp) sendEvent('register_again', false, { email })
        hideAllPopups()
    }

    submit = data =>
        this.props
            .login(data)
            // .then(() => this.props.loadUserData())
            .then(() => this.props.hideAllPopups())
    // .then(() =>{}
    // this.props.showMessage(_t('Successfully logged in!', 'messages'), 'success')
    // console.log('logged in')
    // )
    // .then(() => this.props.history.push(`/${this.props.language}/${_t('account','routes')}/`)) // eslint-disable-line

    render() {
        const {
            props: { loginPopupVisible },
            submit,
            register
        } = this
        return (
            <div
                className={`popup login_popup ${
                    loginPopupVisible ? 'active' : ''
                }`}
            >
                <div className="content_wrapper">
                    <div className="content">
                        <LoginForm submit={submit} register={register} />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    loginPopupVisible: state.ui.loginPopupVisible,
    userHasSignedUp: state.user.userHasSignedUp,
    email: state.user.email
})

export default connect(mapStateToProps, { login, hideAllPopups, sendEvent })(
    LoginPopup
)
