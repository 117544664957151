import React, { Component, PropTypes } from 'react'
import { Collapse } from 'react-collapse'

class FaqDisplay extends Component {
	constructor(props) {
		super(props)
		this.open = this.open.bind(this)
		this.state = {
			openId: null
		}
	}

	open = id => {
		this.setState({ openId: id })
	}

	render() {
		const { faqs } = this.props
		const { openId } = this.state
		const { open } = this

		if (!faqs.length > 0) return null
		return (
			<>
				{faqs.map((faq, n) => (
					<div
						className={`${openId === n ? 'active' : ''} faq`}
						key={`faq_${n}`}
					>
						<p className="question" onClick={() => open(n)}>
							{' '}
							{faq.question}{' '}
						</p>
						<Collapse isOpened={openId === n}>
							<div
								className="answer"
								dangerouslySetInnerHTML={{
									__html: faq.answer || null
								}}
							/>
						</Collapse>
					</div>
				))}
			</>
		)
	}
}

export default FaqDisplay
