export const LOAD_PAGES = 'LOAD_PAGES'

export const SHOW_START_SCREEN = 'SHOW_START_SCREEN'
export const HIDE_START_SCREEN = 'HIDE_START_SCREEN'

export const SHOW_CHAT = 'SHOW_CHAT'
export const SHOW_CHAT_SCREEN = 'SHOW_CHAT_SCREEN'
export const HIDE_CHAT_BACKGROUND = 'HIDE_CHAT_BACKGROUND'

export const SHOW_MAIN_SCREEN = 'SHOW_MAIN_SCREEN'
export const HIDE_MAIN_SCREEN = 'HIDE_MAIN_SCREEN'

export const SHOW_PAGE = 'SHOW_PAGE'
export const HIDE_PAGE = 'HIDE_PAGE'
export const HIDE_ALL_PAGES = 'HIDE_ALL_PAGES'

export const SHOW_POPUP = 'SHOW_POPUP'
export const HIDE_POPUP = 'HIDE_POPUP'

export const USER_REGISTERED = 'USER_REGISTERED'

export const GO_TO_START = 'GO_TO_START'

export const USER_SIGNUP_LOADING = 'USER_SIGNUP_LOADING'

export const ADD_CHAT_USER = 'ADD_CHAT_USER'
export const ADD_CHAT_KOKESHI = 'ADD_CHAT_KOKESHI'

export const SESSION_ID = 'SESSION_ID'

export const WAITING_FOR_ANSWER = 'WAITING_FOR_ANSWER'

export const STARTED_TYPING = 'STARTED_TYPING'
export const FINISHED_TYPING = 'FINISHED_TYPING'

export const ADD_USER_DATA_STORAGE = 'ADD_USER_DATA_STORAGE'

export const SHOW_MENU = 'SHOW_MENU'
export const HIDE_MENU = 'HIDE_MENU'

export const SHOW_LOGIN_POPUP = 'SHOW_LOGIN_POPUP'
export const HIDE_ALL_POPUPS = 'HIDE_ALL_POPUPS'

export const SHOW_MESSAGE = 'SHOW_MESSAGE'

export const USER_LOGGED_IN = 'USER_LOGGED_IN'
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT'

export const CALENDAR_REFRESHED = 'CALENDAR_REFRESHED'
export const CALENDAR_LOADING = 'CALENDAR_LOADING'

export const FORCE_CHAT_START = 'FORCE_CHAT_START'

export const SHOW_REGISTER_POPUP = 'SHOW_REGISTER_POPUP'
export const HIDE_REGISTER_POPUP = 'HIDE_REGISTER_POPUP'

export const PRODUCTS_LOADED = 'PRODUCTS_LOADED'
export const SET_LOADING = 'SET_LOADING'

export const CONSOLIDATE_USER_DATA = 'CONSOLIDATE_USER_DATA'

export const SHOW_COMPLETE_RESET_PASSWORD_POPUP =
	'SHOW_COMPLETE_RESET_PASSWORD_POPUP'
export const HIDE_COMPLETE_RESET_PASSWORD_POPUP =
	'HIDE_COMPLETE_RESET_PASSWORD_POPUP'

export const SHOW_HAMBURGER = 'SHOW_HAMBURGER'
export const HIDE_HAMBURGER = 'HIDE_HAMBURGER'

export const SHOW_SPACE_LIGHTBOX = 'SHOW_SPACE_LIGHTBOX'
export const HIDE_SPACE_LIGHTBOX = 'HIDE_SPACE_LIGHTBOX'

export const SX_LOADING = 'SX_LOADING'
export const SX_REFRESHED = 'SX_REFRESHED'
